import crud from "./crud";
import crud_service from "@/services/crud";

const path = "internal_repairs";
const struct = {
  description: "",
  assigned_to_id: null,
  repaired_at: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  repaired({ commit }, pk) {
    return new Promise((resolve) => {
      crud_service.post(`${path}/${pk}/repaired`, {}).then((item) => {
        commit("nothing");
        resolve(item);
      });
    });
  },

  trashed({ commit }, pk) {
    return new Promise((resolve) => {
      crud_service.post(`${path}/${pk}/trashed`, {}).then((item) => {
        commit("nothing");
        resolve(item);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
