<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName">
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="editedItem.producer_id"
                  :items="$store.state.producers.all"
                  item-title="display_name"
                  item-value="pk"
                  :label="$filters.capitalize($t('producers.label', 1))"
                  :loading="$store.state.producers.isSearching"
                  variant="solo"
                  return
                  object
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="editedItem.region"
                  :label="$filters.capitalize($t('spot_price_contracts.region'))"
                  :items="regions"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <DatePicker
                  v-bind:value="editedItem.start"
                  v-bind:label="'generic.begin_date'"
                  @input="editedItem.start = $event"
                ></DatePicker>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <DatePicker
                  v-bind:value="editedItem.end"
                  v-bind:label="'generic.end_date'"
                  @input="editedItem.end = $event"
                ></DatePicker>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import filters from "@/mixins/filters";

import DatePicker from "@/components/DatePicker";

export default {
  mixins: [filters],

  components: {
    Crud: Crud,
    DatePicker: DatePicker,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  created() {
if (this.$store.state.producers.all.length <= 0){
    this.$store.dispatch("producers/getAll");
  }},

  data() {
    return {
      moduleName: "spot_price_contracts",
      regions: ["FR", "SE1", "SE2", "SE3", "SE4"],
      headers: [
        { title: this.$t("spot_price_contracts.region"), key: "region" },
        {
          title: this.$t("producers.label", 1),
          key: "producer.display_name",
        },
        { title: this.$t("generic.begin_date"), key: "start" },
        { title: this.$t("generic.end_date"), key: "end" },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
