<template>
  <v-card>
    <v-snackbar
      v-model="$store.state.snackbar.visibility"
      location="bottom"
      :color="$store.state.snackbar.color"
      :timeout="$store.state.snackbar.timeout"
    >
      {{ $filters.capitalize($store.state.snackbar.message) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          variant = 'text'
          v-bind="attrs"
          @click="$store.state.snackbar.visibility = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
