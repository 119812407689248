<template>
  <v-container>
    <v-card class="mx-auto my-3">
      <v-toolbar flat color="white" elevation="1">
        <v-toolbar-title style="font-weight: bolder">
          {{ $filters.capitalize($t(`prod_availability_months.label`, 1)) }}
        </v-toolbar-title>
        <v-col>
          <v-btn
            :disabled="loadingRefresh"
            color="secondary lighten-2"
            variant="text"
            @click="refreshData"
          >
            <template v-if="loadingRefresh">
              <v-progress-circular
                indeterminate
                color="primary"
                size="20"
              ></v-progress-circular>
            </template>
            <template v-else>
              {{ $filters.capitalize($t("prod_availability_months.refresh", 1)) }}
            </template>
          </v-btn>
        </v-col>
        <v-col>
          <div class="date-picker-wrapper">
            <template v-if="loadingDownloads">
              <v-progress-circular
                indeterminate
                color="primary"
                size="20"
              ></v-progress-circular>
            </template>
            <template v-else>
              <VueDatePicker
              v-model="range"
              :teleport="true"
              model-type="yyyy-MM"
              :locale="$i18n.locale"
              :first-day-of-week="1"
              @update:model-value="download"
              month-picker
              range
              ></VueDatePicker>
            </template>
          </div>
        </v-col>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr style="width: 100%">
              <th scope="col" rowspan="2" :class="stickyStateClass">
                {{ $filters.capitalize($t("producers.display_name", 1)) }}
              </th>
              <th
                v-for="(shortbread, index) in month_range"
                :key="index"
                scope="col"
                colspan="4"
                :class="'dateline ' + stickyStateClass"
              >
                {{ shortbread.format("MMMM YYYY") }}
              </th>
            </tr>
            <tr>
              <th
                v-for="(pastry, index) in sub_month_range"
                :key="index"
                :class="'text-left subline ' + stickyStateClass"
              >
                {{ pastry }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in PAMHitems_" :key="item.id">
              <td>{{ item.display_name }}</td>
              <td v-for="(cake, index) in item.values" :key="index">
                {{ $filters.formatFloat(cake) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { isNone } from "@/functions.js";
import i18n from "@/i18n";
import utils from "@/mixins/utils";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: { VueDatePicker },

  mixins: [utils],

  created() {
    this.$store
      .dispatch(`${this.moduleName}/getHistory`, this.limit)
      .then((items) => {
        this.PAMHitems = items;
      });
  },

  computed: {
    month_range() {
      const range = moment.range(this.limit, moment());
      return Array.from(range.by('months'));
    },
    PAMHitems_: function () {
      var ret = [];
      for (var i = 0; this.PAMHitems.length - 1 >= i; i++) {
        const line = this.PAMHitems[i];
        ret[i] = line;
        var values = [];
        for (var k = 0; line["values"].length - 1 >= k; k++) {
          values.push(line["values"][k]["prod"]["value"]);
          values.push(line["values"][k]["time"]);
          values.push(parseInt(Math.round(line["values"][k]["data"])));
          values.push(line["values"][k]["prod"]["value_turnover"]);
        }
        ret[i]["values"] = values;
      }
      return ret;
    },
  },

  watch: {
    PAMHitems: function (val) {
      if (isNone(val)) {
        return;
      }
      this.sub_month_range = [];
      for (var j = 0; val[0]["values"].length - 1 >= j; j++) {
        this.sub_month_range.push(this.$t(`${this.moduleName}.history.prod`));
        this.sub_month_range.push(this.$t(`${this.moduleName}.history.time`));
        this.sub_month_range.push(this.$t(`${this.moduleName}.history.data`));
        this.sub_month_range.push(this.$t(`${this.moduleName}.history.data_turnover`));
      }
    },
  },

  methods: {
    async refreshData() {
      const currentUser = isNone(this.$store.getters.user)? undefined: this.$store.getters.user.id;
      let payload = {
        "user" : currentUser
      }
      this.loadingRefresh = true;
      this.$store.dispatch("snackbar/showSuccess", this.$t("snack.refresh"));
      await this.$store.dispatch(`${this.moduleName}/refresh`, payload)
        .catch((error) => {
          console.error("Error refreshing data:", error);
        })
        .finally(() => {
      });
      this.loadingRefresh = false;
    },
    async download() {
      if (isNone(this.range) || this.range.length < 2) {
        const msg = i18n.global.t("snack.no_range");
        this.$store.dispatch("snackbar/showError", msg);
        return;
      }

      console.log("loadingDown");
      this.loadingDownloads = true;

      try {
        const [start, end] = this.range[0] > this.range[1] ? [this.range[1], this.range[0]] : this.range;
        const payload = {
          start,
          end,
          variety: "w",
        };

        await this.getFilePromise(
          "prod_availability_months/export_xls/",
          "",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "blob",
          payload
        );

        console.log("Download complete");
      } catch (error) {
        console.error("Error downloading the file:", error);
        const msg = i18n.global.t("snack.download_error");
        this.$store.dispatch("snackbar/showError", msg);
        this.loadingDownloads = false;
      } finally {
        this.loadingDownloads = false;
        console.log("Stopped");
      }
      
      console.log("Finished");
    }
  },

  data() {
    return {
      loadingRefresh: false,
      PAMHitems: [],
      sub_month_range: [],
      stickyStateClass: "",
      range: [],
      moduleName: "prod_availability_months",
      limit: moment().startOf("day").startOf("month").subtract(5, "months"),
      loadingDownloads: false
    };
  },
};
</script>

<style scoped>
:deep(.v-data-table__wrapper) {
  height: calc(100vh - 90px) !important;
}
th {
  text-align: center !important;
}
th.dateline,
th.subline:nth-child(4n + 1),
td:nth-child(4n + 2) {
  border-left: 1px dashed grey;
}

.download-div > div.v-picker {
  position: absolute;
  right: 15px;
  top: 54px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.no-sticky {
  position: inherit !important;
}
.date-picker-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.custom-date-picker {
  flex-grow: 1;
}

.loading-indicator {
  position: absolute;
  right: 10px;
}
</style>
