import crud from "./crud";
import crud_service from "@/services/crud";

const path = "performance_ratio_solar_panel";
const struct = {
  description: "",
  start: "",
  end: "",
  ratio: 0.0,
  producer_id: 0,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
  addRatioYear({ commit }, item) {
    console.log("addRatioYear");
    return new Promise((resolve, reject) => {
      console.log("taths")
      crud_service.post(path + "/post_year_ratio", item) // Correct usage here
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            console.error('Bad Request error:', error.response.data);
            reject(new Error('Bad Request: ' + error.response.data));
          } else {
            console.error('An error occurred while adding the item:', error);
            reject(error);
          }
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
