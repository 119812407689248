<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
          v-bind:headers="headers"
          v-bind:moduleName="moduleName"
          v-bind:actionDelete="false"
          @addItem="addItemEvent"
          @editItem="editItemEvent"
          @saveItem="saveItemEvent"
        >
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.name"
                  :label="$filters.capitalize($t('stockages.name') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.region"
                  :label="$filters.capitalize($t('stockages.region') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.city"
                  :label="$filters.capitalize($t('stockages.city') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.country"
                  :label="$filters.capitalize($t('stockages.country') )"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-textarea
                  v-model="editedItem.address"
                  :label="$filters.capitalize($t('stockages.address') )"
                  :rows="3"
                  no-resize
                  variant="solo"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="editedItem.users"
                  :items="$store.state.users.search"
                  item-title="name"
                  item-value="id"
                  :label="$filters.capitalize($t('users.label', 1) )"
                  v-model:search="autocompleteSearchUser"
                  :loading="$store.state.users.isSearching"
                  append-icon="mdi-text-box-search"
                  :clearable="true"
                  variant="solo"
                  :chips="true"
                  deletable-chips
                  :multiple="true"
                  cache-items
                  closable-chips
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {toRaw} from "vue"
import { th } from "vuetify/locale";
import utils from "@/mixins/utils";
import Crud from "@/components/Crud";

export default {
  components: {
    Crud: Crud,
  },
  mixins: [utils],
  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  methods: {
    addItemEvent() {
      this.$store.dispatch("users/searchItems", { value: "" });
    },
    editItemEvent() {
      this.$store.dispatch("users/initSearch", {
        item_ids: toRaw(this.editedItem.user_ids),
      });
    },
    saveItemEvent() {
      if(this.editedItem.users !== undefined){
        this.editedItem.user_ids = this.editedItem.users.map(user => user.id)
      }
    }
  },

  watch: {
    autocompleteSearchUser(val) {
      if (!this.$store.getters.canSearch("users")) return;
      if (val =='') return;
      this.delayedSearch(val, "users/searchItems", { value: val }, (()=>{this.autocompleteSearchUser=''}));
    },
  },

  data() {
    return {
      moduleName: "stockages",
      headers: [
        { title: this.$t("stockages.name"), key: "name" },
        { title: this.$t("stockages.region"), key: "region" },
        { title: this.$t("stockages.city"), key: "city" },
        { title: this.$t("stockages.country"), key: "country" },
        { title: this.$t("stockages.address"), key: "address" },
        {
          title: this.$t("stockages.users"),
          key: "users",
          sortable: false,
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      autocompleteSearchUser: null,
    };
  },
};
</script>
