import crud from './crud';
import crud_service from "@/services/crud";

const path = 'unit_codes'
const struct = {
  qrcode: '',
  unit_id: undefined,
}
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
  assign_code({commit}, item){
    // back returns existing object if either qr_code or unit already exist
    return new Promise((resolve) => {
      crud_service.post(`${path}/assign_code`, item)
      .then((response) => {
        commit('updateEdit', response)
        commit('addItem', response)
        resolve(response)
      })
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
