<template>
  <v-app id="raijin">
    <v-main v-bind:style="{ padding: '0px' }">
      <v-container class="fill-height" :style="{...containerStyle, margin:'0', padding: '0'}" fluid>
        <div :style="{...triangleStyle, margin:'0', padding: '0'}"></div>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card elevation="12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{
                  $filters.capitalize($t("login.title"))
                }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>mdi-login-variant</v-icon>
              </v-toolbar>

              <v-card-text>
                <v-form @keyup.native.enter="login(email, password)">
                  <v-text-field
                    :label="$filters.capitalize($t('login.email'))"
                    name="email"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="email"
                    :rules="loginRules"
                    required
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    :label="$filters.capitalize($t('login.password'))"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    :rules="passwordRules"
                    v-model="password"
                  ></v-text-field>
                </v-form>

                <div style="text-align: right">
                  <a :href="password_link" target="_blank">{{
                    $filters.capitalize($t("login.forgotten_creds"))
                  }}</a>
                </div>
              </v-card-text>

              <v-card-actions>
                <img
                  style="max-height: 48px"
                  alt="logo innovent"
                  :src="require('@/assets/logo_innovent.png')"
                />
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login(email, password)" size="large">
                  <span v-show="!loading">Login</span>
                  <v-progress-circular
                    v-show="loading"
                    :indeterminate="true"
                  ></v-progress-circular>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import i18n from "@/i18n.js";
// import { useRoute, useRouter } from "vue-router";

export default {
  //   function pushWithQuery(query){
  //   }

  // },
  methods: {
    login(email, password) {
      this.loading = true;
      this.$store.dispatch("login", { email: email, password: password }).then(
        () => {
          var path = "/";
          if ("next" in this.$route.query) {
            path = this.$route.query.next;
          }
          this.$router.push({ path: path.replace("/login", "/") });
          // this.pushWithQuery()
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("snackbar/showError", error.detail);
        }
      );
    },
  },

  data() {
    return {
      email: "",
      password: "",
      loading: false,
      loginRules: [(v) => !!v || i18n.global.t("login.login_required")],
      passwordRules: [
        (v) => !!v || i18n.global.t("login.password_required"),
        (v) => v.length > 8 || i18n.global.t("login.password_short"),
      ],
      containerStyle: {
        "background-image": "url('static/solar_farm.jpg')",
        "background-size": "cover",
        height: "100vh",
        width: "100%",
      },
      triangleStyle: {
        "clip-path": "polygon(0 0%, 100% 0%, 0% 100%)",
        "background-image": "url('static/wind_farm.jpg')",
        "background-size": "cover",
        height: "100vh",
        width: "100%",
        position: "absolute",
      },
      password_link: `${process.env.VUE_APP_API_URL}/admin/password_reset/`,
    };
  },
};
</script>

<style lang="scss">
.container.container--fluid {
  padding: 0px;
}
</style>
