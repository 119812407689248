<template>
  <v-container pa-0 ma-0>
    <v-text-field
      v-model.number="editedItem.quantity"
      type="number"
      :label="$filters.capitalize($t('consumables.quantity'))"
    ></v-text-field>
  </v-container>
</template>

<script>
export default {
  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit("consumables");
    },
  },

  data() {
    return {};
  },
};
</script>
