import crud from "./crud";
import crud_service from "@/services/crud";

const path = "prod_availabilities";
const struct = {
  date: null,
  value: "",
  producer_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  getMonthItems({ commit }) {
    // get prod availability infos over last month
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/month`)
        .then((items) => {
          commit("setItems", items);
          resolve(items);
        })
        .catch(() => {});
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
