<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName">
          <template v-slot:edit_content>
            <ExternalRepairForm></ExternalRepairForm>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import ExternalRepairForm from "@/components/ExternalRepairForm";

export default {
  components: {
    Crud: Crud,
    ExternalRepairForm: ExternalRepairForm,
  },

  data() {
    return {
      moduleName: "external_repairs",
      headers: [
        {
          title: this.$t("external_repairs.created_at"),
          key: "created_at",
        },
        {
          title: this.$t("external_repairs.description"),
          key: "description",
        },
        {
          title: this.$t("external_repairs.assigned_to"),
          key: "assigned_to.first_name",
        },
        {
          title: this.$t("external_repairs.assigned_to"),
          key: "assigned_to.last_name",
        },
        { title: this.$t("external_repairs.address"), key: "address" },
        { title: this.$t("external_repairs.tracking"), key: "tracking" },
        { title: this.$t("external_repairs.sent_at"), key: "sent_at" },
        {
          title: this.$t("external_repairs.quotation_at"),
          key: "quotation_at",
        },
        {
          title: this.$t("external_repairs.agreed_at"),
          key: "agreed_at",
        },
        {
          title: this.$t("external_repairs.returned_at"),
          key: "returned_at",
        },
        { title: this.$t("stockages.label"), key: "stockage.name" },
        {
          title: this.$t("external_repairs.received_at"),
          key: "received_at",
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      autocompleteSearch: null,
    };
  },
};
</script>
