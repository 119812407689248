<template>
  <v-container fluid style="height: 100vh; overflow: hidden;">
    <v-row no-gutters style="height: 100%;">
      <v-col cols="12" md="8" style="height: 100%;">
        <v-card outlined style="height: 100%;">
          <highcharts :options="chartOptions"></highcharts>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" style="height: 100%; overflow-y: auto;">
        <v-card outlined>
          <v-card-text>
            <iframe
              title="Grafana Panel 1"
              width="100%"
              height="300"
              src="https://grafana.innovent.fr/d-solo/ajxRWE8Gk/production-instantanee?orgId=1&from=now-7d&to=now&theme=light&panelId=5&refresh=5m"
              frameborder="0"
            ></iframe>
          </v-card-text>
        </v-card>
        <v-card outlined class="mt-4">
          <v-card-title>Prix Spot</v-card-title>
          <v-card-subtitle>Last 12 hours</v-card-subtitle>
          <v-card-text>
            <iframe
              title="Grafana Panel 1 Fr"
              width="100%"
              height="150"
              src="https://grafana.innovent.fr/d-solo/ajxRWE8Gk/production-instantanee?orgId=1&from=now-12h&to=now%2B2h&panelId=9&theme=light&refresh=5m"
              frameborder="0"
            ></iframe>
            <iframe
              title="Grafana Panel Kalix"
              width="100%"
              height="150"
              src="https://grafana.innovent.fr/d-solo/ajxRWE8Gk/production-instantanee?orgId=1&from=now-12h&to=now%2B2h&panelId=10&theme=light&refresh=5m"
              frameborder="0"
            ></iframe>
            <iframe
              title="Grafana Panel Vanern"
              width="100%"
              height="150"
              src="https://grafana.innovent.fr/d-solo/ajxRWE8Gk/production-instantanee?orgId=1&from=now-12h&to=now%2B2h&panelId=11&theme=light&refresh=5m"
              frameborder="0"
            ></iframe>
            <iframe 
              title="Grafana Instant production global"
              width="100%"
              height="150"
              frameborder="0"
              src="https://grafana.innovent.fr/d-solo/ajxRWE8Gk/production-instantanee?orgId=1&from=now-30m&to=now&panelId=3&theme=light"
            ></iframe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import exportingInit from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import { capitalize } from "@/functions.js";

exportingInit(Highcharts);
HighchartsMore(Highcharts);

export default {
  props: {
    producer_ids: {
      type: Array,
      required: false,
      default: () => undefined,
    },
    height: {
      type: Number,
      required: false,
      default: 860,
    },
  },

  data() {
    return {
      producers: [],
      intervalId: null,
      chartOptions: this.initializeChartOptions(),
    };
  },

  computed: {
    title() {
      return capitalize(this.$route.query.title);
    },

    producerIds() {
      return this.producer_ids || this.$route.query.id.split(",").map(Number);
    },

    labels() {
      return this.producers.map((producer) => producer.display_name);
    },
  },

  methods: {
    initializeChartOptions() {
      return {
        legend: {
          enabled: true,
          backgroundColor: "#ffffff",
          borderColor: "#ddd",
          borderRadius: 5,
          borderWidth: 1,
          itemStyle: {
            color: "#333",
            fontWeight: "bold",
          },
          itemHoverStyle: {
            color: "#000",
          },
        },
        chart: {
          type: "column",
          height: this.height,
          backgroundColor: "#f4f4f4",
          spacingTop: 20,
          spacingBottom: 20,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            fontFamily: "'Roboto', sans-serif",
          },
        },
        title: {
          text: this.title,
          style: {
            color: "#333",
            fontSize: "18px",
            fontWeight: "bold",
          },
        },
        xAxis: {
          categories: this.labels,
          crosshair: true,
          labels: {
            style: {
              color: "#666",
              fontWeight: "bold",
            },
          },
        },
        yAxis: [
          {
            min: 0,
            maxPadding: 0.1,
            alignTicks: false,
            title: {
              text: "Ensoleillement (Wh/m2)",
              style: {
                color: "#f1c40f",
                fontWeight: "bold",
              },
            },
            labels: {
              style: {
                fontWeight: "bold",
              },
            },
          },
          {
            min: 0,
            maxPadding: 0.1,
            alignTicks: false,
            title: {
              text: "Puissance (MW)",
              style: {
                color: "#e74c3c",
                fontWeight: "bold",
              },
            },
            opposite: true,
            labels: {
              style: {
                fontWeight: "bold",
              },
              format: "{value}",
            },
          },
        ],
        tooltip: {
          shared: true,
          backgroundColor: "#ffffff",
          borderColor: "#ddd",
          borderRadius: 5,
          style: {
            color: "#333",
            fontWeight: "bold",
          },
          headerFormat: "<b>{point.key}</b><br>",
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} <br>',
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0.1,
            dataLabels: {
              enabled: true,
              style: {
                color: "#000",
                textOutline: "none",
                fontWeight: "bold",
              },
              formatter: function () {
                let unit = 'Error'

                if (this.series.name === 'Ensoleillement Horizontal' || this.series.name === 'Ensoleillement Tracker') {
                  unit = 'Wh/m2'
                } else if (this.series.name === 'Puissance' || this.series.name === 'Theoretical Production'){
                  unit = 'Mw'
                }
                return this.y.toFixed(2) + ' ' +unit;
            },
            },
          },
        },
        series: [
          {
            name: "Ensoleillement Horizontal",
            data: [],
            tooltip: { valueSuffix: " Wh/m2" },
            color: "#f1c40f",
            yAxis: 0,
          },
          {
            name: "Ensoleillement Tracker",
            data: [],
            tooltip: { valueSuffix: " Wh/m2" },
            color: "#f39c12",
            yAxis: 0,
          },
          {
            name: "Puissance",
            data: [],
            tooltip: { valueSuffix: " MW" },
            color: "#e74c3c",
            yAxis: 1,
          },
          {
            name: "Theoretical Production",
            type: "errorbar",
            data: [],
            tooltip: {
              valueSuffix: " MW",
            },
            color: "black",
            yAxis: 1,
            pointPlacement: 0,
            zIndex: 3,
            grouping: false,
            borderColor: "#3498db",
            borderWidth: 1,
          },
        ],
      };
    },

    getOrderByProducer(data) {
      const prodOrder = this.producers.map((p) => p.pk);
      return prodOrder.map((id) => data.findIndex((d) => d.producer.pk === id));
    },

    parseData(data) {
      return this.getOrderByProducer(data).map((order) => {
        const { power, radiance_hor, radiance_vert, performance_ratio_actual_month_max, performance_ratio_actual_month_min } = data[order];
        return {
          power: power > 0 ? power/ 1000: 0,
          sun_h: radiance_hor,
          sun_v: radiance_vert,
          performance_ratio_actual_month_max: performance_ratio_actual_month_max / 1000,
          performance_ratio_actual_month_min: performance_ratio_actual_month_min / 1000
        };
      });
    },

    updateData() {
      const payload = { ids: this.producerIds };
      this.$store
        .dispatch("telemetries/solarCurrent_producerItems", payload)
        .then((data) => {
          const parsedData = this.parseData(data);
          this.chartOptions.series[0].data = parsedData.map((x) => x.sun_h);
          this.chartOptions.series[1].data = parsedData.map((x) => x.sun_v);
          this.chartOptions.series[2].data = parsedData.map((x) => x.power);
          this.chartOptions.series[3].data = parsedData.map((x) => [
            x.performance_ratio_actual_month_max,
            x.performance_ratio_actual_month_min 
          ]);          
        });
    },

    setDailyRefresh(hours, minutes) {
      const now = new Date();
      const nextRefresh = new Date();
      nextRefresh.setHours(hours, minutes, 0, 0);

      if (now >= nextRefresh) {
        nextRefresh.setDate(now.getDate() + 1);
      }

      const timeout = nextRefresh.getTime() - now.getTime();
      setTimeout(() => window.location.reload(true), timeout);
    },

    initializeProducers() {
      this.$store.dispatch("producers/getItems", { ids: this.producerIds }).then((producers) => {
        this.producers = producers;
        this.chartOptions.xAxis.categories = this.labels;
        this.updateData();
      });
    },

    setupAutoRefresh() {
      this.intervalId = setInterval(this.updateData, 20000);
    },

    clearAutoRefresh() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },

  mounted() {
    this.chartOptions.title.text = this.title;

    this.initializeProducers();
    this.$nextTick(this.setupAutoRefresh);
    this.setDailyRefresh(13, 30);
  },

  unmounted() {
    this.clearAutoRefresh();
    this.producers = [];
  },
};
</script>

<style scoped>
  .v-container {
    background-color: #fafafa;
  }

  .v-card {
    margin-bottom: 20px;
  }

  .v-card-title {
    font-weight: bold;
    color: #333;
  }

  .v-card-subtitle {
    color: #777;
  }
</style>
