<template>
  <v-container py-0 ma-0>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-select
          :disabled="producerId === null || editMode"
          v-model="category"
          :items="available_categories"
          :item-props="categoryProps"
          :label="$filters.capitalize($t('part_categories.label', 1) )"
          variant="solo"
          return-object
        >
        </v-select>
      </v-col>

    <v-col
      cols="10" sm="10" md="4">
      <v-autocomplete
        :disabled="producerId === null || editMode"
        v-model="editedItem"
        :items="available_units"
        :item-props="unitProps"
        :label="$filters.capitalize($t('units.label') )"
        v-model:search="autocompleteSearchUnit"
        :loading="$store.state.units.isSearching"
        append-icon="mdi-text-box-search"
        :clearable="true"
        variant="solo"
        no-filter
        return-object
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{$filters.capitalize( $t('search_for', {noun: $t('units.label', 1)}) ) }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>

      <v-col
        v-if="!editMode"
        cols="1"
        sm="1"
        md="1"
        class="mt-4"
        align="center"
      >
        <v-tooltip location="top" :text="$filters.capitalize( $t('actions.delete') )">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" @click="deleteBtn()">mdi-delete</v-icon>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { isNone } from "@/functions";

export default {
  mixins: [filters, utils],
  props: {
    ordinal: { type: Number, required: true },
    producerId: {
      required: true,
      validator: (prop) => typeof prop === "number" || isNone(prop),
    },
    stockageId: {
      required: true,
      validator: (prop) => typeof prop === "number" || isNone(prop),
    },
    producerTypeId: {
      required: true,
      validator: (prop) => typeof prop === "number" || isNone(prop),
    },
    editMode: { type: Boolean, required: false, default: true },
  },

  computed: {
    edition: function () {
      return this.$store.state[`${this.moduleName}`].edition;
    },
    available_categories: function () {
      return this.$store.state[`part_categories`].items;
    },
    editedItem: {
      get: function () {
        return this.edition.added_parts[this.ordinal];
      },
      set: function (value) {
        this.$store.dispatch("intervention_reports/updateAddedParts", {
          index: this.ordinal,
          item: value ,
        });
      },
    },
    editedItem_category: { 
      get: function() {
        if (isNone(this.edition.added_parts[this.ordinal])) return undefined
        return this.edition.added_parts[this.ordinal].category
      },
      set: function(value) {
        this.$store.dispatch('intervention_reports/updateAddedParts', { index: this.ordinal, item: { category: value } })
      },
     },
  },

  methods: {
    deleteBtn() {
      this.$emit("remove_affected", this.ordinal);
    },

    updateUnits(val) {
      const options = {
        value: val,
        size: 50,
        category_id: this.category,
        stockage_id: this.stockageId,
        producer_type_id: this.producerTypeId,
      }
      this.delayedSearch(val, 'units/getAvailables', options, (items)=>{ this.updateAvailables(items)})
    },

    updateCategory() {
      if (
        this.editedItem_category &&
        this.editedItem_category.pk !== undefined
      ) {
        this.category = this.editedItem_category.pk;
      }
    },

    updateAvailables(units_list) {
      this.available_units = units_list
      let index = this.available_units.findIndex(x => x.pk === this.editedItem_id)
      if(index == -1 && this.editedItem_id){
        //to deal with cache issues and component not showing the info: add the unit to the item list of the component 
        this.$store.dispatch('units/getItem', this.editedItem_id).then((item) => {
          this.available_units.push(item)
        })
      }
    },

    categoryProps (item) {
      if(typeof item !== 'object' || item === null) return
      item.title = this.$store.getters.language === 'en' ? this.$filters.formatCategoryEn(item) : this.$filters.formatCategory(item);
      item.value = item.pk
      return item
    },
    unitProps (item) {
      item.value = item.pk
      item.title = ''
      if (item.stock_part == undefined) return item
      if (item.stock_part.ref.length > 0) item.title = item.stock_part.ref
      else if (this.$store.getters.language === 'en') item.title = item.stock_part.name_en 
      else item.title = item.stock_part.name
      item.title = item.title + ' - ' + item.serial
      return item
    },
  },

  mounted() {
    if (this.producerId) {
      this.$store
        .dispatch("part_categories/getItemsFromProducer", [this.producerId])
        .then(() => {
          this.updateCategory();
        });
      this.updateUnits("");
    } else {
      this.$store
        .dispatch("part_categories/getItems", { size: 40 })
        .then(() => {
          this.updateCategory();
        });
    }

    if (this.editedItem_id) {
      this.$store.dispatch('units/initSearch', { item_ids: [this.editedItem_id]} ).then((item) => {
        this.available_units.push(item.results)
      })
    }
  },

  watch: {
    producerId(val) {
      if (!isNone(val)) {
        // this.$store.dispatch('units/getFromProducers', [val])
        this.$store.dispatch('part_categories/getItemsFromProducer', [this.producerId]).then(() => {
          this.updateCategory()
        })
      }
    },
    autocompleteSearchUnit(val) {
      if (!this.$store.getters.canSearch("units")) return;
      this.updateUnits(val);
    },
    editedItem_category () {
      if(isNone(this.editedItem_category)) return
      let index = this.available_categories.findIndex(x => x.pk === this.editedItem_category)
      if(index == -1){
        //to deal with cache issues and component not showing the info: add the category to the item list of the component 
        this.$store.dispatch('part_categories/getItem', this.editedItem_category).then((item) => {
          this.available_categories.push(item)
          this.category = item.pk
        })
      } else {
        this.category = this.available_categories[index].pk
      }
      this.updateUnits('')
    }
  },

  data() {
    return {
      moduleName: "intervention_reports",
      autocompleteSearchUnit: null,
      category: null,
      available_units: [],
    };
  },
};
</script>
