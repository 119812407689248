import crud from "./crud";

const path = "spot_price_contracts";
const struct = {
  region: "FR",
  producer_id: null,
  start: null,
  end: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
