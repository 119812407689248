<template>
  <v-container>
    <div style="display: none">
      <EditDialog
        v-bind:model="showAddReport"
        v-bind:moduleName="'preventive_maintenances'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('preventive_maintenances.actions.add_item')"
        @save="saveAddReportEvent"
        @close="cleanupAddReportDialog"
        ref="editDialogAddReport"
      >
        <template v-slot:edit_content>
          <PreventiveMaintenanceForm
            v-bind:askPowerStation="false"
          ></PreventiveMaintenanceForm>
        </template>
      </EditDialog>
    </div>

    <v-toolbar text color="white" elevation="1">
      <v-spacer></v-spacer>
      <v-toolbar-title style="font-weight: bolder">{{
        $filters.capitalize($t(pageTitle, 10) )
      }}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-row align="center" justify="center">
      <v-col cols="12" md="9">
        <v-table class="my-2" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.view.name") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.period.annual") ) }}
                </th>
                <th class="text-left">
                  {{
                    $filters.capitalize($t("preventive_maintenances.period.biannual") )
                  }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.period.four") ) }}
                </th>
                <th class="text-left">
                  {{
                    $filters.capitalize($t("preventive_maintenances.period.quarterly") )
                  }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("actions.label") ) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="pow_sta in power_stations"
                :key="pow_sta.pk"
                :class="bgClass(pow_sta)"
              >
                <td>
                  <router-link
                    :to="{
                      name: 'maintenance_edition',
                      query: {
                        power_station_id: pow_sta.pk,
                        power_station_name: pow_sta.name,
                      },
                    }"
                  >
                    {{ $filters.capitalize(pow_sta.name) }}</router-link
                  >
                </td>
                <td v-if="pow_sta.applicable_maintenances.includes('an')" :class="bgClass(pow_sta, 'an')">
                    {{ $filters.formatDate(pow_sta.annual.done_at)}}
                </td>
                <td v-else class="disabled"></td>
                <td v-if="pow_sta.applicable_maintenances.includes('bian')" :class="bgClass(pow_sta, 'bian')">
                    {{ $filters.formatDate(pow_sta.biannual.done_at) }}
                </td>
                <td v-else class="disabled"></td>
                <td v-if="pow_sta.applicable_maintenances.includes('four')" :class="bgClass(pow_sta, 'four')">
                  {{ $filters.formatDate(pow_sta.four.done_at) }}
                </td>
                <td v-else class="disabled"></td>
                <td v-if="pow_sta.applicable_maintenances.includes('quart')" :class="bgClass(pow_sta, 'quart')">
                  {{ $filters.formatDate(pow_sta.quarterly.done_at) }}
                </td>
                <td v-else class="disabled"></td>
                <td>
                  <v-tooltip location="top" :text="$filters.capitalize($t('preventive_maintenances.actions.add_report')
                  )">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        class="mr-2"
                        @click="addReportBtn(pow_sta)"
                        >mdi-plus-thick
                      </v-icon>
                    </template>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import EditDialog from "@/components/EditDialog";
import PreventiveMaintenanceForm from "@/components/PreventiveMaintenanceForm";
import { isNone } from "@/functions.js";

export default {
  components: {
    EditDialog: EditDialog,
    PreventiveMaintenanceForm: PreventiveMaintenanceForm,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  mounted() {
    this.$store
      .dispatch("power_stations/getPreventiveMaintenances")
      .then((items) => {
        this.power_stations = items.filter(
          (i) => i.applicable_maintenances.length > 0,
        );
      });
  },

  methods: {
    addReportBtn(item) {
      this.power_station_id = item.pk;
      this.$store.dispatch(
        `${this.moduleName}/setEditPowerStation`,
        this.power_station_id,
      );
      const applicables = this.$store.state.preventiveMaintenancePeriods.filter(
        (i) => item.applicable_maintenances.includes(i.id),
      );
      this.$store.dispatch(`power_stations/updateEdit`, {
        name: item.name,
        applicable_maintenances: applicables,
      });
      this.showAddReport = true;
    },

    saveAddReportEvent() {
      if (
        isNone(this.editedItem.period) ||
        isNone(this.editedItem.done_at) ||
        isNone(this.editedItem.report)
      ) {
        this.$store.dispatch(
          "snackbar/showError",
          this.$t("snack.more_infos"),
        );
        this.power_station_id = undefined;
      } else {
        const report = this.editedItem.report[0];
        this.$store
          .dispatch(`${this.moduleName}/addItem`, this.editedItem)
          .then((item) => {
            this.$store
              .dispatch(`${this.moduleName}/sendFile`, {
                pk: item.pk,
                file: report,
                name: report.name,
              })
              .then(() => {
                // view list update
                const index = this.power_stations.findIndex(
                  (obj) => obj.pk === this.power_station_id,
                );
                if (index !== -1) {
                  switch (this.editedItem.period) {
                    case "an":
                      this.power_stations[index].annual.done_at =
                        this.editedItem.done_at;
                      break;
                    case "bian":
                      this.power_stations[index].biannual.done_at =
                        this.editedItem.done_at;
                      break;
                    case "four":
                      this.power_stations[index].four.done_at =
                        this.editedItem.done_at;
                      break;
                    case "quart":
                      this.power_stations[index].quarterly.done_at =
                        this.editedItem.done_at;
                      break;
                    default:
                      console.error(
                        `Unknown period ${this.editedItem.period}.`,
                      );
                  }
                }
                this.$store.dispatch(
                  "snackbar/showSuccess",
                  this.$t("snack.create_success"),
                );
                this.power_station_id = undefined;
              });
          });
      }
      this.$refs.editDialogAddReport.closeDialog();
    },

    cleanupAddReportDialog() {
      this.showAddReport = false;
      this.$store.dispatch(`${this.moduleName}/cleanupEdit`);
    },

    bgClass(item, type) {
      var classes = [];
      if (
        type == 'an' &&
        !isNone(item.annual.done_at) &&
        moment(item.annual.done_at) < this.limit_annual
      ) {
        return "outdated";
      }
      if (
        type == 'bian' &&
        !isNone(item.biannual.done_at) &&
        moment(item.biannual.done_at) < this.limit_biannual
      ) {
        return "outdated";
      }
      if (
        type == 'four' &&
        !isNone(item.four.done_at) &&
        moment(item.four.done_at) < this.limit_four
      ) {
        return "outdated";
      }
      if (
        type == 'quart' &&
        !isNone(item.quarterly.done_at) &&
        moment(item.quarterly.done_at) < this.limit_quarterly
      ) {
        return "outdated";
      }

      return "";
    },
  },

  unmounted() {
    this.power_stations = [];
  },

  data() {
    return {
      pageTitle: "preventive_maintenances.label",
      moduleName: "preventive_maintenances",
      power_stations: [],
      showAddReport: false,
      power_station_id: undefined,
      limit_annual: moment().startOf("day").subtract(1, "years"),
      limit_biannual: moment().startOf("day").subtract(6, "months"),
      limit_four: moment().startOf("day").subtract(4, "months"),
      limit_quarterly: moment().startOf("day").subtract(3, "months"),
    };
  },
};
</script>

<style scoped>
/* Vuetify ugly sticky header hack */
:deep(.v-data-table__wrapper) {
  height: calc(100vh - 100px) !important;
}

td.outdated {
  background-color: #f2c2bd !important;
}

td.disabled {
  background-color: #cccccc;
}
</style>
