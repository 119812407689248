<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col>
        <DateTimePicker
          :label="'limitations.start'"
          :value="editedItem.start"
          :moduleName="'limitations'"
        >
        </DateTimePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DateTimePicker
          :label="'limitations.end'"
          :value="editedItem.end"
          :moduleName="'limitations'"
          :isStart='false'
        >
        </DateTimePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <ProducerForm 
         @updateProducer="updateProducer"
         :multiple="newLimitation">
        </ProducerForm>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model.number="editedItem.power_limit"
          type="number"
          min="0"
          :label="$filters.capitalize($t('limitations.power_limit'))"
          density="compact"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="select"
          :value="editedItem.origin"
          :label="$filters.capitalize($t('limitations.origin.label'))"
          :items="origin_options"
          item-title="text"
          item-value="value"
          @update:modelValue="updateOrigin"
          variant="solo"
          density="compact"
        ></v-select
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="editedItem.comment"
          :label="$filters.capitalize($t('limitations.comment'))"
          outlined
        ></v-textarea
        >
      </v-col>
    </v-row>
    <v-row>
      <v-switch
        v-model="editedItem.impact_dispo"
        inset
        :label="
          editedItem.impact_dispo == true
            ? $t('limitations.impact_dispo.true')
            : $t('limitations.impact_dispo.false')
        "
      ></v-switch>
    </v-row>
  </v-container>
</template>
<script>
import ProducerForm from "./ProducerForm.vue";
import DateTimePicker from '@/components/scheduler/DateTimePicker'
import { unProxy } from "@/functions";

export default {
  components: { ProducerForm, DateTimePicker },

  methods: {
    getOriginOptionByValue(value) {
      return this.origin_options.find(option => option.value === value);
    },
    updateProducer(item) {
      if (this.newLimitation) {
        this.editedItem.producer_ids = item;
        this.editedItem.producer = undefined;
      } else {
        this.editedItem.producer_id = this.$store.state.producers.edit.pk;
        this.editedItem.producer = undefined;
      }
    },
    updateOrigin(item) {
      this.editedItem.origin = item;
    }
  },

  created() {
    this.select = this.getOriginOptionByValue(this.editedItem.origin);
  },

  computed: {
    editedItem: {
      get() {
        return this.$store.getters.dataEdit(this.moduleName);
      },
      set(newValue) {
        this.$store.setters.dataEdit("limitations", newValue);
      }
    },
    newLimitation() {
      return isNaN(this.$store.state.limitations.edit.pk);
    }
  },
  watch: {
    dateRange(val) {
      this.$store.dispatch(`${this.moduleName}/setStart`, val[0]);
      this.$store.dispatch(`${this.moduleName}/setEnd`, val[1]);
    }
  },
  data() {
    return {
      moduleName: "limitations",
      start_at: undefined,
      end_at: undefined,
      picker: null,
      landscape: false,
      select:         
        {
          value: "ui",
          text: this.$t("limitations.origin.ui"),
          disabled: false
        },
      origin_options: [
        {
          value: "ui",
          text: this.$t("limitations.origin.ui"),
          disabled: false
        },
        {
          value: "sp",
          text: this.$t("limitations.origin.sp"),
          disabled: false
        },
        {
          value: "deie",
          text: this.$t("limitations.origin.deie"),
          disabled: true
        }
      ]
    };
  }
};
</script>
