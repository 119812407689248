<template>
  <v-container py-0 ma-0>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-autocomplete
          :disabled="editMode"
          v-model="editedItem"
          :items="available_consumables"
          :item-props="consProps"
          :label="$filters.capitalize($t('consumables.label') )"
          v-model:search="autocompleteSearch"
          @click:clear='this.editedItem.pk = undefined'
          :loading="$store.state.consumables.isSearching"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          no-filter
          return-object
          :filter="filterConsumables"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{$filters.capitalize(
                  $t("search_for", { noun: $t("consumables.label", 1) })
                    )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col v-show="multiple_locations" cols="12" sm="12" md="4">
        <v-select
          :disabled="editMode"
          v-model="editedItem_place"
          :items="available_places"
          :label="$filters.capitalize($t('consumables.stock') )"
          variant="solo"
        >
        </v-select>
      </v-col>

      <v-col cols="10" sm="10" md="3">
        <v-text-field
          v-model.number="editedItem_quantity"
          type="number"
          min="0"
          :label="$filters.capitalize($t('consumables.quantity') )"
          :disabled="editMode"
        ></v-text-field>
      </v-col>

      <v-col
        v-if="!editMode"
        cols="1"
        sm="1"
        md="1"
        class="mt-4"
        align="center"
      >
        <v-tooltip location="top" :text="$filters.capitalize( $t('actions.delete') )">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" @click="deleteBtn()">mdi-delete</v-icon>
          </template>
          <span>{{ }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { isNone } from "@/functions";

export default {
  mixins: [filters, utils],

  props: {
    ordinal: { type: Number, required: true },
    stockageId: {
      required: true,
      validator: (prop) => typeof prop === "number" || prop === null,
    },
    producerTypeId: {
      required: true,
      validator: (prop) => typeof prop === "number" || prop === null,
    },
    editMode: { type: Boolean, required: false, default: true },
  },

  computed: {
    edition: function () {
      return this.$store.state.intervention_reports.edition;
    },
    editedItem: {
      get: function () {
        const consumed_part = this.edition.consumed_parts[this.ordinal];
        if ('pk' in consumed_part) {
          return consumed_part;
        }
        return;
      },
      set: function (value) {
        this.$store.dispatch("intervention_reports/updateConsumedParts", {
          index: this.ordinal,
          item: value ,
        });
      },
    },
    editedItem_quantity: {
      get: function () {
        return this.edition.consumed_parts[this.ordinal].quantity;
      },
      set: function (value) {
        this.$store.dispatch("intervention_reports/updateConsumedParts", {
          index: this.ordinal,
          item: { quantity: Math.abs(value) },
        });
      },
    },
    editedItem_place: {
      get: function () {
        return this.edition.consumed_parts[this.ordinal].place;
      },
      set: function (value) {
        this.$store.dispatch("intervention_reports/updateConsumedParts", {
          index: this.ordinal,
          item: { place: value },
        });
      },
    },
  },

  methods: {
    deleteBtn() {
      this.$emit("remove_consumed", this.ordinal);
    },
    updateConsumables(val) {
      const options = {
        value: val,
        size: 50,
        stockage_id: this.stockageId,
        producer_type_id: this.producerTypeId,
      };
      this.delayedSearch(
        val,
        "consumables/searchRealItems",
        options,
        (values) => {
          this.available_consumables = values;
        },
      );
    },
    choosePlace(unit_id) {
      let index = this.available_consumables.findIndex((x) => x.pk === unit_id);
      if (index !== -1) {
        this.stockpartId = this.available_consumables[index].stock_part_id;
        this.$store
          .dispatch("parts/getPlaceTotals", {
            stockage_id: this.stockageId,
            stock_part_id: this.stockpartId,
          })
          .then((item) => {
            this.available_places = item;
            if (this.available_places.length > 1) {
              this.multiple_locations = true;
            }
          });
      }
    },
    consProps (item) {
      if (typeof item !== 'object' || isNone(item)) return
      item.value = item.pk
      item.title = ''
      if (item.stock_part == undefined) return item
      if (this.$store.getters.language === 'en'){
        item.title = item.stock_part.name_en +' - '+ item.stock_part.ref + ' - ' + item.stock_part.category.name_en
      } else { 
        item.title = item.stock_part.name +' - '+item.stock_part.ref + ' - ' + item.stock_part.category.name
      }
      return item
    },

  },

  mounted() {
    if (this.editedItem_id) {
      this.$store
        .dispatch("consumables/initSearch", { item_ids: [this.editedItem_id] })
        .then((item) => {
          this.available_consumables = item.results;
        });
    }
  },

  watch: {
    autocompleteSearch(val) {
      if (!this.$store.getters.canSearch("consumables")) return;
      if(this.$store.state.consumables.isLoading) return;
      if(isNone(val)) return;
      if(this.editedItem && this.editedItem.pk) return;
      this.updateConsumables(val);
    },
    editedItem_id() {
      if (!isNone(this.editedItem_id)) {
        this.choosePlace(this.editedItem_id);
      } else {
        this.multiple_locations = false;
      }
    },
  },

  data() {
    return {
      moduleName: "intervention_reports",
      autocompleteSearch: null,
      available_consumables: [],
      stockpartId: null,
      multiple_locations: false,
      available_places: [],
    };
  },
};
</script>
