<template>
  <v-container>
    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import exportingInit from "highcharts/modules/exporting";
import { capitalize } from "@/functions.js";

import Highcharts from "highcharts";
exportingInit(Highcharts);

export default {
  computed: {
    title() {
      var resp = "Production aux points de livraison";
      return capitalize(resp);
    },

    producer_ids() {
      return this.$store.state.telemetries.producerIds;
    },

    producers() {
      return this.itemLifter(
        this.$store.state.producers.items,
        this.producer_ids,
      );
    },

    labels() {
      return this.producers.map((a) => a.display_name);
    },

    mode_store() {
      return this.$store.state.telemetries.currentProducerItems;
    },

    data() {
      var data = [];
      for (var i = 0; i < this.producer_ids.length; i++) {
        const index = this.producer_ids[i];
        var value = 0.0;
        if (index in this.mode_store) {
          value = this.mode_store[index].power / 1000.0; // in MW
        }
        data.push(value);
      }
      return data;
    },
  },

  methods: {
    itemLifter: (items, values) => {
      return items.filter((el) => values.includes(el.pk));
    },

    updateData() {
      const payload = { ids: this.producer_ids };
      this.$store
        .dispatch(`telemetries/getCurrent_producerItems`, payload)
        .then(() => {
          this.chartOptions.series[0].data = this.data;
        });
    },

    refreshAt(hours, minutes) {
      var now = new Date();
      var then = new Date();

      if (
        now.getHours() > hours ||
        (now.getHours() == hours && now.getMinutes() >= minutes)
      ) {
        then.setDate(now.getDate() + 1);
      }
      then.setHours(hours);
      then.setMinutes(minutes);
      then.setSeconds(0);

      var timeout = then.getTime() - now.getTime();
      setTimeout(function () {
        window.location.reload(true);
      }, timeout);
    },
  },

  mounted() {
    this.chartOptions.title.text = this.title;

    this.$store.dispatch(`telemetries/getPdlList`).then((items) => {
      this.updateData();
      this.chartOptions.xAxis.categories = Object.values(items);
    });

    this.$nextTick(function () {
      this.intervalId = window.setInterval(() => {
        this.updateData();
      }, 60 * 1000);
    });

    this.refreshAt(13, 30); // refresh every day at 13:30
  },

  unmounted() {
    window.clearInterval(this.intervalId);
  },

  data() {
    return {
      intervalId: undefined,
      chartOptions: {
        chart: {
          type: "column",
          height: 630,
        },
        title: {
          text: "",
          //x:'.$title_offset.'
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            pointPadding: 0,
          },
        },
        xAxis: {
          categories: [],
        },
        yAxis: [
          // {
          //     min: 0,
          //     max:'.$payload_yaxis_wind_max.',
          //     tickInterval: '.$payload_yaxis_wind_tick.',
          //     alignTicks: false,
          //     title:
          //     {
          //         text: "Vent (m/s)"
          //     },
          //     plotBands:
          //     [
          //         {
          //             color: "#ECEFF1",
          //             from: 3,
          //             to: 4,
          //             label:
          //             {
          //                 text: "Cut in",
          //                 align: "left",
          //                 y: +3,
          //                 x: -38,
          //                 style: { fill: "#666666" }
          //             }
          //         }
          //     ]
          // },
          {
            min: 0,
            // max: '.$payload_yaxis_power_max.',
            tickInterval: 1,
            alignTicks: false,
            title: {
              text: "Puissance (MW)",
            },
          },
        ],
        tooltip: {
          headerFormat: "<b>{point.key}</b><br>",
          pointFormat:
            '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y}',
        },
        series: [
          // {
          //     name: "Vent",
          //     data: ['.$payload_yaxis_series_data_vent.'],
          //     tooltip:
          //     {
          //       valueSuffix: " m/s"
          //     },
          //     color: "#2980b9",
          //     yAxis:0
          // },
          // {
          //     name: "Objectif",
          //     data: ['.$payload_yaxis_series_data_obj.'],
          //     type: "columnrange",
          //     lineWidth: 0,
          //     color: "rgba(149,206,255,0.7)",
          //     marker:
          //     {
          //       enabled: false
          //     },
          //     yAxis: 1,
          //     pointPlacement: 0.30,
          //     pointRange: 0.6,
          //     borderWidth: 0
          // },
          {
            name: "Puissance",
            data: this.data,
            tooltip: {
              valueSuffix: " MW",
            },
            color: "#e74c3c",
            //yAxis: 1,
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
div.highcharts-label .v-icon.mdi {
  color: #bbbbbb;
}
</style>
