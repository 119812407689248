<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="6" md="6" v-if="askPowerStation">
        <v-autocomplete
          v-model="editedItem.power_station_id"
          :items="$store.state.power_stations.search"
          item-title="name"
          item-value="pk"
          :label="$filters.capitalize($t('power_stations.label'))"
          v-model:search="autocompleteSearchPowerStation"
          :loading="$store.state.power_stations.isSearching"
          :filter="filterPowerStations"
          no-filter
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("power_stations.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-select
          v-model="editedItem.period"
          :items="items_period"
          item-title="name"
          item-value="value"
          :label="
            $filters.capitalize($t('preventive_maintenances.period.label', 1))
          "
          variant="solo"
        >
        <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{$filters.capitalize(
                  $t("search_for", {
                    noun: $t("preventive_maintenances.label", 10),
                  }) )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, props:{onClick}}">
            <v-list>
              <v-list-item @click="onClick" :title=" $filters.capitalize(item.raw.name)"/>
          </v-list>
          </template>
          <template v-slot:selection="{ item }">
            {{ $filters.capitalize(item.raw.name) }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <DatePicker
          v-bind:label="'preventive_maintenances.done_at'"
          v-bind:value="editedItem.done_at"
          @input="editedItem.done_at = $event"
        >
        </DatePicker>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-file-input
          v-model="report_file"
          @update:modelValue="on_select_file"
          :label="$filters.capitalize($t('preventive_maintenances.report'))"
          show-size
        ></v-file-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import { isNone } from "@/functions.js";
import DatePicker from "@/components/DatePicker";

export default {
  mixins: [filters],

  components: {
    DatePicker : DatePicker
  },

  props: {
    report_value: { type: [String, null], required: false, default: null },
    askPowerStation: { type: Boolean, required: false, default: true },
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit("preventive_maintenances");
    },
  },

  mounted() {
    if (!isNone(this.editedItem.power_station_id)) {
      this.$store.dispatch("power_stations/initSearch", {
        item_ids: this.editedItem.power_station_id,
      });
    }
    if (!isNone(this.report_value)) {
      this.report_file = this.genFile(this.report_value);
    }
  },

  methods: {
    on_select_file() {
      this.editedItem.report = this.report_file;
    },

    genFile(path) {
      const infos = this.editedItem.report_infos;
      return new File([path], infos.name, { type: infos.type });
    },
  },

  watch: {
    autocompleteSearchPowerStation(val) {
      if (!this.$store.getters.canSearch("power_stations")) return;
      this.$store.dispatch("power_stations/searchItems", { value: val });
    },
    report_value(val) {
      if (isNone(val)) {
            this.report_file = [];
          } else {
            this.report_file = [this.genFile(val)];
          }
    },
  },

  data() {
    return {
      autocompleteSearchPowerStation: null,
      report_file: [],
      items_period : [{
        name: this.$t("preventive_maintenances.period.biannual"),
        value: "bian"  
      },
      {
        name: this.$t("preventive_maintenances.period.annual"),
        value: "an"  
      },
    ]
    };
  },
};
</script>
