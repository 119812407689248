<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col v-show="askStockPart" cols="12" sm="8" md="5">
        <v-autocomplete
          v-model="editedItem.stock_part_id"
          :items="$store.state.stock_parts.search"
          :item-props="stockPartProps"
          :label="$filters.capitalize($t('stock_parts.label', 1))"
          v-model:search.sync="autocompleteSearchStockPart"
          :loading="$store.state.stock_parts.isSearching"
          :filter="filterStockParts"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("stock_parts.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
       <v-col class='ma-0 pa-0' v-if="askPlace">
          <StockagePlaceForm  
            :stockageId='stockageId'
            @subFormUpdate="subFormUpdateEvent"
          ></StockagePlaceForm
          ></v-col>
      <v-col cols="12" sm="4" md="2">
        <v-text-field
          v-model.number="editedItem.quantity"
          type="number"
          min="0"
          :label="$filters.capitalize($t('consumables.quantity'))"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StockagePlaceForm from "@/components/StockagePlaceForm";
import filters from "@/mixins/filters";
import { isNone, unProxy } from "@/functions.js";
import { isNumber } from "chart.js/helpers";

export default {
  methods: { isNumber },
  mixins: [filters],
  components: {
    StockagePlaceForm
  },

  computed: {
    editedItem: {
      get: function() {
        return this.$store.getters.dataEdit("consumables");
      },
      set: function(obj) {
        this.$store.dispatch("consumables/updateEdit", obj);
      }
    },
  },

  props: {
    askStockPart: { type: Boolean, required: false, default: true },
    askPlace: { type: Boolean, required: false, default: true },
    stockageId: { type: Number, required: false, default: undefined },
    placeId: { type: Number, required: false, default: undefined }
  },

  watch: {
    autocompleteSearchStockPart(val) {
      if (!this.$store.getters.canSearch("stock_parts")) return;
      this.$store.dispatch("stock_parts/searchItems", { value: val, is_unit: false });
    },
  },
  methods: {
    subFormUpdateEvent(type, place_id){
      this.editedItem.place_id = place_id;
    },
    stockPartProps(item) {
      if (typeof item !== 'object' || item === null) return;
      const titleParts = [];
      if (item.name) {
        titleParts.push(item.name);
      }
      if (item.name_en) {
        titleParts.push(item.name_en);
      }
      if (item.ref) {
        titleParts.push(item.ref);
      }
      const newItemTitle = titleParts.join(' -- ');
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
      return newItem;
    },

  },

  data() {
    return {
      autocompleteSearchStockPart: null
    };
  }
};
</script>
