<template>
  <v-container pa-0 ma-0>
    <h3>
      {{
        $filters.capitalize(
          $t("purchase_view.edit_subtitle", { name: lines[0].stockage__name })
        )
      }}
    </h3>
      <v-card
        class="px-3 mb-2 d-flex flex-wrap"
        v-for="(line, index) in lines"
        :key="index"
      >
        <v-row>
          <v-col cols="4">
            {{ concatenate(line.stock_part__name, line.stock_part__ref) }}
          </v-col>
          <v-col cols="2">
            {{ $filters.capitalize($t("purchase_view.quantity")) }}:
            {{ line.quantity }}
            -
            {{ $filters.capitalize($t("purchase_view.already_received")) }}:
            {{ line.qty_rcvd || 0 }}
          </v-col>
          <!-- CONSUMABLE RECEPTION -->
          <v-col cols="4"
            v-if="
              line.stock_part__unity != 'un' && line.qty_rcvd < line.quantity
            "
          >
            <v-select
              v-model="editedItem[index].place_id"
              :items="available_places"
              :item-props='placeProps'
              :label="$filters.capitalize($t('places.label', 1))"
              :clearable="true"
              variant="solo"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{
                      $filters.capitalize(
                        $t("search_for", { noun: $t("places.label", 1) })
                      )
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="2"
            v-if="
              line.stock_part__unity != 'un' && line.qty_rcvd < line.quantity
            "
          >
          <v-text-field
            :label="
              $filters.capitalize($t('purchase_view.received_quantity'))
            "
            type="number"
            oninput="if(this.value < 0) this.value = 0;"
            v-model="editedItem[index].r_qty"
            ></v-text-field>
          </v-col>

          <!-- UNIT RECEPTION -->
          <v-col cols="5" v-if="line.stock_part__unity == 'un'">
            <div v-for="(unit, index) in editedItem[index].units" :key="index">
              <v-select
                v-model="unit.place_id"
                :items="available_places"
                :item-props='placeProps'
                :label="$filters.capitalize($t('places.label', 1))"
                :clearable="true"
                variant="solo"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{
                        $filters.capitalize(
                          $t("search_for", { noun: $t("places.label", 1) })
                        )
                      }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-text-field
                v-model="unit.serial"
                :label="$filters.capitalize($t('units.serial', 1))"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-card>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import { concat } from "@/functions.js";

export default {
  mixins: [filters],

  computed: {
    purchase: function () {
      return this.$store.state[`purchases`].receivedItem;
    },
    lines: function () {
      return this.$store.state[`purchases`].receivedItem.lines;
    },
    editedItem: function () {
      return this.$store.state[`purchases`].receivedPayload;
    },
    available_places: function () {
      return this.$store.state[`places`].items;
    },
  },

  methods: {
    concatenate(...values) {
      return concat(...values);
    }, // dummy proxy -_-

    placeProps(item) {
      if (typeof item !== 'object' || item === null) return;

      const titleParts = [];

      if (item.cabinet) {
        titleParts.push(item.cabinet);
      }

      if (item.shelf) {
        titleParts.push(item.shelf);
      }

      const newItemTitle = titleParts.join(' - ');
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'default_value' };

      if (this.$store.getters.language === 'en' && item.ref) {
        newItem.selection = `${item.name} – ${item.ref}`;
      }

      return newItem;
    },
  },

  mounted() {
    if (this.purchase.lines[0] !== undefined) {
      // lines is an object -_-
      this.$store.dispatch("places/getItems", {
        stockage_id: this.purchase.lines[0].stockage_id,
        size: 999,
      });
    }
  },

  data() {
    return {
      moduleName: "purchases",
    };
  },
};
</script>
