<template>
  <v-container>
    <TelemetryChart class="pa-2"></TelemetryChart>
  </v-container>
</template>

<script>
import TelemetryChart from "@/components/TelemetryChart";

export default {
  components: {
    TelemetryChart,
  },

  mounted() {
    document.querySelector("#app div.v-main__wrap").style.backgroundColor =
      "#ffffff";
  },

  data() {
    return {};
  },
};
</script>
