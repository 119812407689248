<template>
  <v-container>
    <v-dialog
      v-model="showRangeSelection"
      width="unset"
      @click:outside="closeRangeSelection"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $filters.capitalize($t("export")) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <VueDatePicker
              v-model="range"
              v-if="showRangeSelection"
              on-click-outside="closeRangeSelection"
              :locale="$i18n.locale"
              :first-day-of-week="1"
              :inline="{ input: true }" text-input auto-apply
              range
              show-week
              :enable-time-picker="false"
            ></VueDatePicker>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary lighten-2"
            variant="text"
            @click="showRangeSelection = false"
          >{{ $filters.capitalize($t("cancel")) }}
          </v-btn
          >
          <v-btn color="success" @click="download()">{{ $filters.capitalize(
            $t("export"))
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h3 class="ml-4 mt-2">
      <v-icon>mdi-solar-panel-large</v-icon>
      {{ $filters.capitalize($t("production.section_solar")) }}&nbsp;:
      <!-- (<span v-html="$t('production.instantly', solar_monthly_instant)"></span>&nbsp;: {{$filters.formatFloat(solar_monthly_instant )}} kW &ndash; -->
      {{ $filters.formatFloat(solar_monthly_instant) }} kW
      {{ $t("production.instantly", solar_monthly_instant) }} &mdash;
      {{ $filters.formatFloat((solar_monthly_cumul / 1000)) }} MWh
      {{ $t("production.cumulative", solar_monthly_cumul / 1000) }}
    </h3>

    <v-table fixed-header class="my-2">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">{{ $filters.capitalize($t("production.name")) }}</th>
          <th class="text-left">{{ $filters.capitalize($t("production.power")) }}</th>
          <th class="text-left">
            {{ $filters.capitalize($t("production.last_data")) }}
          </th>
          <th class="text-left">{{ $filters.capitalize($t("actions.label")) }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="prod in solar_productions" :key="prod.producer.pk">
          <td>
            <router-link
              :to="{
                  name: 'intervention_reports',
                  query: { producer_id: prod.producer.pk },
                }"
            >
              {{ $filters.capitalize(prod.producer.display_name) }}
            </router-link
            >
          </td>
          <td>{{ $filters.formatFloat(prod.power) }} kW</td>
          <td :class="getLastDataClass(prod.time)">
            {{ $filters.formatDateTime(prod.time) }}
          </td>
          <td>
            <div class="download-div">
              <v-btn
                v-show="!showSpinner(prod.producer.pk)"
                @click.prevent="openRangeSelection(prod.producer.pk)"
                class="primary white--text"
                icon="true"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-progress-circular
                v-show="showSpinner(prod.producer.pk)"
                class="pr-3"
                size="28"
                :rotate="90"
                :indeterminate="true"
              ></v-progress-circular>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-table>
  </v-container>
</template>

<script>
import moment from "moment";
import { sumValues } from "@/functions.js";
import { isNone } from "@/functions.js";
import i18n from "@/i18n";
import utils from "@/mixins/utils";

export default {
  mixins: [utils],
  methods: {
    getLastDataClass(last) {
      var last_ = moment(last);
      if (last_ < this.limit_5_hour) {
        return "over_5h";
      } else if (last_ < this.limit_3_hour) {
        return "over_3h";
      } else if (last_ < this.limit_1_hour) {
        return "over_1h";
      }
      return "";
    },

    updateData() {
      this.$store
        .dispatch(`telemetries/solarCurrent_producerItems`)
        .then((items) => {
          this.solar_productions = items;
        });

      this.$store
        .dispatch(`telemetries/solarMonthlyProduction`)
        .then((items) => {
          var val = sumValues(items);
          this.solar_monthly_cumul = val;
        });
      this.$store
        .dispatch(`telemetries/solarInstantProduction`)
        .then((items) => {
          var val = sumValues(items);
          this.solar_monthly_instant = val;
        });
    },

    openRangeSelection(prod_id) {
      this.producer_id = prod_id;
      this.showRangeSelection = true;
    },

    closeRangeSelection() {
      this.showRangeSelection = false;
      this.producer_id = undefined;
    },

    download() {
      if (isNone(this.range) || this.range.length < 2) {
        const msg = i18n.global.t("snack.no_range");
        this.$store.dispatch("snackbar/showError", msg);
        return;
      }
      this.$store.dispatch("onLoading");
      var start = this.range[0];
      var end = this.range[1];
      if (start > end) {
        start = this.range[1];
        end = this.range[0];
      }
      const payload = {
        start: start,
        end: end,
        producer_id: this.producer_id
      };
      this.getFile(
        "telemetries/solar/producer/export_csv/",
        "",
        "text/csv",
        "blob",
        payload
      )
      this.closeRangeSelection();
    },

    showSpinner(prod_id) {
      return this.producer_id === prod_id;
    }
  },

  mounted() {
    this.updateData();

    this.$nextTick(function() {
      this.intervalId = window.setInterval(
        () => {
          this.updateData();
        },
        10 * 60 * 1000
      );
    });
  },

  unmounted() {
    window.clearInterval(this.intervalId);
  },

  data() {
    return {
      intervalId: undefined,
      wind_productions: [],
      solar_productions: [],
      solar_monthly_cumul: 0.0,
      solar_monthly_instant: 0.0,
      limit_1_hour: moment().subtract(1, "hours"),
      limit_3_hour: moment().subtract(3, "hours"),
      limit_5_hour: moment().subtract(5, "hours"),
      showRangeSelection: false,
      range: [],
      producer_id: undefined
    };
  }
};
</script>

<style scoped>
td.over_1h {
  color: #ebcc3d;
}

td.over_3h {
  color: #ca8109;
}

td.over_5h {
  color: #eb0a0a;
}
</style>
