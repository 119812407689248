import crud from "./crud";
import crud_service from "@/services/crud";

const path = "todos";
const struct = {
  description: "",
  fullfilled_at: null,
  user_id: null,
  producer_id: null,
  intervention_report_id: null,
  // is_risk is not part of model, only used for request handling
  is_risk: false,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  exportData(_, { exportType, params }) {
    return new Promise((resolve, reject) => {
      let endpoint = '';
  
      switch (exportType) {
        case 'internal':
          endpoint = 'todos/export_internal';
          break;
        case 'external':
          endpoint = 'todos/export_external';
          break;
        case 'done':
          endpoint = 'todos/export_done';
          break;
        default:
          reject(new Error('Invalid export type'));
          return;
      }
  
      crud_service
      .fetch(endpoint, params)
      .then((response) => {
    
        if (!response) {
          reject(new Error('No data received from the server.'));
          return;
        }
    
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${exportType}_export_${new Date().toISOString().slice(0, 10)}.csv`;
        link.click();
        URL.revokeObjectURL(link.href);
        resolve();
      })
      .catch((error) => {
        console.error('Export failed:', error);
        reject(error);
      });
    });
  },
  
  
  
  getUndone({ commit }, payload) {
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/get_undone`, payload)
        .then((items) => {
          commit("setItems", items);
          resolve(items);
        });
    });
  },
  getDone({ commit }, payload) {
    return new Promise((resolve, reject) => {
      crud_service
        .fetch(`${path}/get_done`, payload)
        .then((response) => {
          const items = Array.isArray(response) ? response : [];
          commit("setItems", items);
          resolve(items);
        })
        .catch((error) => {
          console.error("Error fetching done items:", error);
          reject(error);
        });
    });
  },
  
  

  markAsFullfilled({ commit }, { todo_id, fullfilled_at }) {
    return new Promise((resolve) => {
      crud_service
        .post(`todos/${todo_id}/fullfill`, { fullfilled_at: fullfilled_at })
        .then((item) => {
          commit("nothing");
          resolve(item);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
