import crud from "./crud";
import crud_service from "@/services/crud";

const path = "automatons";
const struct = {
  time: "",
  ip: "",
  raw: {},
  producer_id: null,
  /*controlPayload: {
    'time': '',
    'action': '',
    'status': null,
    'automaton_status_id': null,
    'producer_id': null,
  },*/
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  setCurrentProducerItems(state, items) {
    state.currentProducerItems = items;
  },
};

const actions = {
  ...generic.actions,

  getStatus({ commit }, options) {
    const url = `${path}/status`;
    var payload = options;
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((items) => {
        commit("setItems", items.results);
        commit("setPagination", items);
        commit("setIsLoading", false);
        resolve(items.results);
      });
    });
  },

  getCurrentStatus({ commit }, options) {
    const url = `${path}/status/current`;
    var payload = options;

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((items) => {
        commit("setCurrentProducerItems", items);
        resolve(items);
      });
    });
  },

  automatonControl({ commit }, { action, producer_id }) {
    const url = `${path}/status/control`;
    var payload = {
      producer_id: producer_id,
      action: action,
    };

    return new Promise((resolve) => {
      crud_service.post(url, payload).then((item) => {
        commit("nothing");
        resolve(item);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
