<template>
  <v-container>
    <TelemetryChartWind class="pa-2"></TelemetryChartWind>
  </v-container>
</template>

<script>
import TelemetryChartWind from "@/components/TelemetryChartWind";

export default {
  components: {
    TelemetryChartWind,
  },


  data() {
    return {};
  },
};
</script>
