import crud from "./crud";
import moment from "moment";

const path = "limitations";
const struct = {
  start: moment().startOf("day").format('YYYY-MM-DDThh:mm'),
  end: moment().startOf("day").format('YYYY-MM-DDThh:mm'),
  power_limit: 0,
  impact_dispo: false,
  comment: "",
  origin: "ui",
  producer_id: undefined,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  setStart(state, datetime) {
    state.edit.start = datetime;
  },
  setEnd(state, datetime) {
    state.edit.end = datetime;
  },
  ...generic.mutations,
};

const actions = {
  setStart({ commit }, datetime) {
    commit("setStart", datetime);
  },
  setEnd({ commit }, datetime) {
    commit("setEnd", datetime);
  },
  ...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
