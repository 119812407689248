<template>
  <v-card color="white" class="pa-2 elevation-1" density="comfortable">
    <v-row justify="space-around" align="start" density="compact">
      <v-col>
        <v-autocomplete
          v-model="producerIds"
          :items="$store.state.producers.all"
          :item-props="producerProps"
          :label="$filters.capitalize($t('producers.label', 1) )"
          :loading="$store.state.producers.isSearching"
          :filter="filterProducers"
          :clearable="true"
          :multiple="true"
          append-icon="mdi-text-box-search"
          variant="solo"
          density="compact"
          hide-details
          return-object
          @update:modelValue="handleChange"
          @click:clear="handleChange"
          >
          <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{
                $filters.capitalize(
                  $t("search_for", { noun: $t("producers.label", 1) })
                )
              }}
            </v-list-item-title>
          </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col>
        <VueDatePicker 
            v-model="dateRange"
            @update:model-value="handleChange"
            @cleared='handleChange'
            :teleport="true"
            range 
            :enable-time-picker="false"
        >
        </VueDatePicker>
      </v-col>

      <v-col cols="2">
        <v-select
          v-model="levelChoice"
          :items="levelOptions"
          :label="$filters.capitalize($t('alarm_codes.level', 1) )"
          :clearable="true"
          :multiple="true"
          density="compact"
          variant="solo"
          @update:modelValue="handleChange"
          @click:clear="handleChange"
        >
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="criticalityChoice"
          :items="criticalityOptions"
          :label="$filters.capitalize($t('alarm_codes.criticality.label', 1) )"
          :clearable="true"
          :multiple="true"
          density="compact"
          variant="solo"
          @update:modelValue="handleChange"
          @click:clear="handleChange"
        >
        </v-select>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";

import utils from "@/mixins/utils";
import filters from "@/mixins/filters";
import { capitalize, isNone, unProxy } from "@/functions";

export default {
  mixins: [utils, filters],
  emits: ["filterToP"],
  methods: {
    producerProps(item) {
      if (typeof item !== 'object' || item === null) return;
      const newItem = { ...item, title: item.display_name, selection: item.display_name, value: item.pk || 'error_item_props' };
      return newItem;
    },
    handleChange() {
      const dateFrom = this.dateRange?.[0] ?? '2020-01-01';
      const dateTo = this.dateRange?.[1] ?? moment().format("YYYY-MM-DD");
      const idList = this.producerIds.map(item => item.pk)
      
      const searchParams = {
        criticality: this.criticalityChoice,
        level: this.levelChoice,
        date_from: dateFrom,
        date_to: dateTo,
        producer_id: idList
      };
      this.$emit("filterToP", searchParams);
    },
    selectProdFromQuery(list = this.$store.state.producers.all) {
        const p_id = this.$route.query.producer_id 
        const proddy = list.filter(opt => opt.pk == p_id)[0]
        this.producerIds = [proddy];
    },
  },
  created() {
    if (this.$store.state.producers.all.length <= 0) this.$store.dispatch('producers/getAll').then((list)=>{
     if (!isNone(this.$route.query.producer_id)) this.selectProdFromQuery(list);
    })
    else this.selectProdFromQuery();
  },
  data() {
    return {
      moduleName: "alarms",
      search_params: {},
      producerIds: [],
      dropList: [],
      dateRange: ["2020-01-01", moment().add(1,'d').format("YYYY-MM-DD")],
      criticalityChoice: undefined,
      levelChoice: undefined,
      // TODO: these two arrays should be in store
      // // criticalityOptions may need to be updated if alarms (machines) change: SELECT distinct criticality FROM public.api_alarmcode
      criticalityOptions: [
        {
          value: "crit",
          title: capitalize(this.$t("alarm_codes.criticality.critical"))
        },
        {
          value: "warn",
          title: capitalize(this.$t("alarm_codes.criticality.warning"))
        },
        {
          value: "ok",
          title: capitalize(this.$t("alarm_codes.criticality.ok"))
        }
      ],
      // // levelOptions may need to be updated if alarms (machines) change: SELECT distinct 'level' FROM public.api_alarmcode
      levelOptions: [
        "B100",
        "B10",
        "B6",
        "B4",
        "B2",
        "B1",
        "W",
        "Nacelle alarm",
        "Communication",
        "Tower alarm",
        "Rotor alarm",
        0,
        1,
        2
      ]
    };
  }
};
</script>
