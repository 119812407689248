<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="8" md="6">
        <v-text-field
          v-model="editedItem.description"
          :label="$filters.capitalize($t('risks.description') )"
          :row-height="12"
          auto-grow
          variant="solo"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="8" md="6">
        <v-autocomplete
          v-model="editedItem.producer_id"
          :items="$store.state.producers.all"
          item-title="display_name"
          item-value="pk"
          :label="$filters.capitalize($t('producers.label', 1))"
          :loading="$store.state.producers.isSearching"
          :filter="filterProducers"
          append-icon="mdi-text-box-search"
          variant="solo"
          cache-items
          clearable
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize($t("search_for", { noun: $t("producers.label", 1) }))
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="8" md="6">
        <v-checkbox
          v-if="!this.newRisk"
          v-model="editedItem.closed"
          fluid
          :label="$filters.capitalize($t('risks.resolved', 1) )"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-card v-if="editedItem.todo_id">
      <v-card-title>{{ $filters.capitalize($t("risks.related_task") ) }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            {{ $filters.capitalize($t("todos.created_at") ) }}:
            {{ $filters.formatDate(editedItem.todo.created_at )}}
          </v-col>
          <v-col
            >{{ $filters.capitalize($t("generic.created_by") ) }}:
            {{ editedItem.todo.created_by.name }}
          </v-col>
          <v-col
            >{{ $filters.capitalize($t("todos.fullfilled_at") ) }}:
            {{ editedItem.todo.fullfilled_at }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        {{ $filters.capitalize($t("todos.description") ) }}:
        {{ editedItem.todo.description }}
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";

export default {
  mixins: [filters],

  props: {
    newRisk: { type: Boolean, required: false, default: true },
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  created() {
    if (this.$store.state.producers.all.length <= 0 ) this.$store.dispatch('producers/getAll')
    if (this.$route.query.producer_id) {
        this.editedItem.producer_id = this.$route.query.producer_id
    }
  },

  data() {
    return {
      moduleName: "risks",
    };
  },
};
</script>
