<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-textarea
          v-model="editedItem.description"
          :label="$filters.capitalize($t('todos.description') )"
          :row-height="12"
          auto-grow
          variant="solo"
        ></v-textarea>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="askProducer">
        <v-autocomplete
          v-model="editedItem.producer_id"
          :items="$store.state.producers.all"
          item-title="display_name"
          item-value="pk"
          :label="$filters.capitalize($t('producers.label', 1) )"
          :loading="$store.state.producers.isSearching"
          :filter="filterProducers"
          append-icon="mdi-text-box-search"
          variant="solo"
          cache-items
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          v-model="editedItem.assigned_to"
          :items="$store.state.users.search"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('external_repairs.assigned_to', 1) )"
          v-model:search="autocompleteSearchUser"
          :loading="$store.state.users.isSearching"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
          return-object
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="4" v-if="askFullfilledAt">
        <DatePicker
          v-bind:label="'todos.fullfilled_at'"
          v-bind:value="editedItem.fullfilled_at"
          @input="editedItem.fullfilled_at = $event"
        >
        </DatePicker>
      </v-col>

      <v-col cols="12" sm="6" md="4" v-if="askInterventionReport">
        <v-autocomplete
          v-model="editedItem.intervention_report"
          :items="$store.state.intervention_reports.search"
          :item-props="reportsProps"
          :label="$filters.capitalize($t('intervention_reports.label', 1) )"
          :loading="$store.state.intervention_reports.isSearching"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{$filters.capitalize(
                  $t("search_for", {
                    noun: $t("intervention_reports.label", 10),
                  }) )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-if="askRisk">
        <v-checkbox
          color="red"
          v-model="editedItem.is_risk"
          :label="$filters.capitalize($t(`risks.is_risk`) )"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import DatePicker from "@/components/DatePicker";

export default {
  components: {
    DatePicker: DatePicker,
  },

  mixins: [filters],

  created() {
    if (this.currentUser && this.currentUser.id) {
      this.editedItem.assigned_to = this.currentUser;
    }
    if (this.$store.state.producers.all.length <=0 && !this.$store.state.producers.isLoading) this.$store.dispatch("producers/getAll")
  },

  props: {
    askFullfilledAt: { type: Boolean, required: false, default: true },
    askInterventionReport: { type: Boolean, required: false, default: true },
    askProducer: { type: Boolean, required: false, default: true },
    askRisk: { type: Boolean, required: false, default: true },
  },

  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  watch: {
    autocompleteSearchInterventionReports(val) {
      if (!this.$store.getters.canSearch("intervention_reports")) return;
      this.$store.dispatch("intervention_reports/searchItems", { value: val });
    },
    autocompleteSearchUser(val) {
      if (!this.$store.getters.canSearch("users")) return;
      this.$store.dispatch("users/searchItems", { value: val });
    },
  },
  methods: {
    reportsProps (item) {
    item.value = item.pk
    item.title =  item.producer.description + this.$filters.formatDate(item.date ) + ' : '+item.cause
    return item
    },
  },

  data() {
    return {
      moduleName: "todos",
      autocompleteSearchInterventionReports: undefined,
      autocompleteSearchUser: undefined,
    };
  },
};
</script>
