<template>
  <div>
    <div style="display: none">
      <EditDialog
        v-bind:model="editRisk"
        v-bind:moduleName="'risks'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('risks.actions.add_item')"
        @save="saveRiskEvent"
        @close="cleanupRiskDialog"
        ref="editDialogRisk"
      >
        <template v-slot:edit_content>
          <RiskForm :newRisk="newRisk"></RiskForm>
        </template>
      </EditDialog>
    </div>
    <v-btn color="red" class="mb-2" @click="openEditRisk()">
      {{ $filters.capitalize($t(`risks.actions.add_item`) ) }}
    </v-btn>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { slicyUpdate, isNone } from "@/functions";

import filters from "@/mixins/filters";
import EditDialog from "@/components/EditDialog";
import RiskForm from "@/components/RiskForm";

export default {
  props: {
    newRisk: { type: Boolean, required: false, default: true },
  },
  mixins: [filters],
  components: {
    EditDialog,
    RiskForm,
  },
  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
    risk_edit: function () {
      return this.$store.state["risks"].edit;
    },
  },
  methods: {
    openEditRisk() {
      this.$store.dispatch(
        `risks/updateEdit`,
        this.$store.state["risks"].struct,
      );
      this.editRisk = true;
    },
    saveRiskEvent() {
      return this.$store.dispatch(`risks/addItem`, this.risk_edit).then(() => {
        slicyUpdate(this.risks, this.risk_edit);
        this.$refs.editDialogRisk.closeDialog();
        this.$emit("reload");
      });
    },
    cleanupRiskDialog() {
      this.editRisk = false;
      this.$store.dispatch(
        `risks/updateEdit`,
        this.$store.state["risks"].struct,
      );
    },
  },

  data() {
    return {
      moduleName: "risks",
      btnText: this.$t("home.risk"),
      editRisk: false,
      risks: [],
    };
  },
};
</script>
