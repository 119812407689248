import crud_service from "@/services/crud";

const path = "analytics";

const state = {
  items: [],
  isLoading: false,
};

const getters = {};

const mutations = {
  setItems(state, items) {
    state.items = items.concat(state.local_items);
  },
  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
};

const actions = {
  getByCause({ commit }, payload) {
    commit("setIsLoading", true);
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/cause`, payload).then((items) => {
        commit("setItems", items);
        commit("setIsLoading", false);
        resolve(items);
      });
    });
  },

  getByDuration({ commit }, payload) {
    commit("setIsLoading", true);
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/duration`, payload).then((items) => {
        commit("setItems", items);
        commit("setIsLoading", false);
        resolve(items);
      });
    });
  },

  getByFrequency({ commit }, payload) {
    commit("setIsLoading", true);
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/frequency`, payload).then((items) => {
        commit("setItems", items);
        commit("setIsLoading", false);
        resolve(items);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
