import crud from "./crud";
import crud_service from "@/services/crud"

const path = "stock_parts";
const struct = {
  name: "",
  name_en: "",
  ref: "",
  unity: "",
  // threshold: 0,
  price: null,
  price_currency: "EUR",
  resell_price_currency: "EUR",
  is_locally_bought: true,
  image: null,
  category_id: null,
  category: { producer_types: [] }, // to avoid errors on empty load
  supplier_ids: [],
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  getToUnitInfo({ commit }, pk) {
    commit("setIsLoading", true);
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/to_unit_info`, {id:pk})
        .then((items) => {
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
      });
  },
  convertToUnit({ commit }, pk) {
    commit("setIsLoading", true);
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/to_unit`, {id:pk})
        .then((items) => {
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
    },
...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
