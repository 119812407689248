import crud from "./crud";

const path = "alarm_codes";
const struct = {
  level: "",
  name: "",
  code: "",
  criticality: "",
  enabled: true,
  producer_type_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
