<template>
  <FullCalendar :options="calendarOptions" />
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

export default defineComponent({
  name: 'CalendarView',
  components: { FullCalendar },
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const calendarOptions = ref({
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      initialView: 'dayGridMonth',
      editable: false,
      selectable: false,
      events: props.events,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
    });

    watch(() => props.events, (newEvents) => {
      calendarOptions.value = {
        ...calendarOptions.value,
        events: newEvents
      };
    });

    return { calendarOptions };
  }
});
</script>

<style scoped>
</style>
