<template>
  <v-container>
            <v-autocomplete
                v-show="!stockageId"
              v-model="stockageSelected"
              :items="$store.state.stockages.all"
              :item-props='stockageProps'
              :label="$filters.capitalize($t('stockages.label', 1))"
              :clearable="true"
              variant="solo"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="placeSelected"
              :items="availablePlaces"
              :item-props='placeProps'
              :loading='$store.state.places.isLoading'
              :disabled='$store.state.places.isLoading'
              :label="$filters.capitalize($t('places.label', 1))"
              :clearable="true"
              variant="solo"
            >
            </v-autocomplete>
  </v-container>
</template>
<script>
  import {isNone} from "@/functions.js";

  export default {
    computed: {

      availablePlaces: function(){
        return this.$store.state.places.items
      }
    },
    props: {
      stockageId: {type: Number, required: false},
      // producerTypeId: {}, // groundwork for stockage filtering by p-type
    },
    methods:{
    placeProps(item) {
      if (typeof item !== 'object' || item === null) return;

      const titleParts = [];
      if (item.cabinet) {
        titleParts.push(item.cabinet);
      }
      if (item.shelf) {
        titleParts.push(item.shelf);
      }
      const newItemTitle = titleParts.join(' - ');
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'default_value' };
      if (this.$store.getters.language === 'en' && item.ref) {
        newItem.selection = `${item.name} – ${item.ref}`;
      }
      return newItem;
    },

    stockageProps(item) {
      item.title = item.name + ' -- ' + item.city
      item.value = item.pk
      return item
    },
    },
    mounted(){
      this.stockageSelected = this.$store.state.stockages.edit;
      this.placeSelected = this.$store.state.places.edit;
      if (this.$store.state.stockages.all.length<=0){
        this.$store.dispatch('stockages/getAll')
      };
      if ((this.$store.state.places.items).some(({ pk }) => pk === this.placeSelected?.pk)) return;
      if (this.stockageId){
        console.log('here', this.stockageId, this.placeSelected, this.stockageSelected)
        this.$store.dispatch("places/getItems", {
        stockage_id: this.stockageId,
        size: -1,
        });
      };
    },
    unmounted(){
      this.$store.dispatch('stockages/cleanupEdit');
      this.$store.dispatch('places/cleanupEdit');
    },
    watch: {
      placeSelected(val){
      this.$emit("subFormUpdate", "ss", val);
      },
      stockageSelected(val){
        if (val !== null && typeof val ==='object') val = val.pk
        if (isNone(val)) { 
          this.$store.dispatch('places/cleanupItems', )
        } else {
          this.$store.dispatch('places/getItems', {stockage_id:val, size: -1})
          };
        this.$store.dispatch('places/cleanupEdit');
        if (this.placeSelected?.stockage_id !== val){
            this.placeSelected = undefined;
            };
        },
    },
    data() {
      return {
        stockageSelected: undefined,
        placeSelected: undefined,
      }
    },
};
</script>
