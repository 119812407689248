import crud from "./crud";
import crud_service from "@/services/crud";

const path = "spot_prices";
const struct = {
  price: "",
  price_currency: "EUR",
  region: "FR",
  date: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  getCurrent({ commit }) {
    const url = `${path}/current`;

    return new Promise((resolve) => {
      crud_service.fetch(url).then((item) => {
        commit("setItems", [item]);
        resolve(item);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
