import {createI18n}  from 'vue-i18n'

const loadI18nDataFromCache = () => {
    const data = localStorage.getItem(`lang_currentUser`);
    return data ? JSON.parse(data) : null;
};


function loadLocaleMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i,
    );
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
        }
    });
    return messages;
}

const cachedI18nData = loadI18nDataFromCache();
const i18n = createI18n({
    legacy: false,
    locale: cachedI18nData || process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: cachedI18nData || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    globalInjection: true,
    messages: loadLocaleMessages(),
});

export default i18n;
