import crud from "./crud";
import crud_service from "@/services/crud";

const path = "telemetries";
const struct = {
  time: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  currentProducerItems: {},
  currentPowerStationItems: [],
  producerIds: [],
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  setCurrentProducerItems(state, items) {
    state.currentProducerItems = items;
  },
  setCurrentPowerStationItems(state, items) {
    state.currentPowerStationItems = items;
  },
  setProducerIds(state, ids) {
    state.producerIds = ids;
  },
};

const actions = {
  ...generic.actions,

  getPdlList({ commit }, options) {
    const url = `${path}/wind/pdl_list`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        commit("setProducerIds", Object.keys(data));
        resolve(data);
      });
    });
  },

  solarCurrent_producerItems({ commit }, options) {
    const url = `${path}/solar/producer/current`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        commit("setCurrentProducerItems", data);
        resolve(data);
      });
    });
  },

  windCurrent_powerStationItems({ commit }, options) {
    const url = `${path}/wind/power_station/current`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        commit("setCurrentPowerStationItems", data);
        resolve(data);
      });
    });
  },

  windCurrent_producerItems({ commit }, options) {
    const url = `${path}/wind/producer/current`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        commit("setCurrentProducerItems", data);
        resolve(data);
      });
    });
  },

  windCurrent_pdlItems({ commit }) {
    const url = `${path}/wind/producer/current_in_pdls`;

    return new Promise((resolve) => {
      crud_service.fetch(url).then((data) => {
        commit("setCurrentProducerItems", data);
        resolve(data);
      });
    });
  },

  getCurrent_producerItems({ commit }, options) {
    const url = `${path}/wind/producer/current`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        var _data = {};
        if(data !== undefined){
          data.forEach(function (item) {
            _data[item.producer_id] = item;
          });
          commit("setCurrentProducerItems", _data);
          resolve(data);
        }else{
          console.log("Wind producer current undefined");
        }

      });
    });
  },

  solarInstantProduction({ commit }, options) {
    const url = `${path}/solar/power_station/instant_production`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        commit("nothing");
        resolve(data);
      });
    });
  },

  solarMonthlyProduction({ commit }, options) {
    const url = `${path}/solar/power_station/monthly_production`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        commit("nothing");
        resolve(data);
      });
    });
  },

  windInstantProduction({ commit }, options) {
    const url = `${path}/wind/power_station/instant_production`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        commit("nothing");
        resolve(data);
      });
    });
  },

  windMonthlyProduction({ commit }, options) {
    const url = `${path}/wind/power_station/monthly_production`;
    var payload = {};
    if (options) {
      if (options.ids !== undefined) {
        payload["id"] = options.ids;
      }
    }

    return new Promise((resolve) => {
      crud_service.fetch(url, payload).then((data) => {
        commit("nothing");
        resolve(data);
      });
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
