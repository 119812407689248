import crud from "./crud";

const path = "power_curves";
const struct = {
  description: "",
  curve_type: "",
  //curve: {},
  updated_at: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  moySearch: [],
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  setMoySearch(state, items) {
    state.moySearch.splice(0, state.search.length, ...items);
    // slice is needed to force update (while emptying for example)
  },
};

const actions = {
  ...generic.actions,

  setMoySearch({ commit }, item) {
    commit("setMoySearch", [item]);
  },

  initMoySearch({ dispatch }, options) {
    options["action"] = "setMoySearch";
    return dispatch("initSearch", options);
  },

  searchMoyItems({ dispatch }, options) {
    options["curve_type"] = "moy";
    options["action"] = "setMoySearch";
    return dispatch("searchItems", options);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
