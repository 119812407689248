import { mapState } from "vuex";

import {
  formatDate as fd,
  formatDateTime as fdt,
  formatDateCustom as fdC,
  formatDateTimeCustom as fdtC,
  extractFilename,
  isNone,
  unProxy as unP,
} from "@/functions.js";
import { api } from "@/services/api";

export default {
  computed: mapState({
    module(state) {
      return this.getClassText(state, this.moduleName);
    },
    items() {
      return this.module.items;
    },
    search() {
      return this.module.search;
    },
    pagination() {
      return this.module.pagination;
    },
    loading() {
      return this.module.isLoading;
    },
    dataStruct() {
      return this.module.struct;
    },
    editedItem() {
      return this.module.edit;
    },
    isFromStaff() {
      return this.$store.getters.isUserFromStaff;
    },
    stockpart_category_name() {
      if (this.$store.getters.language === "en" && "category__name_en" !== "") {
        // TODO: if name__en == "" then name
        return "category__name_en";
      }
      return "category__name";
    },
    stockpart_name() {
      if (this.$store.getters.language === "en") {
        return "stock_part.name_en";
      }
      return "stock_part.name";
    },
    stockparts_name() {
      if (this.$store.getters.language === "en") {
        return "stock_parts.name_en";
      }
      return "stock_parts.name";
    }
  }),

  methods: {
    unProxy(value) {
      return unP(value);
    },
    formatDate(value) {
      return fd(value);
    },

    formatDateTime(value) {
      return fdt(value);
    },

    formatDateCustom(value) {
      return fdC(value);
    },

    formatDateTimeCustom(value) {
      return fdtC(value);
    },

    // This seems to be legacy code, not only is it not used
    // but the back provides this information as affectation.target
    // commenting out for now. TODO: delete
    // findKindId: function(item) {
    //     // Find the correct id linked to an affectation
    //     switch (item.kind) {
    //         case 'er':
    //             return item.external_repair_id
    //         case 'ir':
    //             return item.internal_repair_id
    //         case 'ss':
    //             return item.stock_state_id
    //         case 'ts':
    //             return item.trash_state_id
    //         case 'p':
    //             return item.producer_id
    //         default:
    //             return null
    //     }
    // },
    getAffectationName: function(item) {
      if (!isNone(item.producer_id)) return item.producer.display_name;
      if (!isNone(item.stock_state)) return item.stock_state.stockage.name;
      return null;
    },

    getClassText: function(hay, fieldName) {
      // Get property attribute from field name as string
      if (Object.prototype.hasOwnProperty.call(hay, fieldName)) {
        return hay[fieldName];
      }
      return;
    },

    genPaginationPayload: function(options) {
      // Generate query params for pagination queries
      // options is linked to data-table v-options.sync
      let order = [];
      if (!isNone(options) && options.sortBy) {
        options.sortBy.forEach((x,i)=>{
          order.push(x.order=='asc'? x.key.replace(/\./g,'__'): '-' + x.key.replace(/\./g, '__'))
        })
      }
      // until vyuetify implements multiple sortBy fields
      let payload = {
        ordering: order[0]
      };
      if (!isNone(options)) {
        payload["page"] = options.page;
        payload["size"] = options.itemsPerPage | options.size;
      }
      return payload;
    },

    delayedSearch(value, dispatch_, options, callback) {
      clearTimeout(this.debounceTimer);
    
      const debounceTime = 350;
    
      const searchFunction = () => {
        if (!isNone(value)) {
          options["search"] = value;
        }
        this.$store.dispatch(dispatch_, options || {}).then(callback);
      };
    
      this.debounceTimer = setTimeout(searchFunction, debounceTime);
    },
    

    getFile(path, filename, type_, responseType, payload) {
      // responseType: arraybuffer, document, json, text, stream, blob
      api.defaults.timeout = 30000;
      api
        .get(path, {
          responseType: responseType,
          params: payload
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: type_ });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = extractFilename(response.headers, filename);
          link.click();
          URL.revokeObjectURL(link.href);
          this.$store.dispatch("offLoading");
        })
        .catch(console.error);
    },
    getFilePromise(path, filename, type_, responseType, payload) {
      return new Promise((resolve, reject) => {
        api.defaults.timeout = 30000;
        api
          .get(path, {
            responseType: responseType,
            params: payload
          })
          .then((response) => {
            const blob = new Blob([response.data], { type: type_ });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = extractFilename(response.headers, filename);
            link.click();
            URL.revokeObjectURL(link.href);
            this.$store.dispatch("offLoading");
            resolve();
          })
          .catch((error) => {
            console.error("Error in file download");
            reject(error);
          });
      });
    }
  },

  data() {
    return {
      debounceTimer: undefined
    };
  }
};
