import crud from "./crud";
import crud_service from "@/services/crud";

const path = "parts/real_list";
const struct = {
  name: "",
  name_en: "",
  ref: "",
  is_locally_bought: true,
  unity: "",
  quantity: 0,
  min_quantity: 0,
  max_quantity: null,
  place_ids: [],
  place_names: [],
  serials: [],
  category__name: "",
  category: null,
  part_id: null,
  image: null,
  image_url: null,
};

const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  getView({ commit }, options) {
    var payload = {};
    if (options) {
      //   if (options.size !== undefined) { payload["size"] = options.size }
      if (options.stockage !== undefined) {
        payload["stockage_id"] = options.stockage;
      }
      if (options.producer_type !== undefined) {
        payload["producer_type_id"] = options.producer_type;
      }
      if (options.lang !== undefined) {
        payload["lang"] = options.lang;
      }
    }
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}`, payload)
        .then((items) => {
          commit("setItems", items);
          // commit('setPagination', items)
          commit("setIsLoading", false);
          resolve();
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  saveFullPart({ commit }, payload) {
    // Create a new full part and stock_part (and stockpartlimit)
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .post(`parts/full`, payload)
        .then((resp) => {
          commit("setIsLoading", false);
          resolve(resp);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  updateFullPart({ commit }, payload) {
    // Update a full part and stock_part (and stockpartlimit)
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .put(`parts/full`, payload)
        .then((resp) => {
          commit("setIsLoading", false);
          resolve(resp);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },

  deleteFullPart({ commit }, pk) {
    // Cleanly delete a full part+stock_part
    return new Promise((resolve) => {
      crud_service.delete(`parts/full`, pk).then(() => {
        commit("deleteItem", pk);
        resolve();
      });
    });
  },

  setInvisible({ commit }, item) {
    // take out line from list
    return new Promise((resolve) => {
      crud_service.put("stock_part_limits", item).then((response) => {
        commit("deleteItem", item.list_id);
        resolve(response);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
