<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          v-model="localLine.stock_part.name"
          readonly
          :label="$filters.capitalize($t('stock_parts.name'))"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="2">
        <v-text-field
          v-model.number="localLine.quantity"
          type="number"
          :disabled="!creation"
          oninput="if(this.value < 0) this.value = 0;"
          :label="$filters.capitalize($t('purchase_lines.quantity'))"
          @change="updateLine"
        ></v-text-field>
      </v-col>
      <v-col cols="8" sm="4" md="2">
        <v-text-field
          v-model.number="localLine.price"
          type="number"
          :clearable="true"
          oninput="if(this.value < 0) this.value = 0;"
          :label="$filters.capitalize($t('purchase_lines.price') )"
          @change="updateLine"
        ></v-text-field>
      </v-col>
      <v-col cols="4" sm="4" md="2">
        <SelectCurrency
          v-bind:currency="localLine.price_currency"
          @change="chooseCurrency"
        ></SelectCurrency>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        stock: {{ localLine.current_stock }} | min:
        {{ localLine.min_quantity }} | max: {{ localLine.max_quantity }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isNone } from "@/functions.js";
import SelectCurrency from "@/components/SelectCurrency.vue";

export default {
  props: {
    line: { type: Object, required: true },
    ordinal: { type: Number, required: true },
    creation: { type: Boolean, required: false, default: true },
  },
  components: { SelectCurrency: SelectCurrency },

  methods: {
    chooseCurrency(value) {
      this.localLine.price_currency = value;
      this.updateLine();
    },
    updateLine(){
      if (this.creation) {
        this.$store.dispatch('purchase_lines/updateSelected',
          {index: this.ordinal, item: this.localLine})
      } else {
        this.$store.dispatch('purchases/updateLine',
          {index: this.ordinal, item: this.localLine})
      };
    },
  },

  computed: {
    selected: function() {
      if (this.creation) {
        return this.$store.state[`${this.moduleName}`].selected;
      }
      return this.$store.state[`purchases`].edit.lines;
    },
    },

  data() {
    return {
      moduleName: "purchase_lines",
      localLine: JSON.parse(JSON.stringify(this.line)),
    }
  }
};
</script>
