import axios from "axios";
import Cookies from "js-cookie";

import i18n from "@/i18n.js";
import router from "@/router";
import store from "@/store";
import { capitalize } from "@/functions";

var headers = {
  "Content-Type": "application/json; charset=utf-8",
  "X-CSRFToken": Cookies.get("csrftoken")
};

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api",
  timeout: 22000,
  headers: headers
});

export const cancelSource = axios.CancelToken.source();

// Intercept queries to inject auth_token and bearer token, if needed
api.interceptors.request.use(
  (config) => {
    if (config.params === undefined) {
      config.params = {};
    }
    const auth_token = localStorage.getItem("auth_token");
    if (auth_token !== null) {
      config.params["auth_token"] = auth_token;
    }
    const token = localStorage.getItem("token");
    if (token !== null && !("auth_token" in config.params)) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    // https://github.com/svrcekmichal/redux-axios-middleware/issues/83#issuecomment-407466397
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Intercept expired token and renegociate it
api.interceptors.response.use(undefined, ( error ) => {
  if (error.response !== undefined) {
    if (error.response.status === 401) {
      if (error.response.config.url.includes("api/token")) {
        // Login errors are treated in Login.vue
        return Promise.reject(error.response.data);
      }
      return store.dispatch("to_login").then(() => {
        router.push({ name: "login" });
      });
    }
    var message = capitalize(
      i18n.global.t("error.default", { spec: error.response.statusText })
    );
    if (error.response.data !== undefined) {
      if (error.response.data.message !== undefined) {
        message = error.response.data.message;
      } else if (error.response.data.detail !== undefined) {
        message = error.response.data.detail;
      }
    }
    store.dispatch("snackbar/showError", message);
    return Promise.reject(error.response.data);
  }

  var message_ = capitalize(i18n.global.t("error.unknown"));
  store.dispatch("snackbar/showError", message_);
  return Promise.reject(message_);
});

export { api };
