import crud from "./crud";
import crud_service from "@/services/crud";

const path = "producers";
const struct = {
  description: "",
  commissioned_at: null,
  ordinal: null,
  power_station_id: null,
  producer_type_id: null,
  power_curve_min_id: null,
  power_curve_moy_id: null,
};
const generic = crud(path, struct);

const state = {
  all: [],
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  setAll(state, items) {
    state.all= items
  },
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
  getAll({commit}) {
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {
      crud_service.fetch(path).then((items)=>{
        commit("setAll",items.results);
        resolve(items.results);
        commit('setIsLoading', false);
        }).catch((e)=>{
        reject();
    })})
  },
  getMine({ commit }) {
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/mine`).then((items) => {
        commit("setItems", items);
        resolve(items);
      });
    });
  },

  getSolarPanel({ commit }) {
    commit('setIsLoading', true);
    return new Promise((resolve, reject) => {
      crud_service.fetch(`${path}/get_solar_panel`)
        .then((items) => {
          commit("setAll", items);
          resolve(items);
          commit('setIsLoading', false);
        })
        .catch((error) => {
          commit('setIsLoading', false);
          console.error('Error fetching solar panel data:', error);
          reject(error);
        });
    });
  },
  
  getWithAutomatons({ commit }, { search }) {
    const payload = {
      search: search,
    };
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/with_automatons`, payload).then((items) => {
        commit("setSearch", items);
        resolve(items);
      });
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
