<template>
  <v-container>
    <v-tooltip location="top" :text="$filters.capitalize( btnName ) ">
      <template v-slot:activator="{ on }">
        <v-icon
          v-bind="on"
          @click="scanDialog = true"
          color="primary"
          size="x-large"
          :disabled="!scanEnabled"
        >mdi-qrcode-scan</v-icon>
      </template>
    </v-tooltip>
    <v-dialog
      v-model="scanDialog"
      height="50ex"
      width="50ex"
      :scrollable="false"
    >
      <v-card class="pa-2">
            <v-text-field
            outlined
            v-model="result"
            :label="$filters.capitalize($t('units.code') )"
            ></v-text-field>
            <v-btn color="primary" @click="onDecode(result)">{{$filters.capitalize( $t('add') ) }}</v-btn>
        <v-card-text>
          <qrcode-stream @decode="onDecode" @init="onInit" :camera="camera"
          ><p class="error">
            {{ error }}
          </p>
          </qrcode-stream>
        </v-card-text>
        <v-card-actions>
            <v-btn v-show="camera == 'off'" @click="camera = 'auto'">on</v-btn>
            <v-btn v-show="camera != 'off'" @click="camera = 'off'">off</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { QrcodeStream } from "vue3-qrcode-reader";
import { isNone } from "@/functions.js";

export default {
  components: { QrcodeStream },
  props: {
    btnName: { type: String, required: false, default: "scan code" },
    scanEnabled: { type: Boolean, required: false, default: true },
    newCode: {type: Boolean, required: false, default: false},
  },
  methods: {
    onDecode(result) {
      if (isNone(result)) {
            this.$store.dispatch(
            "snackbar/showWarning",
            this.$t("units.missing.code")
            );
            return true;
      }

      this.result = result;
      if (this.result.includes("qr_code=")) {
        this.result = this.result.split("qr_code=")[1];
        this.result = this.result.split("&")[0]; //in case other params are coded into qr_code
      }
      this.$emit("decoded", this.result);
      this.$store
        .dispatch("unit_codes/getItems", { qr_code: this.result })
        .then((unit) => {
          if(this.newCode){
            if (unit.length >= 1){
              this.$emit("fetchedUnit", unit[0]);
            this.$store.dispatch('units/updateEdit', unit[0])
            return true
            }
          } else {
            if (unit.length == 0){
              this.$store.dispatch("snackbar/showWarning", this.$t("units.code_error"));
              return true;
            }
            this.$emit("fetchedUnit", unit[0]);
            this.$store.state.units.local_items.push(unit[0])
          }
        });
        // TODO: error handling - snackbar?
        this.camera = "off";
        this.scanDialog = false;  
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        // TODO: locale error message
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
  computed:{
    mode(){return process.env.NODE_ENV}
  },
  data() {
    return {
      scanDialog: false,
      result: "",
      error: "",
      camera: "auto", // Valid Inputs: auto, rear, front, off
    };
  },
};
</script>
