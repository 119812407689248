<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName" :actionDelete='false'>
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  v-model="editedItem.name"
                  :label="$filters.capitalize($t('producer_types.name') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <v-select
                  v-model="editedItem.variety"
                  :items="$store.state.varietyItems"
                  item-title="name"
                  item-value="id"
                  :label="$filters.capitalize($t('variety.label', 1) )"
                  variant="solo"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  v-model="editedItem.cut_in"
                  type="number"
                  min="0"
                  :label="$filters.capitalize($t('producer_types.cut_in') )"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";

export default {
  components: {
    Crud: Crud,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  created() {},

  data() {
    return {
      moduleName: "producer_types",
      headers: [
        { title: this.$t("producer_types.name"), key: "name" },
        { title: this.$t("variety.label"), key: "variety" },
        { title: this.$t("producer_types.cut_in"), key: "cut_in" },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
