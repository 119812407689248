<template>
  <v-container>
    <v-card color="white" class="flat pa-2" height="auto">
      <v-row>
        <v-col cols="12" sm="7" md="6">
          <v-select
            v-model="edition.producer_type_id"
            :items="$store.state.producer_types.items"
            item-title="name"
            item-value="pk"
            :label="$filters.capitalize($t('producer_types.label', 1) )"
            :loading="$store.state.producer_types.isLoading"
            variant="solo"
            outlined
            :clearable="true"
            return-object
            persistent-hint
            :hint="$filters.capitalize($t('producer_types.label') )"
            @update:modelValue="chooseProdTypeEvent"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="7" md="6">
          <v-switch
            v-model="includeGeneric"
            :label="$filters.capitalize($t('part_edition.include_generic') )"
            :loading="$store.state.stock_parts.setIsLoading"
          >
          </v-switch>
        </v-col>

        <v-col cols="12" sm="5" md="6">
          <v-select
            v-model="edition.part_category_id"
            :items="$store.state.part_categories.items"
            item-title="name"
            item-value="pk"
            :label="$filters.capitalize($t('part_categories.label') )"
            :loading="$store.state.part_categories.isLoading"
            variant="solo"
            :clearable="true"
            persistent-hint
            :hint="$filters.capitalize($t('part_categories.label') )"
            @update:modelValue="choosePartCategoryEvent"
          >
          <template #no-data>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("part_categories.label", 1) })
                  )
                }}
          </template>
          <template #item="{ item, props: { onClick } }">
              <span v-if="$store.getters.language === 'en' && item.raw.name_en != ''">
                <v-list-item 
                  @click="onClick">
                {{item.raw.name_en}}
                </v-list-item>     
              </span>
              <span v-else>
                <v-list-item 
                  @click="onClick">
                {{item.raw.name}}
                </v-list-item>                 
              </span>
            </template>
            <template #selection="{item}">
              <span v-if="$store.getters.language === 'en' && item.raw.name_en != ''">
                {{item.raw.name_en}}
              </span>
              <span v-else>
                {{item.raw.name}}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-btn-toggle v-model="isUnit" color="deep-blue-accent-3" group>
            <v-btn value="true">
              {{ $filters.capitalize(this.$t("units.label", 10)) }}
            </v-btn>
            <v-btn value="false">
              {{ $filters.capitalize(this.$t("consumables.label", 10)) }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="searchStockPartName"
            append-icon="mdi-folder-search"
            :label="$filters.capitalize($t('stock_parts.name', 1) )"
            single-line
            hide-details
            :clearable="true"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="$store.state.stock_parts.items"
        :v-model="selectedStockPart"
        :no-data-text="$filters.capitalize($t('no_data_text') )"
        :no-results-text="$filters.capitalize($t('no_results_text') )"
        :loading="$store.state.stock_parts.isLoading"
        @click:row="chooseStockPartEvent"
        elevation="2"
        fixed-header
      >
        <template v-slot:item.unity="{ item }">
          {{ $filters.capitalize($filters.unityFilter(item.unity)) }}
        </template>

        <template v-slot:item.image="{ item }">
          <v-img
            :src="item.image"
            max-height="32px"
            max-width="32px"
            contain
          ></v-img>
        </template>

        <template v-slot:item.price="{ item }">
          {{ $filters.formatPrice(item.price, item.price_currency) }}
        </template>

        <template v-slot:item.suppliers="{ item }">
          <ul v-if="item.suppliers !== undefined">
            <li
              v-for="supplier in item.suppliers"
              class="supplier"
              v-bind:key="supplier.pk"
            >
              {{ $filters.capitalize(supplier.name) }}
            </li>
          </ul>
        </template>
      </v-data-table>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4">
        <v-btn @click="newStockPartEvent">{{ $filters.capitalize(
          $t("stock_parts.actions.new_item"))
          }}
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-card>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import { capitalize } from "@/functions.js";
import utils from "@/mixins/utils";
import { isNone } from "@/functions.js";
import { toRaw } from "vue";

export default {
  mixins: [filters, utils],

  props: {
    edition: { type: Object, required: true }
  },

  computed: {
    part_name: function() {
      if (this.$store.getters.language === "en") {
        return "name_en";
      }
      return "name";
    }
  },

  methods: {
    updateStockPartList() {
      if (this.$store.state.stock_parts.isLoading) return;
      this.$store.dispatch("stock_parts/getItems", {
        producer_type_id: this.edition.producer_type_id,
        category_id: this.edition.part_category_id,
        is_unit: this.isUnit,
        include_generic: this.includeGeneric,
        value: this.searchStockPartName
      });
    },
    updateCats(producer_type) {
      if (!this.$store.state.part_categories.isLoading) {
        if (!isNone(producer_type)) {
          // avoid requests with id[]=undefined
          this.$store.dispatch("part_categories/getItemsFromProducerType", [
            producer_type.pk
          ]);
        } else {
          this.$store.dispatch("part_categories/getItems");
        }
      }
    },
    chooseProdTypeEvent(value) {
      this.$emit("chooseProdType", value);
      if (this.edition.part_category_id === undefined) {
        this.updateCats(value);
      }
      this.updateStockPartList();
    },
    choosePartCategoryEvent(value) {
      this.$emit("choosePartCategory", value);
      this.updateStockPartList();
    },
    chooseStockPartEvent(event, value) {
      this.$store
        .dispatch(`${this.moduleName}/getItem`, toRaw(value.item.pk))
        .then((item) => {
          this.$store.dispatch(`${this.moduleName}/updateEdit`, item);
          this.$emit("chooseStockPart", item);
        });
      this.searchStockPartName = "";
    },
    newStockPartEvent() {
      this.$store.dispatch("stock_parts/cleanupEdit");
      this.$emit("newStockPart");
    }
  },
  watch: {
    searchStockPartName(val) {
      this.delayedSearch(val, `${this.moduleName}/getItems`, {
        producer_type_id: this.edition.producer_type_id,
        category_id: this.edition.part_category_id,
        is_unit: this.isUnit,
        include_generic: this.includeGeneric
      });
    },
    isUnit() {
      this.updateStockPartList();
    },
    includeGeneric() {
      this.updateStockPartList();
    }
  },
  data() {
    return {
      moduleName: "stock_parts",
      searchStockPartName: "",
      selectedStockPart: undefined,
      isUnit: undefined,
      includeGeneric: true,
      image: undefined,
      headers: [
        { title: this.$t("stock_parts.name"), key: "name" },
        { title: this.$t("stock_parts.name_en"), key: "name_en" },
        { title: this.$t("stock_parts.ref"), key: "ref" },
        { title: this.$t("stock_parts.price"), key: "price" },
        { title: this.$t("stock_parts.image"), key: "image" },
        {
          title: capitalize(this.$t("unity.label", 1)),
          key: "unity",
          align: " d-none d-md-table-cell"
        },
        {
          title: capitalize(this.$t("suppliers.label", 10)),
          key: "suppliers",
          align: " d-none d-md-table-cell"
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false
        }
      ]
    };
  }
};
</script>
