import moment from "moment";

export default {
  namespaced: true,

  state: {
    start: moment().startOf("day").toDate(),
    end: moment().endOf("day").toDate(),
  },

  getters: {
    datetime: (state) => (start) => (start ? state.start : state.end),
  },

  actions: {
    setStart({ commit }, datetime) {
      commit("setStart", datetime);
    },
    setEnd({ commit }, datetime) {
      commit("setEnd", datetime);
    },
  },

  mutations: {
    setStart(state, datetime) {
      state.start = datetime;
    },
    setEnd(state, datetime) {
      state.end = datetime;
    },
  },
};
