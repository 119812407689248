<template>
  <div></div>
</template>

<script>
export default {
  created: function () {
    this.$store.dispatch("logout").then(() => {
      this.$router.push({ name: "login" });
    });
  },
};
</script>
