<template>
  <v-card v-show="isExistingUnit" class="pa-2 mt-3 mx-0">
    <v-card-title
      >{{$filters.capitalize( $t("units.affected_units") ) }}
      <v-icon small right @click="loadList()">mdi-refresh</v-icon>
    </v-card-title>
    <v-col>
      <v-data-table
        class="ma-4"
        :headers="headers_aff"
        :items="affectedUnits"
        :options.sync="optionsTable"
        :server-items-length="pageSize"
        :hide-default-footer="affectedUnits.length < 10"
        :loading="$store.state.affectations.isLoading"
        :footer-props="footerProps"
        density= 'compact'
      >
        <template v-slot:item.kind="{ item }">
          {{$filters.capitalize($filters.affectationKindFilter( item.kind ) ) }}
        </template>
        <template v-slot:item.date="{ item }">
          {{$filters.formatDate( item.date ) }}</template
        >
        <template v-slot:item.target="{ item }">{{
          getAffectationName(item)
        }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip location="top" :text="$filters.capitalize( $t('actions.edit'))">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" @click="viewUnit(item.unit_id)">
                mdi-eye-outline
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
  </v-card>
</template>

<script>
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { isNone } from "@/functions";
export default {
  mixins: [filters, utils],
  props: {
    showSPS: { type: Boolean, required: false },
    stock_part_id: { type: Number, required: false },
  },
  computed: {
    isExistingUnit() {
      return this.$store.state.stock_parts.edit.unity === "un" &&
        !isNone(this.stock_part_id);
    },
  },
  methods: {
    viewUnit(id) {
      let query = {
        part: String(id).toString(),
      };
      this.$router.push({ name: "unit", query: query });
    },
    getAffectedUnits(stock_part_id) {
if (this.$store.state.affectations.isLoading) return
      let options = this.genPaginationPayload(this.optionsTable);
      this.$store
        .dispatch("affectations/getOthers", {
          stock_part_id: stock_part_id,
          options: options,
        })
        .then((items) => {
          this.affectedUnits = items.results;
          this.pageSize = items.size;
        });
    },
    loadList() {
      if (!isNone(this.stock_part_id)) {
        this.getAffectedUnits(this.stock_part_id);
      }
    },
  },
  mounted() {
    let q_stock_part_id = parseInt(this.$route.query.stock_part_id)  || undefined;

    if (!isNone(q_stock_part_id)) {
      this.getAffectedUnits(q_stock_part_id);
      return;
    }
    this.$store.dispatch("affectations/cleanupItems");
    this.loadList();
  },
  watch: {
    showSPS() {
      if (isNone(this.stock_part_id)) {
        this.$store.dispatch("affectations/cleanupItems");
        return;
      }
      this.getAffectedUnits(this.stock_part_id);
    },
    optionsTable: {
      handler() {
        this.getAffectedUnits(this.stock_part_id);
      },
      deep: true,
    },
  },
  data() {
    return {
      moduleName: "units",
      affectedUnits: [],
      optionsTable: {},
      pageSize: undefined,
      headers_aff: [
        {
          title: this.$t("affectations.status.label"),
          key: "kind",
          sortable: true,
        },
        {
          title: this.$t("generic.date"),
          key: "date",
          sortable: true,
        },
        {
          title: this.$t("affectations.label", 1),
          key: "target",
          sortable: false,
        },
        {
          title: this.$t("units.serial", 1),
          key: "unit.serial",
          sortable: true,
        },
        {
          title: this.$t("units.code", 1),
          key: "unit.qr_code",
          sortable: true,
        },
        // // // TODO: add on serializer
        // {
        //   title: this.$t("generic.created_at", 1),
        //   key: "unit.serial",
        //   sortable: false,
        // },
        {
          title: "",
          key: "actions",
          sortable: false,
        },
      ],
      footerProps: {
        "items-per-page-options": [10, 20, 50, -1],
        "items-per-page-text": this.$t("rows_per_page"),
      },
    };
  },
};
</script>
