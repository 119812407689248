import crud from "./crud";

const path = "producer_types";
const struct = {
  name: "",
  variety: "",
  cut_in: 0.0,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
