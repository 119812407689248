<template>
  <v-container>
    <v-row>
      <v-col col="12">
        <TelemetryChartSolar
          class="pa-2"
          v-bind:producer_ids="[omburu_id, osona_id, aussenkehr_id]"
          v-bind:height="420"
        ></TelemetryChartSolar>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <TelemetryChartWind
          class="pa-2"
          v-bind:producer_ids="ombepo_ids"
          v-bind:height="420"
        ></TelemetryChartWind>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TelemetryChartSolar from "@/components/TelemetryChartSolar";
import TelemetryChartWind from "@/components/TelemetryChartWind";

export default {
  components: {
    TelemetryChartWind,
    TelemetryChartSolar,
  },

  mounted() {
    document.querySelector("#app div.v-main__wrap").style.backgroundColor =
      "#ffffff";
  },

  data() {
    return {
      omburu_id: 144,
      osona_id: 143,
      aussenkehr_id: 145,
      ombepo_ids: [64, 65, 66],
    };
  },
};
</script>
