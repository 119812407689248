<template>
  <v-container>
    <v-card class="mx-auto my-3">
      <v-toolbar flat color="white" elevation="1">
        <v-spacer></v-spacer>

        <v-toolbar-title style="font-weight: bolder"
          >{{ $filters.capitalize($t(`prod_availability.label`, 1) ) }}
        </v-toolbar-title>
        <v-col>
          <v-btn
            color="primary"
            elevation="3"
            class="ml-3"
            variant="outlined"
            @click.prevent="openRangeSelection()"
          >
            <v-progress-circular
              v-show="$store.state.loading"
              class="mr-3"
              size="28"
              :indeterminate="true"
            ></v-progress-circular>
            {{ $filters.capitalize($t(`actions.download`) ) }}
          </v-btn>
        </v-col>
        <v-col>
          <VueDatePicker
            v-model="range"
            :teleport="true"
            :locale="$i18n.locale"
            :first-day-of-week="1"
            @update:model-value="download()"
            :enable-time-picker="false"
            range
            show-week
          ></VueDatePicker>
        </v-col>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr style="width: 100%">
              <th
                :class="'text-left ' + stickyStateClass"
                style="width: 130px; height: 36px !important"
              >
                {{ $filters.capitalize($t("prod_availability.date", 10) ) }}
              </th>
              <th
                :class="'text-left th-date ' + stickyStateClass"
                v-for="sheet in dateKeys"
                :key="sheet.id"
              >
                {{ $filters.formatDat(sheet.date) }}
              </th>
              <th
                :class="'text-left ' + stickyStateClass"
                style="width: auto; height: auto"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in PAitems" :key="item.id">
              <td>{{ item.display_name }}</td>
              <td colspan="10">
                <v-sheet
                  v-for="sheet in item.values"
                  :key="sheet.id"
                  :color="get_color_int(sheet.value)"
                  :class="'sheet_step ' + get_step_ink(sheet.value)"
                  elevation="3"
                  height="32"
                  width="32"
                  rounded
                  >{{ $filters.formatAvailability(sheet.value) }}</v-sheet
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-card>
  </v-container>
</template>

<script>
import { isNone } from "@/functions.js";
import i18n from "@/i18n";
import utils from "@/mixins/utils";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { VueDatePicker },

  mixins: [utils],

  created() {
    this.$store.dispatch(`${this.moduleName}/getMonthItems`).then((items) => {
      this.PAitems = items;
    });
  },

  computed: {
    dateKeys: function () {
      if (!isNone(this.PAitems) && this.PAitems.length > 0) {
        return this.PAitems[0].values.filter((p, i) => i % 5 === 0);
      }
      return [];
    },
  },

  methods: {
    rgbToHex(r, g, b) {
      // int '255' color to hex
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    },

    pickHex(color1, color2, weight) {
      // interpolate color between two colors
      const w1 = weight;
      const w2 = 1 - w1;
      const rgb = [
        Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2),
      ];

      return this.rgbToHex(rgb[0], rgb[1], rgb[2]);
    },

    get_color_int(value) {
      // interpolate color from a gradient
      const value_ = parseFloat(value);
      if(isNone(value)) return '#ffffff'
      var step = 0;
      for (var i = 0; i < this.gradient.length; i++) {
        const val = this.gradient[i][0];
        if (value_ <= val) {
          step = i;
          break;
        }
      }
      var begin = this.gradient[step - 1];
      if (step === 0) {
        begin = this.gradient[step];
      }
      const end = this.gradient[step];
      var ratio = 1 - (end[0] - value_) / (end[0] - begin[0]);
      if (isNone(ratio) && !isNaN(value_)) {
        ratio = 0;
      }
      const hex = this.pickHex(end[1], begin[1], ratio);
      return hex;
    },

    get_step_ink(value) {
      // get text color for text inside sheep
      const value_ = parseFloat(value);
      if (value_ < 50) {
        return "text-inverted";
      }
    },

    download() {
      if (isNone(this.range) || this.range.length < 2) {
        const msg = i18n.global.t("snack.no_range");
        this.$store.dispatch("snackbar/showError", msg);
        return;
      }

      this.$store.dispatch("onLoading");
      var start = this.range[0];
      var end = this.range[1];
      if (start > end) {
        start = this.range[1];
        end = this.range[0];
      }
      const payload = {
        start: start,
        end: end,
        variety: "w",
      };
      this.getFile(
        "prod_availabilities/export_xls/",
        "",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "blob",
        payload,
      );
    },
  },

  data() {
    return {
      moduleName: "prod_availabilities",
      PAitems: undefined,
      range: [],
      stickyStateClass: "",
      gradient: [
        [0, [0, 0, 0]],
        [
          60,
          [255, 0, 0], // #FF0000
        ],
        [
          80,
          [255, 242, 0], // #FFF200
        ],
        [
          97,
          [30, 150, 0], // #1E9600
        ],
        [
          100,
          [30, 150, 0], // #1E9600
        ],
      ],
    };
  },
};
</script>

<style scoped>
/* Vuetify ugly sticky header hack */
:deep( .v-data-table__wrapper) {
  height: calc(100vh - 90px) !important;
}

.sheet_step {
  display: inline-block;
  margin: 0 1px;
  text-align: center;
  padding-top: 6px;
  margin-bottom: 5px;
}
.text-inverted {
  color: white;
}
.th-date {
  width: 170px;
  height: 36px !important;
}

.download-div > div.v-picker {
  position: absolute;
  right: 15px;
  top: 54px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.no-sticky {
  position: inherit !important;
}
</style>
