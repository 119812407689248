import crud from "./crud";
import crud_service from "@/services/crud";

const path = "risks";

const struct = {
  //   pk: null,
  producer_id: null,
  todo_id: null,
  description: "",
  closed: false,
  // created_at: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
  delete({ commit }, pk) {
    // Cleanly delete a risk
    return new Promise((resolve) => {
      crud_service.delete(`${path}`, pk).then(() => {
        commit("deleteItem", pk);
        resolve();
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
