<template>
  <v-container>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ props }">
        <v-text-field
          :label="$filters.capitalize($t(label))"
          v-bind="props"
          v-model="formattedDate"
          variant="underlined"
          @change="onChange"
          @input="updateDate"
        >
          <template v-slot:append-inner>
            <v-icon v-if="showIcon">mdi-calendar</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="selectedDate"
        @update:modelValue="updateDate"
      ></v-date-picker>
    </v-menu>
  </v-container>
</template>
<script>

export default {
  props: {
    label: { type: String, required: true },
    value: { type: [String, Object], required: false, default: "" },
    showIcon: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      selectedDate: null,
      formattedDate: null,
      menu: false,
    };
  },
  watch: {
    value(newVal) {
      this.selectedDate = new Date(newVal);
      this.formattedDate = this.formatDate(newVal);
    },
  },
  methods: {
    updateDate(val) {
      this.selectedDate = new Date(val);
      this.formattedDate = this.formatDate(val);
      this.$emit('input', this.formattedDate);
    },

    formatDate(val) {
      if (!val) return '';
      const date = new Date(val);
      if (isNaN(date.getTime())) return '';
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      this.menu = false;
      return `${year}-${month}-${day}`;
    },

    onChange(val) {
      console.error(val);
    },
  },
  created() {
    this.selectedDate = new Date(this.value);
    if (this.value === null){
      this.selectedDate = new Date();
    }
    this.formattedDate = this.formatDate(this.value);
  },
};

</script>