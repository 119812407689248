<template>
  <v-select
    v-model="value"
    :items="currencies"
    item-title="symbol"
    item-value="value"
    :disabled="disabled"
    @update:modelValue="changed"
    :label="$filters.capitalize($t('generic.currency') )"
  ></v-select>
</template>

<script>
export default {
  props: {
    currency: { type: String, required: false, default: "EUR" },
    disabled: { type: Boolean, required: false, default: false },
  },

  methods: {
    changed(newValue) {
      this.$emit("change", newValue);
    },
  },

  watch: {
    currency(newValue) {
      // changed in parent
      this.value = newValue;
    },
  },

  data() {
    return {
      value: this.currency,
      currencies: [
        { symbol: "€", value: "EUR" },
        { symbol: "$US", value: "USD" },
        { symbol: "N$", value: "NAD" },
        { symbol: "KMF", value: "KMF" },
        { symbol: "CFA (CEMAC)", value: "XAF" },
        { symbol: "CFA (UEMOA)", value: "XOF" },
      ],
    };
  },
};
</script>
