import crud from "./crud";
import crud_service from "@/services/crud";

const path = "part_categories";
const struct = {
  name: "",
  name_en: "",
  producer_type_ids: [],
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,

  getItemsFromProducer({ commit }, producer_ids) {
    // list cats of a specific producer
    const payload = {
      id: producer_ids,
      size: 300,
    };

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/producers`, payload)
        .then((items) => {
          commit("setItems", items.results);
          resolve(items);
        })
        .catch(() => {});
    });
  },

  getItemsFromProducerType({ commit }, producer_type_ids) {
    // list cats of a specific producer_type
    const payload = {
      id: producer_type_ids,
    };

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/producer_types`, payload)
        .then((items) => {
          commit("setItems", items.results);
          resolve(items);
        })
        .catch(() => {});
    });
  },

  searchItemsFromProducerType({ commit }, { value, producer_types }) {
    // search categories of specific producer
    commit("setIsSearching", true);
    const payload = {
      search: value,
      id: [producer_types],
    };
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/producer_types`, payload)
        .then((items) => {
          commit("setSearch", items.results);
          commit("setIsSearching", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsSearching", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
