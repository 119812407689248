import { isNone } from "@/functions.js";

export default {
  namespaced: true,

  state: {
    visibility: false,
    message: "",
    color: "info",
    timeout: 8000,
  },

  getters: {},

  actions: {
    showMessage({ commit }, infos) {
      commit("showMessage", infos);
    },

    showError({ commit }, message) {
      commit("setColor", "error");
      commit("showMessage", { message: message });
    },

    showWarning({ commit }, message) {
      commit("setColor", "warning");
      commit("showMessage", { message: message });
    },

    showInfo({ commit }, message) {
      commit("setColor", "info");
      commit("showMessage", { message: message });
    },

    showSuccess({ commit }, message) {
      commit("setColor", "success");
      commit("showMessage", { message: message });
    },
  },

  mutations: {
    showMessage(state, items) {
      state.message = items.message;
      if (!isNone(items.color)) {
        state.color = items.color;
      }
      state.visibility = true;
    },
    setColor(state, color) {
      state.color = color;
    },
  },
};
