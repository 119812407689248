<template>
  <v-container>
    <AlarmSearch @filterToP="changeP($event)"></AlarmSearch>
    <AlarmCount :search_params="{...search_params}"></AlarmCount>
    <AlarmList :search_params="{...search_params}"></AlarmList>
  </v-container>
</template>

<script>
import moment from "moment";

import AlarmSearch from "@/components/alarms/AlarmSearch.vue";
import AlarmCount from "@/components/alarms/AlarmCount.vue";
import AlarmList from "@/components/alarms/AlarmList.vue";

export default {
  components: { AlarmSearch, AlarmCount, AlarmList },
  methods: {
    changeP(search_params) {
      this.search_params = search_params;
    }
  },
  created(){ this.changeP({producer_id: [this.$route.query.producer_id]});},
  data() {
    return {
      search_params: undefined
    };
  }
};
</script>
