<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="12">
        <v-icon>mdi-card-account-mail-outline</v-icon> {{ item.name }} &lt;
        <a :href="'tel:' + item.contact">{{ item.contact }}</a> &gt;
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        {{$filters.capitalize( $t("suppliers.address") ) }} : {{ item.address }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <ul class="no-bullets">
          <li>
            <v-icon>mdi-cellphone-basic</v-icon
            ><a :href="'tel:' + item.phone1">{{ item.phone1 }}</a>
          </li>
          <li>
            <v-icon>mdi-cellphone-basic</v-icon
            ><a :href="'tel:' + item.phone2">{{ item.phone2 }}</a>
          </li>
          <li>
            <v-icon>mdi-cellphone-basic</v-icon
            ><a :href="'tel:' + item.phone3">{{ item.phone3 }}</a>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },

  data() {
    return {
      moduleName: "suppliers",
    };
  },
};
</script>

<style scoped>
.no-bullets {
  list-style-type: none;
}
</style>
