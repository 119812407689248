import crud from "./crud";
import crud_service from "@/services/crud";
import { isNone } from "@/functions";

const path = "appointments";
const struct = {
  name: "",
  description: "",
  ilk: "",
  start: "",
  end: "",
  is_locked: "",
  is_remote: null,
  user_id: null,
  producer_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  schedules: {},
  user_stats: {
    days: [],
    number_of_days: undefined,
    sat_triggers: [],
    sat_triggers_total: 0,
    sun_triggers: [],
    sun_triggers_total: 0,
  },
  user_stats_state: false,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  setUserStats(state, stats) {
    state.user_stats = stats;
  },
  setUserStatsState(state, value) {
    state.user_stats_state = value;
  },
};

const actions = {
  ...generic.actions,

  getUserStats({ commit }) {
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/user_stats`)
        .then((item) => {
          if (isNone(item)) {
            commit("setUserStatsState", false);
          } else {
            commit("setUserStats", item);
            commit("setUserStatsState", true);
          }
          resolve();
        })
        .catch(() => {});
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
