import crud from "./crud";
import crud_service from "@/services/crud";
import { isNone } from "@/functions.js";
import moment from "moment";

const path = "purchases";
const struct = {
  ordered_at: moment().format("YYYY-MM-DD"),
  comment: "",
  delay: null,
  quoted_at: null,
  paid_at: null,
  sent_at: null,
  received_at: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  receivedItem: {},
  receivedPayload: [],
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  updateReceived(state, { item, lines }) {
    state.receivedItem = Object.assign({}, item);
    state.receivedItem.lines = Object.assign({}, lines);
    state.receivedPayload = Object.assign([]);
    for (var index in lines) {
      var line = lines[index];
      var init_dict = { purchase_line_id: line.pk };
      if (line.stock_part__unity == "un") {
        init_dict.units = Array.from(
          { length: line.quantity - line.qty_rcvd },
          () => ({ place_id: null, serial: null }),
        );
      } else {
        init_dict.place_id = null;
        line.quantity - line.qty_rcvd > 0
          ? (init_dict.r_qty = null)
          : (init_dict.r_qty = 0);
        // set to null to force user to enter 0, set to zero when reception complete
      }
      state.receivedPayload.push(init_dict);
    }
  },
  updateLine(state, { index, item }) {
    const newValue = Object.assign(state.edit.lines[index], item);
    state.edit.lines.splice(index, 1, newValue);
  },
};

const actions = {
  ...generic.actions,

  getLines({ commit }, item) {
    // Get all lines of a purchase
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/${item.pk}/lines`).then((items) => {
        commit("updateReceived", { item: item, lines: items });
        resolve();
      });
    });
  },

  updateLine({ commit }, { index, item }) {
    if (
      item.id !== undefined &&
      state.edit.lines.findIndex((x) => x.id === item.id) !== -1
    ) {
      const msg = i18n.global.t("purchase_lines.error_duplicated", { id: item.id });
      store.dispatch("snackbar/showError", msg);
      return;
    }
    commit("updateLine", { index, item });
  },

  getOpenedPurchases({ commit }, { stockage_id, producer_type_id, options }) {
    // Get unclosed purchase orders
    if (isNone(options)) {
      options = {};
    }
    if (!isNone(stockage_id)) {
      options["stockage_id"] = stockage_id;
    }
    if (!isNone(producer_type_id)) {
      options["producer_type_id"] = producer_type_id;
    }
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/opened`, options).then((items) => {
        commit("setItems", items.results);
        resolve(items);
      });
    });
  },

  getArchivedPurchases({ commit }, { stockage_id, producer_type_id, options }) {
    // Get archived purchase orders
    if (isNone(options)) {
      options = {};
    }
    if (!isNone(stockage_id)) {
      options["stockage_id"] = stockage_id;
    }
    if (!isNone(producer_type_id)) {
      options["producer_type_id"] = producer_type_id;
    }
    commit("setIsLoading", true)
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/archived`, options).then((items) => {
        commit("nothing");
        commit("setIsLoading", false)
        resolve(items);
      });
    });
  },

  received({ commit, state }) {
    // Integrate current received purchase
    return new Promise((resolve) => {
      crud_service
        .post(
          `${path}/${state.receivedItem.pk}/received`,
          state.receivedPayload,
        )
        .then((item) => {
          commit("nothing");
          resolve(item);
        });
    });
  },

  canceled({ commit }, pk) {
    // Cancel desired purchase
    return new Promise((resolve) => {
      crud_service.post(`${path}/${pk}/canceled`).then((freed_lines) => {
        commit("nothing");
        resolve(freed_lines);
      });
    });
  },

  updateState({ commit }, { pk, state, date }) {
    // Update state of a purchase
    return new Promise((resolve) => {
      crud_service
        .post(`${path}/${pk}/update_state`, { state: state, date: date })
        .then((resp) => {
          commit("nothing");
          resolve(resp);
        });
    });
  },

  add_lines({ commit }, { pk, items }) {
    // Add some lines to a purchase
    return new Promise((resolve, reject) => {
      crud_service
        .post(`${path}/${pk}/add_lines`, items)
        .then((status) => {
          commit("nothing");
          resolve(status);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
