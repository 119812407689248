<template>
  <v-container>
    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import exportingInit from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import HighchartsMore from 'highcharts/highcharts-more';
import { capitalize } from "@/functions.js";

exportingInit(Highcharts);
HighchartsMore(Highcharts);

export default {
  props: {
    producer_ids: {
      type: Array,
      required: false,
      default: () => undefined,
    },
    height: {
      type: Number,
      required: false,
      default: 860,
    },
  },

  data() {
    return {
      producers: [],
      intervalId: null,
      chartOptions: this.initializeChartOptions(),
    };
  },

  computed: {
    title() {
      return capitalize(this.$route.query.title);
    },

    producerIds() {
      return this.producer_ids || this.$route.query.id.split(",").map(Number);
    },

    labels() {
      return this.producers.map((producer) => producer.display_name);
    },
  },

  methods: {
    initializeChartOptions() {
      return {
        legend: {
          enabled: true,
          backgroundColor: '#ffffff',
          align: 'center',
          borderColor: '#ddd',
          borderRadius: 5,
          borderWidth: 1,
          itemStyle: {
            color: '#333',
            fontWeight: 'bold',
            fontSize: '14px',

          },
          itemHoverStyle: {
            color: '#000',
          },
        },
        chart: {
          type: "column",
          height: this.height,
          backgroundColor: '#f4f4f4',
          spacingTop: 20,
          spacingBottom: 20,
          spacingLeft: 10,
          spacingRight: 10,
          style: {
            color: '#000',
            textOutline: 'none',
            fontWeight: 'bold',
          },
        },
        title: {
          text: this.title,
          align: 'center',
          style: {
            color: '#2c3e50',
            fontSize: '24px',
            fontWeight: 'bold',
            fontFamily: "'Montserrat', sans-serif",
            textTransform: 'uppercase',
            letterSpacing: '1px',
            textShadow: '1px 1px 2px #bdc3c7',
          },
          margin: 20,
        },

        xAxis: {
          categories: [],
          crosshair: true,
          labels: {
            style: {
              color: '#666',
              fontWeight: 'bold',
            },
          },
        },
        yAxis: [
          {
            min: 0,
            tickInterval: 250,
            alignTicks: false,
            endOnTick: true,
            maxPadding: 0.1,
            title: {
              text: "Ensoleillement (Wh/m2)",
              style: {
                color: '#f1c40f',
                fontWeight: 'bold',
              },
            },
            labels: {
              style: {
                fontWeight: 'bold',
              },
            },
          },
          {
            min: 0,
            tickInterval: 0.5,
            alignTicks: false,
            endOnTick: true,
            maxPadding: 0.1,
            title: {
              text: "Puissance (KW)",
              style: {
                color: '#e74c3c',
                fontWeight: 'bold',
              },
            },
            opposite: true,
            labels: {
              style: {
                fontWeight: 'bold',
              },
              format: "{value}",
            },
          },
        ],
        tooltip: {
          shared: true,
          backgroundColor: '#ffffff',
          borderColor: '#ddd',
          borderRadius: 5,
          style: {
            color: '#333',
            fontWeight: 'bold',
          },
          headerFormat: "<b>{point.key}</b><br>",
          pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} <br>',
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0.05,
            borderRadius: 8,
            dataLabels: {
              enabled: true,
              style: {
                color: '#000',
                textOutline: 'none',
                fontWeight: 'bold',
              },
              formatter: function () {
                let unit = 'Error'

                if (this.series.name === 'Ensoleillement Horizontal' || this.series.name === 'Ensoleillement Tracker') {
                  unit = 'Wh/m2'
                } else if (this.series.name === 'Puissance' || this.series.name === 'Theoretical Production'){
                  unit = 'Mw'
                }
                return this.y.toFixed(2) + ' ' +unit;
            },
              y: -10
            },
            shadow: {
              color: 'rgba(0,0,0,0.1)',
              offsetX: 1,
              offsetY: 2,
              width: 3,
            },
          },
        },
        series: [
          {
            name: "Ensoleillement Horizontal",
            data: [],
            tooltip: { valueSuffix: " Wh/m2" },
            color: "#f1c40f",
            yAxis: 0,
          },
          {
            name: "Ensoleillement Tracker",
            data: [],
            tooltip: { valueSuffix: " Wh/m2" },
            color: "#f39c12",
            yAxis: 0,
          },
          {
            name: "Puissance",
            data: [],
            tooltip: { valueSuffix: " Mw" },
            color: "#e74c3c",
            yAxis: 1,
          },
          {
            name: "Theoretical Production",
            type: "errorbar",
            data: [],
            tooltip: {
              valueSuffix: " Mw",
            },
            color: "black",
            yAxis: 1,
            zIndex: 3,
            grouping: false,
            borderColor: "#3498db",
            borderWidth: 1,
          },
        ],
      };
    },

    getOrderByProducer(data) {
      if (!Array.isArray(data) || !Array.isArray(this.producers)) {
        throw new Error('Invalid input: both data and producers should be arrays.');
      }

      const producerMap = new Map(data.map((d, index) => [d.producer.pk, index]));

      return this.producers.map((p) => {
        const index = producerMap.get(p.pk);
        
        if (index === undefined) {
          console.error(`Producer with pk ${p.pk} not found in data.`);
          return -1;
        }
        
        return index;
      });
    },


    parseData(data) {
      return this.getOrderByProducer(data).map((order) => {
        const { power, radiance_hor, radiance_vert, performance_ratio_actual_month_max, performance_ratio_actual_month_min } = data[order];
        return {
          power: power > 0 ? power/ 1000: 0,
          sun_h: radiance_hor,
          sun_v: radiance_vert,
          performance_ratio_actual_month_max: performance_ratio_actual_month_max / 1000,
          performance_ratio_actual_month_min: performance_ratio_actual_month_min / 1000
        };
      });
    },
    //Wh /m2
    updateData() {
      const payload = { ids: this.producerIds };
      this.$store.dispatch("telemetries/solarCurrent_producerItems", payload)
        .then((data) => {
          const parsedData = this.parseData(data);
          this.chartOptions.series[0].data = parsedData.map((x) => x.sun_h);
          this.chartOptions.series[1].data = parsedData.map((x) => x.sun_v);
          this.chartOptions.series[2].data = parsedData.map((x) => x.power);
          this.chartOptions.series[3].data = parsedData.map((x) => [
            x.performance_ratio_actual_month_max,
            x.performance_ratio_actual_month_min 
          ]);

        });
    },

    setDailyRefresh(hours, minutes) {
      const now = new Date();
      const nextRefresh = new Date();
      nextRefresh.setHours(hours, minutes, 0, 0);

      if (now >= nextRefresh) {
        nextRefresh.setDate(now.getDate() + 1);
      }

      const timeout = nextRefresh.getTime() - now.getTime();
      setTimeout(() => window.location.reload(true), timeout);
    },

    initializeProducers() {
      this.$store.dispatch("producers/getItems", { ids: this.producerIds })
        .then((producers) => {
          this.producers = producers;
          this.chartOptions.xAxis.categories = this.labels;
          this.updateData();
        });
    },

    setupAutoRefresh() {
      this.intervalId = setInterval(this.updateData, 20000);
    },

    clearAutoRefresh() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },

  mounted() {
    this.chartOptions.title.text = this.title;

    this.initializeProducers();
    this.$nextTick(this.setupAutoRefresh);
    this.setDailyRefresh(13, 30);
  },

  unmounted() {
    this.clearAutoRefresh();
    this.producers = [];
  },
};
</script>

<style scoped>
div.highcharts-label .v-icon.mdi {
  color: #bbbbbb;
}
</style>
