<template>
  <div class="locale-changer">
    <v-select
      v-model="currentUserLang"
      :items="langs"
      :label="$root.$i18n.locale"
      variant="solo"
      @update:modelValue="setLocale"
    >
    </v-select>
  </div>
</template>

<script>

export default {
  name: "locale-changer",

  created() {
    this.getLangCurrentUser();
  },

  methods: {
    getLangCurrentUser() {
      this.currentUserLang = this.$root.$i18n.locale;
    },
    setLocale(locale) {
      this.$store.dispatch("setCurrentUserLang", locale);
    },
  },

  data() {
    return {
      langs: ["fr", "en"],
      currentUserLang: "",
    };
  },
};
</script>
