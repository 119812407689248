<template>
    <v-toolbar class="elevation-1">
      <v-toolbar-title style="font-weight: bolder">
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-data-table-server
      :headers="alarmHeaders"
      :items="items"
      :items-length="pagination.size || 0"
      :items-per-page="pagination.page_size"
      :page="pagination.current"
      :loading="loading"
      :items-per-page-options="footerProps['items-per-page-options']"
      :items-per-page-text="footerProps['items-per-page-text']"
      :no-data-text="$filters.capitalize($t('no_data_text') )"
      @update:options="loadOptions"
    >
      <template v-slot:item.time="{ item }">
        {{ $filters.formatFullDateTime(item.time) }}
      </template>
    </v-data-table-server>
</template>

<script>
import utils from "@/mixins/utils";
import filters from "@/mixins/filters";
import { capitalize, unProxy } from "@/functions";

export default {
  props: ["search_params"],
  mixins: [utils, filters],
  methods: {
    loadList() {
      const searchParams = {
        producer_id: this.search_params.producer_id,
        level: this.search_params.level,
        criticality: this.search_params.criticality,
        start: this.search_params.date_from,
        end: this.search_params.date_to
      };
      const payload = {
        ...this.paginationOptions,
        ...searchParams
      };
      this.$store.dispatch("alarms/getItems", payload);
    },

    loadOptions({page, itemsPerPage, sortBy}){
        this.paginationOptions = this.genPaginationPayload(
            {"page": page, "size": itemsPerPage, "sortBy":sortBy})
        this.loadList()
    },
  },
  watch: {
    search_params() {
      // reset page when doing new search
        this.paginationOptions.page = 1;
        this.pagination.current = 1;
      this.loadList();
    },
  },

  data() {
    return {
      moduleName: "alarms",
      alarmHeaders: [
        {
          title: this.$t("producers.label"),
          key: "producer.display_name", sortable: true
        },
        { title: this.$t("alarms.time"), key: "time" , sortable: true},
        { title: this.$t("alarms.name"), key: "alarm_code.name" , sortable: true},
        { title: this.$t("alarms.code"), key: "alarm_code.code" , sortable: true}
      ],
      title: capitalize(this.$t("alarms.label", 10)),
      paginationOptions: {
        "page":1, "size":10,
      },
      footerProps: {
        "items-per-page-options": [10, 20, 50],
        "items-per-page-text": this.$t("rows_per_page")
      },
    };
  }
};
</script>
