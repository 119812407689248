<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName">
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  v-model="editedItem.name"
                  :label="$filters.capitalize($t('power_stations.name') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  v-model="editedItem.power"
                  :label="$filters.capitalize($t('power_stations.power') )"
                  :rules="positiveIntRule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  v-model="editedItem.description"
                  :label="$filters.capitalize($t('power_stations.description') )"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="8" md="6">
                <v-autocomplete
                  v-model="editedItem.stockage_id"
                  :items="$store.state.stockages.search"
                  item-title="name"
                  item-value="pk"
                  :label="$filters.capitalize($t('stockages.label') )"
                  v-model:search="autocompleteSearchStockage"
                  :loading="$store.state.stockages.isSearching"
                  :filter="filterStockages"
                  append-icon="mdi-text-box-search"
                  :clearable="true"
                  variant="solo"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{$filters.capitalize(
                          $t("search_for", { noun: $t("stockages.label", 1) })
                            )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template #item="{item, props:{onClick}}">
                   <v-list><v-list-item @click="onClick" :title= "item.raw.name+'-'+item.raw.city"/></v-list>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="editedItem.applicable_maintenances"
                  :items="$store.state.preventiveMaintenancePeriods"
                  :item-title="capital"
                  item-value="id"
                  :label="$filters.capitalize(
                    $t('power_stations.applicable_maintenances') )
                  "
                  :chips="true"
                  deletable-chips
                  :multiple="true"
                  :clearable="true"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{$filters.capitalize(
                          $t("search_for", { noun: $t("users.label", 10) })
                            )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import filters from "@/mixins/filters";
import { capitalize } from "@/functions.js";

export default {
  mixins: [filters],

  components: {
    Crud: Crud,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  created() {
    this.$store.dispatch("stockages/searchItems", { value: "" });
  },

  methods: {
    capital(item) {
      return capitalize(item.name);
    },
  },

  watch: {
    autocompleteSearchStockage(val) {
      if (!this.$store.getters.canSearch("stockages")) return;
      this.$store.dispatch("stockages/searchItems", { value: val });
    },
  },

  data() {
    return {
      moduleName: "power_stations",
      headers: [
        { title: this.$t("power_stations.name"), key: "name" },
        { title: this.$t("power_stations.power"), key: "power" },
        {
          title: this.$t("power_stations.description"),
          key: "description",
        },
        { title: this.$t("stockages.label"), key: "stockage.name" },
        {
          title: this.$t("power_stations.applicable_maintenances"),
          key: "applicable_maintenances",
          sortable: false,
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      positiveIntRule: [
        (v) => Number.isInteger(Number(v)) || this.$t("rule.integer"),
        (v) =>
          (Number.isInteger(Number(v)) && v >= 0.0) ||
          this.$t("rule.positive"),
      ],
      autocompleteSearchStockage: null,
    };
  },
};
</script>
