<template>
  <v-container py-0 ma-0>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-autocomplete
          :disabled="producerId === null || editMode"
          v-model="editedItem"
          :items="available_units"
          :item-props = "unitProps"
          :label="$filters.capitalize($t('units.label') )"
          v-model:search="autocompleteSearchUnit"
          :loading="$store.state.units.isSearching"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          return-object
          no-filter
        >
          <template v-slot:no-data>
                {{$filters.capitalize(
                  $t("search_for", { noun: $t("units.label", 1) }) )
                }}
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="10" sm="10" md="4">
        <v-select
          :disabled="editMode"
          v-model="editedItem_kind"
          :items="affection_types"
          item-title="name"
          item-value="id"
          :label="$filters.capitalize($t('affectations.choose_type') )"
          variant="solo"
        >
        </v-select>
        <!-- TODO => radio button ? -->
      </v-col>

      <v-col
        v-if="!editMode"
        cols="1"
        sm="1"
        md="1"
        class="mt-4"
        align="center"
      >
        <v-tooltip location="top" :text="$filters.capitalize( $t('actions.delete') )">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" @click="deleteBtn()">mdi-delete</v-icon>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { isNone } from "@/functions";

export default {
  mixins: [filters, utils],
  props: {
    ordinal: { type: Number, required: true },
    producerId: {
      required: true,
      validator: (prop) => typeof prop === "number" || isNone(prop),
    },
    editMode: { type: Boolean, required: false, default: true },
  },
  computed: {
    edition: function () {
      return this.$store.state[`${this.moduleName}`].edition;
    },
    editedItem: {
      get: function () {
        return this.edition.removed_parts[this.ordinal]
      },
      set: function (value) {
        this.$store.dispatch("intervention_reports/updateRemovedParts", {
          index: this.ordinal,
          item: value ,
        });
        // NOTE: pk must be called id here because if we use pk, it will explode on your face
      },
    },
    editedItem_kind: {
      get: function () {
        return this.edition.removed_parts[this.ordinal].kind;
      },
      set: function (value) {
        this.$store.dispatch("intervention_reports/updateRemovedParts", {
          index: this.ordinal,
          item: { kind: value },
        });
      },
    },
    disabled: function () {
      return this.editedItem_id === undefined || this.editMode;
    },

    affection_types: function () {
      var liste = [
        { name: this.$t("stock_state.label", 1), id: "ss" },
        { name: this.$t("external_repairs.label", 1), id: "er" },
        { name: this.$t("internal_repairs.label", 1), id: "ir" },
        { name: this.$t("trash_state.label", 1), id: "ts" },
      ];
      return liste;
    },
  },
  mounted() {
    if (this.editedItem_id) {
      this.$store
        .dispatch("units/initSearch", { item_ids: [this.editedItem_id] })
        .then((item) => {
          this.available_units = item.results;
        });
    }

  },
  methods: {
    deleteBtn() {
      this.$emit("remove_affected", this.ordinal);
    },
    updateUnits(val) {
      const options = {
        value: val,
        item_id: this.producerId,
      };
      this.delayedSearch(val, "units/searchFromProducer", options, (values) => {
        this.updateAvailables(values)
      });
    },
    updateAvailables(units_list) {
      this.available_units = units_list
      let index = this.available_units.findIndex(x => x.pk === this.editedItem_id)
      if(index == -1 && this.editedItem_id){
        //to deal with cache issues and component not showing the info: add the unit to the item list of the component 
        this.$store.dispatch('units/getItem', this.editedItem_id).then((item) => {
          this.available_units.push(item)
        })
      }
    },
    unitProps (item) {
      if(typeof item !== 'object' || item == null) return
      item.value = item.pk
      item.title = ''
      if (item.stock_part == undefined) return item
      if (item.stock_part.ref.length > 0) item.title = item.stock_part.ref
      else if (this.$store.getters.language === 'en') item.title = item.stock_part.name_en 
      else item.title = item.stock_part.name
      item.title = item.title + ' - ' + item.serial

      return item
    },
  },
  created() {
    if (this.editedItem_id && this.editMode) {
      this.$store.dispatch("units/getItems", { ids: [this.editedItem_id] });
    }
  },
  watch: {
    producerId(val) {
      if (val) {
        this.$store
          .dispatch("units/searchFromProducer", { item_id: val })
          .then((items) => (this.available_units = items));
      }
    },
    autocompleteSearchUnit(val) {
      if (!this.$store.getters.canSearch("units")) return;
      if (val) {
        this.updateUnits(val);
      }
    },
    editedItem_id() {
      this.updateAvailables(this.available_units)
    }
  },

  data() {
    return {
      moduleName: "intervention_reports",
      autocompleteSearchUnit: undefined,
      available_units: [],
    };
  },
};
</script>
