<template>
  <v-container>
    <v-row>
      <v-col class="mt-4" cols="12">
        <h2 class="headline font-weight-bold mb-3">
          {{$filters.capitalize( $t("user.informations") ) }}
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <!--           <v-card-title>
            {{$filters.capitalize( $t('user.informations') ) }}
          </v-card-title> -->

          <v-card-text>
            <ul class='no_bullet'>
              <li>
                {{$filters.capitalize( $t("user.last_name") ) }} :
                {{ this.user.last_name }}
              </li>
              <li>
                {{$filters.capitalize( $t("user.first_name") ) }} :
                {{ this.user.first_name }}
              </li>
              <li>
                {{$filters.capitalize( $t("user.staff") ) }} :
                {{$filters.formatBool( this.user.is_staff ) }}
              </li>
              <li>
                {{$filters.capitalize( $t("user.profile") ) }} :
                {{$filters.formatVariety( this.user.variety ) }}
              </li>
            </ul>
            <br />
            <ul class='no_bullet'>
              <li>
                {{$filters.capitalize( $t("user.change_locale") ) }} :
                <span flex style="display: block; width: 15ex"
                  ><LocaleChanger
                /></span>
              </li>
              <li>
                <a href="https://raijin.innovent.fr/admin/password_reset/">{{$filters.capitalize(
                  $t("user.change_password") )
                }}</a>
              </li>
            <li>
				<Vipini/>
            </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LocaleChanger from "@/components/LocaleChanger";
import Vipini from "@/components/Vipini";

export default {
  components: {
    LocaleChanger: LocaleChanger,
    Vipini: Vipini,
  },

  computed: {
    user: function () {
      return this.$store.getters.user;
    },
  },
};
</script>

<style>
ul.no_bullet {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
</style>
