import crud from "./crud";
import crud_service from "@/services/crud";

const path = "preventive_maintenances";
const struct = {
  period: "",
  report: null,
  done_at: null,
  power_station_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  setEditPowerStation(state, id_) {
    state.edit.power_station_id = id_;
  },
};

const actions = {
  ...generic.actions,

  setEditPowerStation({ commit }, id_) {
    commit("setEditPowerStation", id_);
  },

  fromPowerStation({ commit }, power_station_id) {
    commit("setIsLoading", true);

    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/power_station/${power_station_id}`)
        .then((items) => {
          //commit('setItems', items)
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
