<template>
  <v-container class="mb-5">
    <v-toolbar flat color="white" elevation="1" class="my-3">
      <v-tooltip location="top" :text="$filters.capitalize( $t(`${moduleName}.edition.back`) ) ">
        <template v-slot:activator="{ on }">
          <v-icon v-bind="on" class="mr-2" @click="backBtn()"
            >mdi-arrow-left-drop-circle-outline</v-icon
          >
        </template>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-toolbar-title style="font-weight: bolder">{{$filters.capitalize(
        $t(pageTitle, 10, { target: this.powerStationName }))
      }} {{this.powerStationName}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-table class="my-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.period.label") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.done_at") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("preventive_maintenances.report") ) }}
                </th>
                <th class="text-left">
                  {{ $filters.capitalize($t("actions.label") ) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="maint in preventive_maintenances" :key="maint.pk">
                <td>{{$filters.capitalize($filters.formatPeriod( maint.period ) ) }}</td>
                <td>{{$filters.formatDate( maint.done_at ) }}</td>
                <td>
                  <v-tooltip location="top" :text= "$filters.capitalize($t('actions.download') )">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-show="maint.report !== null"
                        v-bind="props"
                        class="mr-auto"
                        @click="openLink(maint.report)"
                        >mdi-file-outline</v-icon
                      >
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete') )">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        class="mr-2"
                        @click="deleteItemBtn(maint)"
                        >mdi-delete</v-icon>
                    </template>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { alertPopup } from "@/functions";

export default {
  mounted() {
    this.power_station_id = parseInt(this.$route.query.power_station_id)  || undefined;
    this.powerStationName = this.$route.query.power_station_name;
    this.$store
      .dispatch(`${this.moduleName}/fromPowerStation`, this.power_station_id)
      .then((items) => {
        this.preventive_maintenances = items;
      });
  },

  methods: {
    backBtn() {
      this.$router.push({ name: "maintenance_view" });
    },

    deleteItem(id_) {
      return this.$store.dispatch(`${this.moduleName}/deleteItem`, id_);
    },

    deleteItemBtn(item) {
      var index = this.preventive_maintenances.findIndex(
        (i) => i.pk === item.pk,
      );
      alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
        if (result && this.preventive_maintenances.splice(index, 1)) {
          this.deleteItem(item.pk);
        }
      });
    },

    openLink(url) {
      window.open(url, "_blank");
    },
  },

  data() {
    return {
      pageTitle: "preventive_maintenances.edition.label",
      moduleName: "preventive_maintenances",
      preventive_maintenances: [],
      power_station_id: undefined,
      powerStationName: "",
    };
  },
};
</script>
