import crud from "./crud";
import crud_service from "@/services/crud";
import { isNone } from "@/functions";
import i18n from "@/i18n.js";
import store from "..";

const path = "purchase_lines";
const struct = {
  quantity: 0,
  price: 0.0,
  price_currency: "EUR",
  is_locally_bought: true,
  stock_part_id: null,
  stockage_id: null,
  purchase_id: null,
  min_quantity: 0,
  max_quantity: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  selected: [],
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,

  setSelected(state, { items }) {
    state.selected = items;
  },

  updateSelected(state, { index, item }) {
    const newValue = Object.assign(state.selected[index], item);
    state.selected.splice(index, 1, newValue);
  },

  emptySelected(state) {
    state.selected = Object.assign([]);
  },

  updateSelectedQuantity(state, { index, quantity }) {
    var newValue = Object.assign(state.selected[index], {});
    newValue.quantity = quantity;
    state.selected.splice(index, 1, newValue);
  },
};

const actions = {
  ...generic.actions,

  getCurrentNeeds({ commit }, { stockage_id, producer_type_id, options }) {
    // Get non-purchased lines
    if (isNone(options)) {
      options = {};
    }
    if (!isNone(stockage_id)) {
      options["stockage_id"] = stockage_id;
    }
    if (!isNone(producer_type_id)) {
      options["producer_type_id"] = producer_type_id;
    }
    commit("setIsLoading", true)
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/needed`, options).then((items) => {
        commit("setItems", items.results);
    commit("setIsLoading", false)
        resolve(items);
      });
    });
  },

  setSelected({ commit }, { items }) {
    commit("setSelected", { items });
  },

  updateSelected({ commit }, { index, item }) {
    if (
      item.id !== undefined &&
      state.selected.findIndex((x) => x.id === item.id) !== -1
    ) {
      const msg = i18n.global.t("purchase_lines.error_duplicated", { id: item.id });
      store.dispatch("snackbar/showError", msg);
      return;
    }
    commit("updateSelected", { index, item });
  },

  emptySelected({ commit }) {
    commit("emptySelected");
  },

  updateSelectedQuantity({ commit }, { index, quantity }) {
    if (index > state.selected.length) {
      const msg = i18n.global.t("purchase_lines.error___out_of_bound");
      store.dispatch("snackbar/showError", msg);
      return;
    }
    commit("updateSelectedQuantity", { index, quantity });
  },

  switchIsLocallyBought({ commit }, item) {
    // Switch is locally bought value
    return new Promise((resolve) => {
      crud_service
        .put_without_id(`${path}/${item.pk}/is_locally_bought`, {
          is_locally_bought: item.is_locally_bought,
        })
        .then(() => {
          commit("nothing");
          resolve();
        });
    });
  },

  saveFullPurchaseLine({ commit }, item) {
    return new Promise((resolve) => {
      crud_service.post(`${path}/full`, item).then((resp) => {
        commit("updateEdit", resp);
        commit("editItem", resp);
        resolve(resp);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
