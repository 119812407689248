<template>
  <v-toolbar class="elevation-1">
    <v-toolbar-title style="font-weight: bolder">
      {{ title }}
    </v-toolbar-title>
  </v-toolbar>
  <v-data-table-server
    class='mb-2'
    :headers="countHeaders"
    fixed-header
    :items="items"
    :items-length="pagination.size || 0"
    :items-per-page="pagination.page_size"
    :page="pagination.current"
    :loading="loading"
    :items-per-page-options="footerProps['items-per-page-options']"
    :items-per-page-text="footerProps['items-per-page-text']"
    :no-data-text="$filters.capitalize($t('no_data_text') )"
    @update:options="loadOptions"
  ></v-data-table-server>
</template>

<script>
import utils from "@/mixins/utils";
import filters from "@/mixins/filters";
import { capitalize, unProxy, isNone } from "@/functions";
import i18n from "@/i18n";

export default {
  props: ["search_params"],
  mixins: [utils, filters],
  methods: {
    loadCount() {
      const searchParams = {
        producer_id: this.search_params.producer_id,
        level: this.search_params.level,
        criticality: this.search_params.criticality,
        start: this.search_params.date_from,
        end: this.search_params.date_to
      };
      const payload = {
        ...this.paginationOptions, 
        ...searchParams
      };
      this.$store.dispatch(`alarm_count/getItems`, payload);
    },
    loadOptions({page, itemsPerPage, sortBy}){
        this.paginationOptions = this.genPaginationPayload(
            {"page": page, "size": itemsPerPage, "sortBy":sortBy})
        this.loadCount()
    },
  },
  watch: {
    search_params() {
     // reset page when doing new search
        this.paginationOptions.page = 1;
        this.pagination.current = 1;
      this.loadCount();
    }
  },
  data() {
    return {
      moduleName: "alarm_count",
      countHeaders: [
        {
          title: this.$filters.capitalize(this.$t("producers.label")),
          key: "producer_id__display_name",
          sortable: true
        },
        { title: this.$filters.capitalize(this.$t("alarm_list.alarm_count")), key: "alarm_count" ,
          sortable: true},
        { title: this.$filters.capitalize(this.$t("alarm_codes.name")), key: "alarm_code__name" ,
          sortable: true},
        {
          title: this.$filters.capitalize(this.$t("alarm_codes.label")),
          key: "alarm_code",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("alarm_codes.code")),
          key: "alarm_code__code",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("alarm_codes.level")),
          key: "alarm_code__level",
          sortable: true
        },
        {
          title: this.$filters.capitalize(this.$t("alarm_codes.criticality.label")),
          key: "alarm_code__criticality",
          sortable: true
        }
      ],
      title: capitalize(this.$t("alarm_list.label", 10)),

      paginationOptions: { "page": 1, "size": 10 },

      footerProps: {
        "items-per-page-options": [10, 20, 50],
        "items-per-page-text": this.$t("rows_per_page")
      }
    };
  }
};
</script>
