import moment from "moment";
import crud from "@/store/modules/crud";
import crud_service from "@/services/crud";
import i18n from "@/i18n.js";
import store from "@/store";

const path = "intervention_reports";
const struct = {
  date: moment().startOf("day").format("YYYY-MM-DD"),
  number: "",
  status: "",
  cause: "",
  action: "",
  todo: "",
  todo_is_risk: false,
  producer_id: null,
  alarm_code_id: null,
  partner_ids: [],
  affectation_ids: [],
  consumable_ids: [],
};
const edition_struct = {
  pk: struct.pk,
  date: struct.date,
  number: struct.number,
  status: struct.status,
  cause: struct.cause,
  action: struct.action,
  todo: struct.todo,
  producer_id: struct.producer_id,
  alarm_code_id: struct.alarm_code_id,
  partner_ids: struct.partner_ids,
  removed_parts: [],
  consumed_parts: [],
  added_parts: [],
};
const generic = crud(path, struct);

const state = {
  ...generic.state,

  edition: Object.assign({}, edition_struct),
};

const getters = {
  ...generic.getters,

  edition: (state) => state.edition,
};

const mutations = {
  ...generic.mutations,

  cleanup_edition(state) {
    state.edition = Object.assign({}, edition_struct);
    state.edition.removed_parts.splice(0, state.edition.removed_parts.length);
    state.edition.consumed_parts.splice(0, state.edition.consumed_parts.length);
    state.edition.added_parts.splice(0, state.edition.added_parts.length);
  },

  add_consumed(state, { id, quantity, place }) {
    state.edition.consumed_parts.push({
      id: id,
      quantity: quantity,
      place: place,
    });
  },

  remove_consumed(state, ordinal) {
    if (
      ordinal < 0 ||
      state.edition.consumed_parts === undefined ||
      ordinal > state.edition.consumed_parts.length - 1
    ) {
      return;
    }
    state.edition.consumed_parts.splice(ordinal, 1);
  },

  add_removed(state, { id, kind }) {
    state.edition.removed_parts.push({ id: id, kind: kind });
  },

  remove_removed(state, ordinal) {
    if (
      ordinal < 0 ||
      state.edition.removed_parts === undefined ||
      ordinal > state.edition.removed_parts.length - 1
    ) {
      return;
    }
    state.edition.removed_parts.splice(ordinal, 1);
  },

  add_added(state, { pk, category }){
    state.edition.added_parts.push({ pk: pk, category: category})
  },

  remove_added(state, ordinal) {
    if (
      ordinal < 0 ||
      state.edition.added_parts === undefined ||
      ordinal > state.edition.added_parts.length - 1
    ) {
      return;
    }
    state.edition.added_parts.splice(ordinal, 1);
  },

  initEdition(state) {
    state.edition = Object.assign({}, edition_struct);
  },

  edition(state, response) {
    state.edition.pk = response.pk;
    state.edition.date = response.date;
    state.edition.number = response.number;
    state.edition.status = response.status;
    state.edition.cause = response.cause;
    state.edition.action = response.action;
    state.edition.todo = response.todo;
    state.edition.producer_id = response.producer_id;
    state.edition.partner_ids = response.partner_ids;
    state.edition.alarm_code_id = response.alarm_code_id;
    state.edition.consumed_parts = response.consumables.map(function (i) {
      let quantity = i.quantity * - 1;
      return {
        name: i.stock_part.name,
        name_en: i.stock_part.name_en,
        pk: i.pk,
        quantity: quantity,
        place: i.place,
      };
    });
    var added = [];
    var removed = [];
    response.affectations.forEach(function (item) {
      if (item.producer !== null) {
        added.push({
          pk: item.unit_id,
          category: item.unit.stock_part.category,
        });
      } else if (
        item.external_repair !== null ||
        item.internal_repair !== null ||
        item.stock_state !== null ||
        item.trash_state !== null
      ) {
        removed.push({ id: item.unit_id, kind: item.kind });
      }
    });
    state.edition.added_parts = added;
    state.edition.removed_parts = removed;
  },

  updateRemovedParts(state, { index, item }) {
    const newValue = Object.assign(state.edition.removed_parts[index], item);
    state.edition.removed_parts.splice(index, 1, newValue);
  },

  updateConsumedParts(state, { index, item }) {
    const newValue = Object.assign(state.edition.consumed_parts[index], item);
    state.edition.consumed_parts.splice(index, 1, newValue);
  },

  updateAddedParts(state, { index, item }) {
    const newValue = Object.assign(state.edition.added_parts[index], item);
    state.edition.added_parts.splice(index, 1, newValue);
  },
};

const actions = {
  ...generic.actions,

  initEdition({ commit }) {
    commit("initEdition");
  },

  getEdition({ commit }, pk) {
    // Get a single item (for edition)
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/${pk}`).then((response) => {
        commit("edition", response);
        resolve(response);
      });
    });
  },

  delete({ commit }, pk) {
    // Cleanly delete an IR
    return new Promise((resolve) => {
      crud_service.delete(`${path}/edition`, pk).then(() => {
        commit("deleteItem", pk);
        resolve();
      });
    });
  },

  addEmptyConsumed({ commit }) {
    commit("add_consumed", { id: undefined, quantity: 1 });
  },

  removeConsumed({ commit }, ordinal) {
    commit("remove_consumed", ordinal);
  },

  addEmptyRemoved({ commit }) {
    commit("add_removed", { id: undefined, kind: undefined });
  },

  addRemoved({ commit }, item) {
    commit("add_removed", { id: item, kind: undefined });
  },

  removeRemoved({ commit }, ordinal) {
    commit("remove_removed", ordinal);
  },

  addEmptyAdded({ commit }) {
    commit('add_added', {pk: undefined, category: undefined })
  },

  addAdded({ commit }, item, category) {
    commit('add_added', {pk: item, category: category })
  },

  removeAdded({ commit }, ordinal) {
    commit("remove_added", ordinal);
  },

  cleanupEdition({ commit }) {
    commit("cleanup_edition");
  },

  sendEdition({ state }, pk) {
    // Create/update a full report
    const payload = Object.assign({}, state.edition);
    var rp_ = payload.removed_parts.map(function (i) {
      return { pk: i.pk, kind: i.kind };
    });
    payload.removed_parts = Object.assign([], rp_);
    payload.consumed_parts = payload.consumed_parts.map(function (i) {
      return { pk: i.pk, quantity: Math.abs(i.quantity), place: i.place };
    });
    payload.added_parts = payload.added_parts.map(function (i) {
      return { pk: i.pk, category: i.category }
    })

    var method_ = crud_service.post;
    var url = path + "/edition";
    if (pk) {
      method_ = crud_service.put;
    }
    return new Promise((resolve) => {
      method_(url, payload).then(() => {
        //commit('edition', response)
        resolve();
      });
    });
  },

  updateRemovedParts({ commit }, { index, item }) {
    if(!item) return
    if (
      item.id !== undefined &&
      state.edition.removed_parts.findIndex((x) => x.id === item.id) !== -1
    ) {
      const msg = i18n.global.t("intervention_reports.edition.error_duplicated", {
        id: item.id,
      });
      store.dispatch("snackbar/showError", msg);
      return;
    }
    commit("updateRemovedParts", { index, item });
  },

  updateConsumedParts({ commit }, { index, item }) {
    if(!item) return
    if (
      item.id !== undefined &&
      state.edition.consumed_parts.findIndex((x) => x.id === item.id) !== -1
    ) {
      const msg = i18n.global.t("intervention_reports.edition.error_duplicated", {
        id: item.id,
      });
      store.dispatch("snackbar/showError", msg);
      return;
    }
    commit("updateConsumedParts", { index, item });
  },

  updateAddedParts({ commit }, { index, item }) {
    if(!item) return
    if (
      item.id !== undefined &&
      state.edition.added_parts.findIndex((x) => x.id === item.id) !== -1
    ) {
      const msg = i18n.global.t("intervention_reports.edition.error_duplicated", {
        id: item.id,
      });
      store.dispatch("snackbar/showError", msg);
      return;
    }
    commit("updateAddedParts", { index, item });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
