import crud from "./crud";
import crud_service from "@/services/crud";

import { isNone } from "@/functions";

const path = "affectations";
const struct = {
  date: null,
  unit_id: null,
  external_repair_id: null,
  internal_repair_id: null,
  stock_state_id: null,
  trash_state_id: null,
  producer_id: null,
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  kindId: null,
};

const getters = {
  ...generic.getters,

  kindId: function (state) {
    return state.kindId;
  },
};

const mutations = {
  ...generic.mutations,

  kindId(state, _id) {
    state.kindId = _id;
  },

  editExternalRepair(state, item) {
    var index = state.items.findIndex(
      (obj) => obj.external_repair_id === item.pk,
    );
    if (index !== -1) {
      state.items[index].external_repair = item;
    }
  },

  editInternalRepair(state, item) {
    var index = state.items.findIndex(
      (obj) => obj.internal_repair_id === item.pk,
    );
    if (index !== -1) {
      state.items[index].internal_repair = item;
    }
  },
};

const actions = {
  ...generic.actions,

  kindId({ commit }, _id) {
    // temporary id var dispatched to external_repair_id, internal, producer_id
    commit("kindId", _id);
  },

  affect({ commit }, item) {
    if (isNone(item.pk)) {
      return new Promise((resolve) => {
        crud_service.post(`${path}`, item).then((item) => {
          commit("setItems", [item]);
          resolve(item);
        });
      });
    }
    return new Promise((resolve) => {
      crud_service.put(`${path}`, item).then((item) => {
        commit("setItems", [item]);
        resolve(item);
      });
    });
  },

  getLatest({ commit }) {
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/latest`).then((items) => {
        commit("setItems", items.results);
        resolve();
      });
    });
  },

  getOthers({ commit }, { stock_part_id, options }) {
    if (isNone(options)) {
      options = {};
    }
    if (!isNone(stock_part_id)) {
      options["stock_part_id"] = stock_part_id;
    }
    commit("setIsLoading", true);

    return new Promise((resolve, reject) => {
      crud_service
        .fetch(`${path}/latest`, options)
        .then((items) => {
          //commit("setItems", items.results);
          commit("setIsLoading", false);
          resolve(items);
        })
        .catch(() => {
          commit("setIsLoading", false);
          reject();
        });
    });
  },

  getInProduction({ commit }, payload) {
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/in_production`, payload).then((items) => {
        commit("setItems", items.results);
        resolve(items);
      });
    });
  },

  getUndoneExternalRepairs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      crud_service
        .fetch(`${path}/external_repairs/undone_external`, payload)
        .then((response) => {
          const items = Array.isArray(response) ? response : [];
          commit("setItems", items);
          resolve(items);
        })
        .catch((error) => {
          console.error("Error fetching undone externals repairs:", error);
          commit("setItems", []);
          reject(error);
        });
    });
  },

  editExternalRepair({ commit }, external_repair) {
    return new Promise((resolve) => {
      crud_service.put(`external_repairs`, external_repair).then(() => {
        commit("editExternalRepair", external_repair);
        resolve();
      });
    });
  },

  getUndoneInternalRepairs({ commit }, payload) {
    return new Promise((resolve, reject) => {
      crud_service
        .fetch(`${path}/internal_repairs/undone_internal`, payload)
        .then((response) => {
          const items = Array.isArray(response) ? response : [];
          commit("setItems", items);
          resolve(items);
        })
        .catch((error) => {
          console.error("Error fetching undone internal repairs:", error);
          commit("setItems", []);
          reject(error);
        });
    });
  },
  

  editInternalRepair({ commit }, internal_repair) {
    return new Promise((resolve) => {
      crud_service.put(`internal_repairs`, internal_repair).then(() => {
        commit("editInternalRepair", internal_repair);
        resolve();
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
