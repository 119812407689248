<template>
  <v-row class="text-center">
    <v-col class="mb-5" cols="12">
      <v-toolbar text color="white" elevation="1" density='compact'>
        <v-spacer></v-spacer>
        <v-toolbar-title style="font-weight: bold">
          {{ $filters.capitalize(title) }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table :headers="headers" :items="$store.state.affectations.items">
        <template v-slot:item.date="{ item }">
          {{$filters.formatDate( item.date ) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip location="top" :text="$filters.capitalize($t('actions.edit'))">
            <template v-slot:activator="{ props }">
              <v-icon small v-bind="props" class="mr-2" @click="toUnitView(item)"
                >mdi-eye-outline</v-icon
              >
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    producer_id: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
  },
  methods: {
    toUnitView(item) {
      let query = {
        part: String(item.unit_id).toString(),
      };
      this.$router.push({ name: "unit", query: query });
    },
  },
  created() {
    this.$store.dispatch("affectations/getInProduction", {
      producer_id: this.producer_id,
    });
  },
  data() {
    return {
      title:this.$t("units.affected_units"),
      headers: [
        {
          title:this.$t("generic.date", 10),
          key: "date",
        },
        {
          title:this.$t("stock_parts.name"),
          key: "unit.stock_part.name",
        },
        {
          title:this.$t("stock_parts.name_en"),
          key: "unit.stock_part.name_en",
        },
        {
          title:this.$t("stock_parts.ref"),
          key: "unit.stock_part.name_ref",
        },
        {
          title:this.$t("units.serial", 1),
          key: "unit.serial",
        },
        {
          title:this.$t("actions.label"),
          key: "actions",
        },
      ],
    };
  },
};
</script>
