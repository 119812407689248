<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
          v-bind:headers="headers"
          v-bind:moduleName="moduleName"
          @addItem="addItemEvent"
          @editItem="editItemEvent"
        >
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.name"
                  :label="$filters.capitalize($t('suppliers.name') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.contact"
                  :label="$filters.capitalize($t('suppliers.contact') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-textarea
                  v-model="editedItem.address"
                  :label="$filters.capitalize($t('suppliers.address') )"
                  :row-height="18"
                  auto-grow
                  variant="solo"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <vue-tel-input
                  v-model="phone1"
                  defaultCountry="fr"
                  mode='international'                  
                  :clearable="true"
                  name="phone"
                  :label="$filters.capitalize($t('suppliers.phone') )"
                  :placeholder="$filters.capitalize($t('placeholder.phone') )"
                  @validate="validatePhone1"
                ></vue-tel-input>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <vue-tel-input
                  v-model="phone2"
                  mode='international'                  
                  defaultCountry="fr"
                  :clearable="true"
                  name="phone"
                  :label="$filters.capitalize($t('suppliers.phone') )"
                  :placeholder="$filters.capitalize($t('placeholder.phone') )"
                  @validate="validatePhone2"
                ></vue-tel-input>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <vue-tel-input
                  v-model="phone3"
                  mode='international'                  
                  defaultCountry="fr"
                  :clearable="true"
                  name="phone"
                  :label="$filters.capitalize($t('suppliers.phone') )"
                  :placeholder="$filters.capitalize($t('placeholder.phone') )"
                  @validate="validatePhone3"
                ></vue-tel-input>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import filters from "@/mixins/filters";
import { isNone } from "@/functions.js";

export default {
  mixins: [filters],

  components: {
    Crud: Crud,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  methods: {
    validatePhone1(data) {
      return this.validatePhone(data, 1);
    },
    validatePhone2(data) {
      return this.validatePhone(data, 2);
    },
    validatePhone3(data) {
      return this.validatePhone(data, 3);
    },

    validatePhone(data, ordinal) {
      if (ordinal < 1 || ordinal > 3) {
        console.error(`wrong ordinal ${ordinal}`);
        return;
      }
      if (!data.valid) {
        this.editedItem[`phone${ordinal}`] = "";
      } else {
        this.editedItem[`phone${ordinal}`] = data.number;
      }
    },

    addItemEvent() {
      this.phone1 = undefined;
      this.phone2 = undefined;
      this.phone3 = undefined;
    },
    editItemEvent() {
      this.phone1 = this.editedItem.phone1;
      this.phone2 = this.editedItem.phone2;
      this.phone3 = this.editedItem.phone3;
    },
  },

  data() {
    return {
      moduleName: "suppliers",
      headers: [
        { title: this.$t("suppliers.name"), key: "name" },
        { title: this.$t("suppliers.contact"), key: "contact" },
        { title: this.$t("suppliers.address"), key: "address" },
        { title: this.$t("suppliers.phone"), key: "phone1" },
        { title: this.$t("suppliers.phone"), key: "phone2" },
        { title: this.$t("suppliers.phone"), key: "phone3" },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      phone1: undefined,
      phone2: undefined,
      phone3: undefined,
    };
  },
};
</script>

<style scoped>
:deep(div.v-data-table tr td:nth-child(3)) {
  white-space: pre-wrap;
}
</style>
