import crud from "./crud";
import crud_service from "@/services/crud";

const path = "stockages";
const struct = {
  name: "",
  region: "",
  city: "",
  country: "",
  user_ids: [],
};
const generic = crud(path, struct);

const state = {
  ...generic.state,
  regions: [],
  x_prod_type: [],
  all: [],
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
  setXProdType(state, x_prod_type) {
    state.x_prod_type = x_prod_type;
  },

  setRegions(state, regions) {
    state.regions = regions;
  },
  setAll(state,items) {
    state.all = items;
  },
};

const actions = {
  ...generic.actions,
  getAll({commit}) {                                                                                                  
    commit('setIsLoading', true)
    return new Promise((resolve, reject) => {crud_service.fetch(path).then((items)=>{
      commit("setAll",items.results);
      resolve(items.results);
      commit('setIsLoading', false);
    }).catch((e)=>{
      reject();
    })})
  },
  getRegions({ commit }) {
    // read all available regions
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/regions`).then((items) => {
        commit("setRegions", items);
        resolve(items);
      });
    });
  },

  getRegionsStockageIds({ commit }) {
    // read all available regions with stockage_ids
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/regions_stockage_ids`).then((items) => {
        commit("setRegions", items);
        resolve(items);
      });
    });
  },

  getMine({ commit }) {
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/mine`).then((items) => {
        commit("setItems", items);
        resolve(items);
      });
    });
  },

  getXProdType({ commit }) {
    // get stockage name product producer type
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/x_producer_type`).then((items) => {
        commit("setXProdType", items);
        resolve(items);
      });
    });
  },

  // keeping next method until we update PartEdition's handling of x_producer_type
  getXProducerType() {
    // get stockage name product producer type
    return new Promise((resolve) => {
      crud_service.fetch(`${path}/x_producer_type`).then((items) => {
        resolve(items);
      });
    });
  },

  getItemsFromProducer({ commit }, producer_id) {
    // list stockages from a specific producer
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}`, { producer_id: producer_id })
        .then((items) => {
          commit("setItems", items.results);
          resolve();
        })
        .catch(() => {});
    });
  },

  getDefaultPlace({ commit }, stockage_id) {
    // get default place for this stockage
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/${stockage_id}/default_place`)
        .then((item) => {
          commit("nothing");
          resolve(item);
        });
    });
  },

  getByProducerTypes({ commit }, producer_types) {
    // get list of stockages linked to a specific producer_type
    return new Promise((resolve) => {
      crud_service
        .fetch(`${path}/by_producer_types`, producer_types)
        .then((items) => {
          commit("setItems", items);
          resolve();
        })
        .catch(() => {});
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
