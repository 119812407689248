<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
          v-bind:headers="headers"
          v-bind:moduleName="moduleName"
          v-bind:saveOverride="saveOverride"
          v-bind:actionAddPart="false"
          v-bind:actionPartToTrash="true"
          v-bind:actionDelete="false"
          @addItem="addItemEvent"
          @editItem="editItemEvent"
        >
          <template v-slot:edit_content>
            <UnitForm 
                :askPlace='true'
                :key="editedItem.stock_part_id"
            ></UnitForm>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import UnitForm from "@/components/UnitForm";
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { isNone } from "@/functions.js";

export default {
  mixins: [filters, utils],

  components: {
    Crud: Crud,
    UnitForm: UnitForm,
  },

  methods: {
    saveOverride() {
      if (isNone(this.editedItem.serial)) {
        this.$store.dispatch(
          "snackbar/showWarning",
          this.$t("parts.missing.serial"),
        );
        return new Promise(() => {});
      }
      return new Promise((resolve) => {
        this.$store.dispatch(`units/addItem`, this.editedItem).then(() => {
          // if (this.module.new_item.pk !== undefined) {
          //   this.$store
          //     .dispatch("affectations/affect", {unit_id:this.module.new_item.pk, kind:"ss"})
          //     .then(() => {
          //       resolve();
          //     });
          // }
          resolve();
        });
      });
    },
    addItemEvent() {
      this.$store.dispatch("stock_parts/searchItems", { value: "", is_unit: true });
      //this.$store.dispatch("places/searchItems", { value: "" });
    },
    editItemEvent() {
      this.$store.dispatch("units/getItem", this.editedItem.pk);
      this.$store
        .dispatch("stock_parts/getItem", this.editedItem.stock_part_id)
        .then((item) => {
          this.$store.dispatch("stock_parts/setSearch", item);
        });
      //this.$store.dispatch("places/initSearch", {
      //  item_ids: this.editedItem.place_id,
      //});
      this.$store.dispatch(
        "part_categories/setSearch",
        this.editedItem.stock_part.category,
      );
    },
  },

  data() {
    return {
      moduleName: "units",
      headers: [
        { title: this.$t("stock_parts.label"), key: "stock_part.ref" },
        {
          title: this.$t("stock_parts.name"),
          key: "stock_part.name",
          sortable: false,
        },
        {
          title: this.$t("stock_parts.name_en"),
          key: "stock_part.name_en",
          sortable: false,
        },
        { title: this.$t("units.serial", 1), key: "serial" },
//        {
//          title: this.$t("stockages.label"),
//          key: "place.stockage.name",
//          sortable: false,
//        },
//        { title: this.$t("places.cabinet"), key: "place.cabinet" },
//        { title: this.$t("places.shelf"), key: "place.shelf" },
        {
          title: this.$t("units.code"),
          key: "qr_code",
        },
        {
          title: this.$t("purchase_view.label"),
          key: "purchase_id",
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
