<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col v-if="askStockage" cols="12" sm="6" md="6">
        <v-autocomplete
          v-model="editedItem.stockage"
          :items="$store.state.stockages.search"
          :item-props="stockageProps"
          :label="$filters.capitalize($t('stockages.label'))"
          v-model:search="autocompleteSearchStockage"
          :loading="$store.state.stockages.isSearching"
          :filter="filterStockages"
          prepend-icon="mdi-truck-delivery-outline"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          cache-items
          density="compact"
          hide-details
          return-object
        >
          <template #no-data>
                {{$filters.capitalize($t("search_for", { noun: $t("stockages.label", 1) }))}}
          </template>
        </v-autocomplete>
      </v-col>

    <v-col cols="12" sm="6" md="6">
      <v-autocomplete
        v-model="editedItem.stock_part"
        :items="$store.state.stock_parts.search"
        :label="$filters.capitalize($t('stock_parts.label'))"
        :item-props="stockPartProps"
        v-model:search="autocompleteSearchStockPart"
        :loading="$store.state.stock_parts.isSearching"
        append-icon="mdi-text-box-search"
        return-object
        :clearable="true"
        variant="solo"
        no-filter
      >
        <template #no-data>
              {{
                $filters.capitalize(
                  $t("search_for", { noun: $t("stock_parts.label", 1) })
                )
              }}
        </template>
      </v-autocomplete>
    </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-text-field
          v-model.number="editedItem.quantity"
          type="number"
          min="0"
          oninput="if(this.value < 0) this.value = 0;"
          :label="$filters.capitalize($t('purchase_lines.quantity'))"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="2" v-if="!adminView">
        <v-text-field
          v-model.number="editedItem.min_quantity"
          type="number"
          min="0"
          oninput="if(this.value < 0) this.value = 0;"
          :label="$filters.capitalize($t('stock_parts.min_quantity'))"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="2" v-if="!adminView">
        <v-text-field
          v-model.number="editedItem.max_quantity"
          type="number"
          oninput="if(this.value < 0) this.value = 0;"
          :label="$filters.capitalize($t('stock_parts.max_quantity'))"
        ></v-text-field>
      </v-col>
      <v-col cols="8" sm="4" md="2">
      <v-text-field
        v-model.number="editedItem.price"
        type="number"
        :clearable="true"
        oninput="if(this.value < 0) this.value = 0;"
        :label="$filters.capitalize($t('purchase_lines.price'))"
      ></v-text-field>
      </v-col>
      <v-col cols="4" sm="4" md="2">
        <SelectCurrency
          v-bind:currency="editedItem.price_currency"
          @update:modelValue="chooseCurrency"
        ></SelectCurrency>
      </v-col>

      <v-col v-if="askPurchase" cols="12" sm="6" md="6">
        <v-autocomplete
          v-model="editedItem.purchase"
          :items="$store.state.purchases.search"
          :item-props="purchaseProps"
          :label="$filters.capitalize($t('purchases.label'))"
          v-model:search="autocompleteSearchPurchase"
          :loading="$store.state.purchases.isSearching"
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
          no-filter
        >
          <template #no-data>
            <v-list-item :title="$filters.capitalize(
                    $t('search_for', { noun: $t('purchases.label', 1) }))"/>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SelectCurrency from "@/components/SelectCurrency";
import { isNone } from "@/functions.js";
import utils from "@/mixins/utils";
import filters from "@/mixins/filters";

export default {
  mixins: [filters, utils],

  components: {
    SelectCurrency: SelectCurrency,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
    askStockage: function () {
      return isNone(this.stockageId);
    },
  },

  props: {
    stockageId: { type: Number, required: false, default: NaN },
    askPurchase: { type: Boolean, required: false, default: true },
    adminView: { type: Boolean, required: false, default: false },
  },

  methods: {
    updatePurchase(val) {
      var payload = { value: val, size: 50 };
      this.$store.dispatch("purchases/searchItems", payload);
    },

    updateStockPart(val) {
      if (isNone(val)) {
        return;
      }
      
      var payload = { value: val, size: 50 };
      if (!isNone(this.stockageId)) {
        payload["stockage_id"] = this.stockageId;
      }
      this.delayedSearch(val, "stock_parts/searchItems", payload);
    },

    chooseCurrency(value) {
      this.editedItem.price_currency = value;
    },
    changeStockPart() {
      if (!isNone(this.editedItem.stock_part_id)) {
        this.$store
          .dispatch("stock_part_limits/getItems", {
            stock_part_id: this.editedItem.stock_part_id,
            stockage_id: this.editedItem.stockage_id,
          })
          .then((items) => {
            if (items.length > 0){
            this.editedItem.min_quantity = items[0].min_quantity;
            this.editedItem.max_quantity = items[0].max_quantity;
          }});
      }
    },
    stockPartProps(item) {
      if (typeof item !== 'object' || item === null) return;
      const titleParts = [];
      if (item.name) {
        titleParts.push(item.name);
      }
      if (item.name_en) {
        titleParts.push(item.name_en);
      }
      if (item.ref) {
        titleParts.push(item.ref);
      }
      const newItemTitle = titleParts.join(' -- ');
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
      return newItem;
    },
    stockageProps(item) {
      item.title = item.name + ' -- ' + item.city
      item.value = item.pk
      return item
    },
    purchaseProps(item) {
      item.title = item.ordered_at+' '+item.comment
      item.value = item.pk
      return item
    },
  },

  mounted() {
    if (!isNone(this.editedItem.purchase_id)) {
      this.$store.dispatch("purchases/initSearch", {
        item_ids: this.editedItem.purchase_id,
      });
    }
    if (!isNone(this.editedItem.stockage_id)) {
      this.$store.dispatch("stockages/initSearch", {
        item_ids: this.editedItem.stockage_id,
      });
    }
    if (!isNone(this.editedItem.stock_part_id)) {
      this.$store.dispatch("stock_parts/initSearch", {
        item_ids: this.editedItem.stock_part_id,
      });
    }
  },

  watch: {
    autocompleteSearchPurchase(val) {
      if (!this.$store.getters.canSearch("purchases")) return;
      this.updatePurchase(val);
    },
    autocompleteSearchStockPart(val) {
      if (!this.$store.getters.canSearch("stock_parts")) return;
      if (val != this.editedItem.value){  
        this.updateStockPart(val);
    }},
    autocompleteSearchStockage(val) {
      if (!this.$store.getters.canSearch("stockages")) return;
      if (!isNone(this.editedItem.stockage_id)) return;
      this.$store.dispatch("stockages/searchItems", { value: val })
    },
  },

  data() {
    return {
      moduleName: "purchase_lines",
      autocompleteSearchPurchase: null,
      autocompleteSearchStockage: null,
      autocompleteSearchStockPart: null,
    };
  },
};
</script>
