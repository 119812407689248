import crud from "./crud";

const path = "stock_part_limits";
const struct = {
  stock_part_id: undefined,
  stockage_id: undefined,
  min_quantity: 0,
  max_quantity: undefined,
};

const generic = crud(path, struct);

const state = {
  ...generic.state,
};

const getters = {
  ...generic.getters,
};

const mutations = {
  ...generic.mutations,
};

const actions = {
  ...generic.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
