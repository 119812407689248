<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName">
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="editedItem.producer_type_id"
                  :items="$store.state.producer_types.items"
                  item-title="name"
                  item-value="pk"
                  :label="$filters.capitalize($t('producer_types.label', 1) )"
                  variant="solo"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.level"
                  :rules="levelRules"
                  :label="$filters.capitalize($t('alarm_codes.level') )"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.name"
                  :label="$filters.capitalize($t('alarm_codes.name') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.code"
                  :rules="codeRules"
                  :label="$filters.capitalize($t('alarm_codes.code') )"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="editedItem.criticality"
                  :items="$store.state.criticalityItems"
                  :item-title="capital"
                  item-value="id"
                  :label="$filters.capitalize($t('alarm_codes.criticality.label') )"
                  :clearable="true"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{$filters.capitalize(
                          $t("search_for", {
                            noun: $t("alarm_codes.criticality.label", 1),
                          }) )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.description"
                  :label="$filters.capitalize($t('alarm_codes.description') )"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import filters from "@/mixins/filters";
import { capitalize } from "@/functions.js";

export default {
  mixins: [filters],

  components: {
    Crud: Crud,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  created() {
    this.$store.dispatch("producer_types/getItems");
  },

  methods: {
    capital(item) {
      return capitalize(item.name);
    },
  },

  data() {
    return {
      moduleName: "alarm_codes",
      headers: [
        { title: this.$t("alarm_codes.level"), key: "level" },
        { title: this.$t("alarm_codes.name"), key: "name" },
        { title: this.$t("alarm_codes.code"), key: "code" },
        {
          title: this.$t("alarm_codes.criticality.label"),
          key: "criticality",
        },
        {
          title: this.$t("producer_types.label"),
          key: "producer_type.name",
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      levelRules: [
        () =>
          (!!this.editedItem.level && this.editedItem.level.length <= 20) ||
          capitalize(this.$t("error.too_long")),
      ],
      codeRules: [
        () =>
          (!!this.editedItem.code && this.editedItem.code.length <= 40) ||
          capitalize(this.$t("error.too_long")),
      ],
    };
  },
};
</script>
