<template>
  <v-container>
    <div style="display: none">
      <EditDialog
        v-bind:model="editTask"
        v-bind:moduleName="'todos'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('new_item')"
        @save="saveTaskEvent"
        @close="cleanupTaskDialog"
        ref="editDialogTask"
      >
        <template v-slot:edit_content>
          <TodoForm
            v-bind:askFullfilledAt="false"
            v-bind:askInterventionReport="false"
            v-bind:askProducer="this.producerId ? false : true"
            v-bind:askRisk="true"
          ></TodoForm>
        </template>
      </EditDialog>
      <EditDialog
        v-bind:model="exportDatePick"
        v-bind:moduleName="''"
        v-bind:btnName="$t('export')"
        v-bind:formTitle="$t('analytics.period.choose')"
        @save="exportCSV"
        @close="cleanupExport"
        ref="editExportDates"
      >
        <template v-slot:edit_content>
          <v-row>
            <v-col>
              <DatePicker
                label="limitations.start"
                v-bind:value="report_start"
                @input="report_start = $event"
              ></DatePicker>
            </v-col>
            <v-col>
              <DatePicker
              label="limitations.end"
              v-bind:value="report_end"
              @input="report_end = $event">
              </DatePicker>
            </v-col>
          </v-row>
          <v-row>{{
              $filters.capitalize($t("intervention_reports.date_selection"))
            }}
          </v-row>
        </template>
      </EditDialog>

    </div>

    <v-row no-gutters>
      <v-col
        cols="3"
        class="pa-2 ma-2"
      >
        <h3 class="my-3" v-show="show_todo">
          {{ $filters.capitalize($t(`${moduleName}.label_todo`, tasks.length)) }}
        </h3>
        <v-btn color="primary-darken-1" @click="openEditTask()">
            <p class= 'text-wrap'>{{$t(`todos.actions.add_item`)}}</p>
        </v-btn>
      </v-col>
      <v-col
        class="pa-2 ma-2"
        >
        <v-list v-show="show_todo" class="pa-0">
            <div v-for="(item, index) in tasks" :key="item.pk">
              <v-list-item @click="todoClick(item)">
                <template #default=" active ">
                  <v-row>
                    <v-col>
                      <v-list-item-title v-text="item.assigned_to.name">
                      </v-list-item-title>
                      <v-list-item>
                        {{ item.description }}
                      </v-list-item>
                    </v-col>
                    <v-col cols="3"
                      class="text align-self-start 'right-align'"
                    >
                      <v-list-item-title>{{
                          $filters.capitalize($t(`todos.created_at`))
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                          $filters.formatDate(item.created_at)
                        }}
                      </v-list-item-subtitle>
                    </v-col>
                    <v-col cols="1">
                      <v-list-item-action>
                        <v-icon color="grey lighten-1">
                          mdi-clipboard-check
                        </v-icon>
                      </v-list-item-action>
                    </v-col>
                  </v-row>
                </template>
              </v-list-item>
              <v-divider v-if="index < items.length"></v-divider>
            </div>
        </v-list>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <v-toolbar color="white" elevation="1">
          <v-spacer></v-spacer>

          <v-toolbar-title style="font-weight: bolder">{{
              title
            }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            color="primary-darken-1"
            class="mb-2"
            @click="editBtn(undefined)"
          >
            {{ $filters.capitalize($t(`${moduleName}.actions.add_item`)) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" class="mb-2" @click="exportDialog()"
          >
            <v-icon small>mdi-file-export-outline</v-icon>
            <v-progress-circular
              class="ml-3"
              v-show="loadingExport"
              :indeterminate="true"
            ></v-progress-circular>
          </v-btn>

        </v-toolbar>

        <v-toolbar text color="white" elevation="1" class="px-2">
          <v-autocomplete
            v-model="userId"
            :items="$store.state.users.search"
            item-title="name"
            item-value="id"
            :label="$filters.capitalize($t('users.label', 1) )"
            v-model:search="autocompleteSearchUser"
            :loading="$store.state.users.isSearching"
            append-icon="mdi-text-box-search"
            :clearable="true"
            variant="solo"
            cache-items
            class="mr-3"
            density= 'compact'
            hide-details
            @update:modelValue="loadPage"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{
                    $filters.capitalize($t("search_for", { noun: $t("users.label", 10) })
                    )
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-model="alarmCodeId"
            :items="$store.state.alarm_codes.search"
            :item-props="alarmProps"
            :label="$filters.capitalize($t('alarm_codes.label', 1) )"
            v-model:search="autocompleteSearchAlarmCode"
            :loading="$store.state.alarm_codes.isSearching"
            append-icon="mdi-text-box-search"
            :clearable="true"
            variant="solo"
            class="mr-3"
            density= 'compact'
            hide-details
            no-filter
            @update:modelValue="loadPage"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{
                    $filters.capitalize($t("search_for", { noun: $t("alarm_codes.label", 1) })
                    )
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="data">
              <v-list-item @click="data.props.onClick">
                <span
                  v-if="
                    !['', null, undefined].includes(data.item.raw.code) &&
                    !['', null, undefined].includes(data.item.raw.level)
                  "
                >
                  {{ data.item.raw.level }} / n°{{ data.item.raw.code }} &ndash;
                  {{ data.item.raw.name }}
                </span>
                <span v-else-if="!['', null, undefined].includes(data.item.raw.code)">
                  n°{{ data.item.raw.code }} &ndash; {{ data.item.raw.name }}
                </span>
                <span
                  v-else-if="!['', null, undefined].includes(data.item.raw.level)"
                >
                  {{ data.item.raw.level }} / {{ data.item.raw.name }} ({{
                    data.item.raw.producer_type.name
                  }})
                </span>
                <span v-else>
                  {{ data.item.raw.name }} ({{ data.item.raw.producer_type.name }})
                </span>
              </v-list-item>
            </template>
          </v-autocomplete>


          <v-text-field
            v-model="searchValue"
            append-icon="mdi-folder-search"
            :label="$filters.capitalize($t('search') )"
            single-line
            hide-details
            @input="loadPage"
          ></v-text-field>
        </v-toolbar>
          <v-data-table-server
            :headers="headers"
            :items="items"
            item-key="pk"
            :items-length="pagination.size || 0"
            :items-per-page="pagination.page_size"
            :page="pagination.current"
            :loading="$store.state.intervention_reports.isLoading"
            @update:options="loadOptions"
            :no-data-text="$filters.capitalize($t('no_data_text'))"
            :no-results-text="$filters.capitalize($t('no_results_text'))"
            elevation="2"
          >
            <template v-slot:item.date="{ item }">
              {{ $filters.formatDate(item.date) }}
            </template>

            <template v-slot:item.status="{ item }">
              {{ $filters.capitalize($filters.reportStatusFilter(item.status)) }}
            </template>

            <template v-slot:item.affectations="{ item }">
              <ul v-if="item.affectations !== undefined" class="no_style">
                <li v-for="aff in item.affectations" v-bind:key="aff.pk">
                  <v-icon small v-if="aff.kind === 'p'"
                  >mdi-plus-circle-outline
                  </v-icon
                  >
                  <v-icon small v-if="aff.kind !== 'p'"
                  >mdi-minus-circle-outline
                  </v-icon
                  >
                  {{ $filters.capitalize(aff.unit.stock_part.name) }} :
                  {{ aff.unit.serial }}
                </li>
              </ul>
              <ul v-if="item.consumables !== undefined" class="no_style">
                <li v-for="cons in item.consumables" v-bind:key="cons.pk">
                  <v-icon small>mdi-play-circle-outline</v-icon>
                  {{ cons.quantity }} × {{ $filters.capitalize(cons.stock_part.name) }}
                </li>
              </ul>
            </template>

            <template v-slot:item.partners="{ item }">
              <ul v-if="item.partners !== undefined">
                <li v-for="partner in item.partners" v-bind:key="partner.pk">
                  {{ $filters.capitalize(partner.name) }}
                </li>
              </ul>
            </template>


            <template v-slot:item.actions="{ item }">
  <v-tooltip location="top" :text="$filters.capitalize($t('actions.edit'))">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-show="canEdit(item)"
                    small
                    v-bind="props"
                    class="mr-2"
                    @click="editBtn(item.pk)"
                  >mdi-pencil
                  </v-icon
                  >
                </template>
              </v-tooltip>
              <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete'))">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-show="isFromStaff"
                    small
                    v-bind="props"
                    @click="deleteBtn(item.pk)">mdi-delete
                  </v-icon>
                </template>
              </v-tooltip>
            </template>
          </v-data-table-server>
      </v-col>
    </v-row>
    <RiskList v-bind:askProducer="this.producerId ? false : true"></RiskList>
    <AffectationList
      v-if="this.producerId"
      v-bind:producer_id="this.producerId"
    ></AffectationList>
  </v-container>
</template>

<script>
import moment from "moment";
import i18n from "@/i18n";
import { api } from "@/services/api";

import {
  capitalize,
  extractFilename,
  isNone,
  slicyRemove,
  slicyUpdate,
  alertPopup,
  unProxy
} from "@/functions";
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import EditDialog from "@/components/EditDialog";
import TodoForm from "@/components/TodoForm";
import RiskList from "@/components/RiskList";
import AffectationList from "@/components/AffectationList";
import DatePicker from "@/components/DatePicker.vue";

export default {
  mixins: [utils, filters],

  components: {
    EditDialog,
    TodoForm,
    RiskList,
    AffectationList,
    DatePicker
  },

  computed: {
    editedItem: function() {
      return this.$store.getters.dataEdit(this.moduleName);
    },
    task_edit: function() {
      return this.$store.state["todos"].edit;
    },
    show_todo: function() {
      return this.tasks.length > 0;
    },
    ...utils.computed
  },

  methods: {
    editBtn(pk) {
      var params = { pk: pk };
      var query = {};
      if (!isNone(this.producerId)) {
        query["producer_id"] = this.producerId;
      }
      if (!isNone(this.userId)) {
        query["user_id"] = this.userId;
      }
      this.$store.dispatch('intervention_reports/initEdition').then(()=>{
        this.$router.push({
          name: "intervention_reports_edition",
          params: params,
          query: query
        });
      })
    },
    deleteBtn(pk) {
      alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
        if (result) {
          this.$store.dispatch(`${this.moduleName}/delete`, pk);
        }
      });
    },
    loadPage() {
      if (!this.$store.getters.canSearch(this.moduleName)) return
      if (this.$store.state.intervention_reports.isLoading) return
      var options2 = {
        producer_id: this.producerId,
        user_id: this.userId,
        alarm_code_id: this.alarmCodeId,
        search: this.searchValue
      };
      const payload = Object.assign({}, this.paginationOptions, options2);
      this.delayedSearch(this.searchValue, `${this.moduleName}/getItems`, payload);
    },
    loadOptions({page, itemsPerPage, sortBy}){
     this.paginationOptions =   this.genPaginationPayload({"page": page, "size": itemsPerPage, "sortBy":sortBy})
     this.loadPage()
    },
    canEdit(item) {
      return this.isFromStaff || moment(item.date) > this.limit_15;
    },
    todoClick(item) {
      alertPopup(capitalize(this.$t("dialog.fullfillment_confirm"))).then(
        (result) => {
          if (result) {
            const fullfilled_at = moment().format("yyyy-MM-DD");
            this.$store
              .dispatch(`todos/markAsFullfilled`, {
                todo_id: item.pk,
                fullfilled_at: fullfilled_at
              })
              .then((item) => {
                slicyRemove(this.tasks, item);
              });
          }
        }
      );
    },

    openEditTask() {
      this.task_edit.producer_id = this.producerId;
      if (!isNone(this.$store.state.user)) {
        this.$store.dispatch("users/setSearch", this.$store.state.user);
        this.task_edit.assigned_to_id = this.$store.state.user.id;
      }
      this.editTask = true;
    },
    saveTaskEvent() {
      return this.$store.dispatch(`todos/addItem`, this.task_edit).then(() => {
        slicyUpdate(this.tasks, this.task_edit);
        this.$refs.editDialogTask.closeDialog();
      });
    },
    cleanupTaskDialog() {
      this.editTask = false;
      this.$store.dispatch(
        `todos/updateEdit`,
        this.$store.state["todos"].struct
      );
    },
    exportDialog() {
      this.exportDatePick = true;
      // this.exportDialogDatePick = false
    },
    exportCSV() {
      this.exportDatePick = false;
      this.loadingExport = true;
      const payload = {
        start: this.report_start,
        end: this.report_end
      };
      api.defaults.timeout = 30000;
      api
        .get("intervention_reports/export_csv/", { params: payload })
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = extractFilename(response.headers, "Export.csv");
          link.click();
          URL.revokeObjectURL(link.href);
          this.loadingExport = false;
        })
        .catch(() => {
          console.error;
          this.loadingExport = false;
        });
    },
    cleanupExport() {
      this.loadingExport = false;
      this.exportDatePick = false;
      this.report_end = undefined;
      this.report_start = undefined;
    },
    // ...filters.methods
// TODO: make this available everywhere, handle multiple keys and no key as all keys
    isInStore(val, moduleName, key){
      let l_ = unProxy(this.$store.state[moduleName].search)
      return l_.some(i => i[key].includes(val))
    },

    alarmProps(item) {
      if (typeof item !== 'object' || item === null) return;
      let titleParts = [];
      if (item.name) {
        titleParts.push(item.name);
      }
      if (item.name_en) {
        titleParts.push(item.name_en);
      }
      if (item.ref) {
        titleParts.push(item.ref);
      }
      const newItemTitle = titleParts.join(' -- ');
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
      return newItem;

  },},

  created() {
    this.producerId = parseInt(this.$route.query.producer_id) || undefined;
    this.userId = parseInt(this.$route.query.user_id) || undefined;
    if (
      !this.$store.getters.isUserFromStaff &&
      isNone(this.userId)
      // &&
      // this.$route.query.user_id === undefined
    ) {
      this.userId = this.$store.getters.user.id;
    }
    if (!isNone(this.userId)) {
      this.$store.dispatch("users/initSearch", { item_ids: this.userId });
    }
    this.alarmCodeId = parseInt(this.$route.query.alarm_code_id) || undefined;
    if (!isNone(this.alarmCodeId)) {
      this.$store.dispatch("alarm_codes/initSearch", {
        item_ids: this.alarmCodeId
      });
    }
    if (!isNone(this.producerId)) {
      const search_params = {producer_ids : [this.producerId]}
      this.$store.dispatch("todos/getUndone", search_params).then((items) => {
        this.tasks = items;
      });
      this.$store
        .dispatch(`producers/getItem`, this.producerId)
        .then((item) => {
          this.producerTypeId = item.producer_type_id;
          this.title = capitalize(
            i18n.global.t("intervention_reports.label_with_name", 10, {
              name: item.display_name
            })
          );
        });
    }
// unnecessary as loadOptions will call it once its triggered on render
//    this.loadPage();
  },

  watch: {
    autocompleteSearchAlarmCode(val) {
      if (!this.$store.getters.canSearch("alarm_codes")) return;
      if (isNone(val)) return;
      if (this.isInStore(val, 'alarm_codes', 'name')) return;
      let payload = { value: val };
      if (!isNone(this.producerTypeId)) {
        payload["producer_type_id"] = this.producerTypeId;
      }
      this.delayedSearch(val, "alarm_codes/searchItems", payload);
    },

    autocompleteSearchUser(val) {
      if (!this.$store.getters.canSearch("users")) return;
      if(this.isInStore(val,'users', 'name')) return
      let payload = {value:val}
      this.delayedSearch(val,"users/searchItems", payload);
    },
    selectedTask: function() {
      // reinit selection state
      this.selectedTask = null;
    }
  },

  data() {
    return {
      moduleName: "intervention_reports",
      headers: [
        {
          title: this.$t("producers.label"),
          key: "producer.display_name"
        },
        { title: this.$t("intervention_reports.date"), key: "date" },
        {
          title: this.$t("intervention_reports.number"),
          key: "number",
        },
        {
          title: this.$t("generic.created_by"),
          key: "created_by.name",
        },
        {
          title: this.$t("intervention_reports.partner", 10),
          key: "partners"
        },
        { title: this.$t("intervention_reports.cause"), key: "cause" },
        { title: this.$t("intervention_reports.action"), key: "action" },
        {
          title: this.$t("intervention_reports.status.label"),
          key: "status",
        },
        {
          title: this.$t("intervention_reports.used_part"),
          key: "affectations",
          sortable: false
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false
        }
      ],
      paginationOptions: { "page":1, "size":20},
      footerProps: {
        "items-per-page-options": [10, 20, 50],
        "items-per-page-text": this.$t("rows_per_page")
      },
      autocompleteSearchAlarmCode: undefined,
      autocompleteSearchUser: undefined,
      userId: undefined,
      alarmCodeId: undefined,
      searchValue: undefined,
      producerId: undefined,
      limit_15: moment().startOf("day").subtract(15, "days"),
      title: capitalize(i18n.global.t("intervention_reports.label", 10)),
      tasks: [],
      // risks: [],
      selectedTask: undefined,
      editTask: false,
      exportDatePick: false,
      loadingExport: false,
      report_start: undefined,
      report_end: undefined
    };
  },
}
</script>

<style>
ul.no_style {
  list-style-type: none;
}

.v-list-item__subtitle {
  white-space: pre;
}
</style>
