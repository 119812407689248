<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
          v-bind:headers="headers"
          v-bind:moduleName="moduleName"
          v-bind:actionDelete="false"
        >
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.name"
                  :label="$filters.capitalize($t('part_categories.name') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.name_en"
                  :label="$filters.capitalize($t('part_categories.name_en') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="editedItem.producer_type_ids"
                  :items="$store.state.producer_types.items"
                  item-title="name"
                  item-value="pk"
                  :label="$filters.capitalize($t('producer_types.label', 1) )"
                  variant="solo"
                  :multiple="true"
                  :chips="true"
                >
                </v-select>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";

export default {
  components: {
    Crud: Crud,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  created() {
    this.$store.dispatch("producer_types/getItems");
  },

  data() {
    return {
      moduleName: "part_categories",
      headers: [
        { title: this.$t("part_categories.name"), key: "name" },
        {
          title: this.$t("part_categories.name_en"),
          key: "name_en",
          sortable: false,
        }, // queryset uses distinct on name this cannot be sorted by name_en
        {
          title: this.$t("producer_types.label"),
          key: "producer_types",
          sortable: false,
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
