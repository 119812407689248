<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
          v-bind:headers="headers"
          v-bind:moduleName="moduleName"
          v-bind:saveOverride="saveOverride"
          v-bind:editOverride="editOverride"
          @editItem="editItem"
          @addItem="addItem"
        >
          <template v-slot:edit_content>
            <PreventiveMaintenanceForm
              v-bind:report_value="report_value"
            ></PreventiveMaintenanceForm>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import PreventiveMaintenanceForm from "@/components/PreventiveMaintenanceForm";
import { isNone, slicyUpdate } from "@/functions.js";

export default {
  components: {
    Crud: Crud,
    PreventiveMaintenanceForm: PreventiveMaintenanceForm,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  methods: {
    xOverride(verb, item) {
      const report_ = item.report;
      const hasNewFile = report_ instanceof File;
      return this.$store
        .dispatch(`${this.moduleName}/${verb}Item`, item)
        .then((new_) => {

          if (hasNewFile) {
            this.$store
              .dispatch(`${this.moduleName}/sendFile`, {
                pk: new_.pk,
                file: report_,
                name: report_.name,
              })
              .then((file_) => {
                slicyUpdate(
                  this.$store.state[`${this.moduleName}`].items,
                  file_,
                );
                this.$store.dispatch(
                  "snackbar/showSuccess",
                  this.$t("snack.edit_success"),
                );
              });
          } else if (isNone(report_)) {
            this.$store
              .dispatch(`${this.moduleName}/deleteFile`, new_.pk)
              .then(() => {
                item.report = null;
                slicyUpdate(
                  this.$store.state[`${this.moduleName}`].items,
                  item,
                );
                this.$store.dispatch(
                  "snackbar/showSuccess",
                  this.$t("snack.edit_success"),
                );
              });
          } else {
            this.$store.dispatch(
              "snackbar/showSuccess",
              this.$t("snack.edit_success"),
            );
          }
        });
    },
    saveOverride(item) {
      return this.xOverride("add", item);
    },
    editOverride(item) {
      return this.xOverride("edit", item);
    },
    editItem(item) {
      this.$store
        .dispatch("power_stations/getItem", this.editedItem.power_station_id)
        .then((item) => {
          const applicables =
            this.$store.state.preventiveMaintenancePeriods.filter((i) =>
              item.applicable_maintenances.includes(i.id),
            );
          this.$store.dispatch(`power_stations/updateEdit`, {
            ...item,
            applicable_maintenances: applicables,
          });
        });
      this.report_value = this.editedItem.report;
    },
    addItem() {
      this.report_value = null;
    },
  },
  data() {
    return {
      moduleName: "preventive_maintenances",
      headers: [
        {
          title: this.$t("power_stations.label"),
          key: "power_station.name",
        },
        {
          title: this.$t("preventive_maintenances.period.label"),
          key: "period",
        },
        {
          title: this.$t("preventive_maintenances.done_at"),
          key: "done_at",
        },
        {
          title: this.$t("preventive_maintenances.report"),
          key: "report",
          sortable: false,
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      report_value: null,
    };
  },
};
</script>
