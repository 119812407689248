<template>
  <v-container>
    <div style="display: none">
      <v-dialog v-model="showCouplingWarning" max-width="55%" @click:outside="showCouplingWarning = false">
        <v-card>
          <v-card-title>
            <span class="headline"></span>
          </v-card-title>

          <v-card-text class="text-center" style="font-size: 1.25em;">
            {{ $filters.capitalize($t("automatons.dialog.pdl_reset_warning")) }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-toolbar flat color="white" elevation="1">
      <v-spacer></v-spacer>
      <v-toolbar-title style="font-weight: bolder">
        {{ $filters.capitalize($t("automatons.label_status")) }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="(s, index) in items" :key="index">
        <v-card elevation="5" shaped class="mx-auto my-1">
          <v-card-title class="title-container">
            <v-row class="title-content" align="center" justify="space-between">
              <v-col cols="12" sm="6" class="py-0 text-center text-sm-left">
                {{ s.AS.producer.display_name }}
              </v-col>
              <v-col cols="12" sm="6" class="py-0 text-center text-sm-right">
                {{ getPowerP(s)}} 
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-subtitle :class="formatTimeAndClass(s.AS.time).timeClass" class="time-container">
            <div class="time-content">
              <v-icon v-if="formatTimeAndClass(s.AS.time).timeClass === 'time-ok'" color="green">mdi-check-circle</v-icon>
              <v-icon v-if="formatTimeAndClass(s.AS.time).timeClass === 'time-warning'" color="orange">mdi-alert</v-icon>
              <v-icon v-if="formatTimeAndClass(s.AS.time).timeClass === 'time-late'" color="red">mdi-alert-circle</v-icon>
              {{ formatTimeAndClass(s.AS.time).formattedTime }}
            </div>
          </v-card-subtitle>

          <v-card-text>
            <v-row v-for="(chunk, chunkIndex) in chunkedStatuses(s)" :key="chunkIndex">
              <v-col cols="12" sm="6" md="6" class="status-item" v-for="([key, status], statusIndex) in chunk" :key="statusIndex">
                  {{ $filters.capitalize($t(status.label)) }}
                  <v-icon
                    v-if="status.value === true"
                    small
                    color="success darken-1"
                    class="status-icon"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else-if="status.value === false"
                    small
                    color="error darken-1"
                    class="status-icon"
                  >
                    mdi-alert-circle
                  </v-icon>
                  <v-icon
                    v-else
                    small
                    color="grey darken-1"
                    class="status-icon"
                  >
                    mdi-help-circle
                  </v-icon>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-table class="mt-2">
                  <template v-slot:default>
                    <thead class="raw" @click="toggleTable(s.AS.pk)">
                      <tr>
                        <th class="text-left">{{ $filters.capitalize($t("automatons.cards.variable")) }}</th>
                        <th class="text-right">{{ $filters.capitalize($t("automatons.cards.value")) }}</th>
                      </tr>
                    </thead>
                    <tbody :id="`raw_table-${s.AS.pk}`" hidden>
                      <tr v-for="(value, key) in s.AS.raw" :key="key">
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import filters from "@/mixins/filters";

export default {
  mixins: [filters],
  computed: {
    timeStatusClass(time) {
      if (time == undefined) {
        return;
      }
      const now = new Date();
      const eventTime = new Date(time);
      const diffMinutes = (now.getTime() - eventTime.getTime()) / (1000 * 60);

      if (diffMinutes < 30) {
        return 'time-ok';
      } else if (diffMinutes < 60) {
        return 'time-warning';
      } else {
        return 'time-late';
      }
    },
    items() {
      const currentProducerItems = this.$store.state[this.moduleName]?.currentProducerItems;
      if (!currentProducerItems) {
        return [];
      }

      const producerMap = new Map();
      currentProducerItems.forEach((item) => {
        producerMap.set(item.producer_id, item);
      });
      let items = currentProducerItems.map((itm) => {
        let data = producerMap.get(itm.producer_id);
        return {
          AS: data,
        };
      });
      return items;
    },
  },

  created() {
    this.loadPage();
    this.$nextTick(function () {
      this.intervalId = window.setInterval(() => {
        this.loadPage();
      }, 5 * 60 * 1000); // 5 minutes
    });
  },

  unmounted() {
    window.clearInterval(this.intervalId);
  },

  methods: {
    chunkedStatuses(statuses) {
      const entries = Object.entries(this.getStatuses(statuses));
      return this.chunkArray(entries, 2);
    },
    getPowerP(s) {
        return "`${s.AS.Puissance_P}` kWh"
    },
    getStatuses(s) {
      if (s == undefined) return;
      return [
        { label: 'automatons.has_coupling_authorisation', value: s.AS.has_coupling_authorisation },
        { label: 'automatons.has_enedis_voltage', value: s.AS.has_enedis_voltage },
        { label: 'automatons.has_closed_breaker', value: s.AS.has_closed_breaker },
        { label: 'automatons.has_limitations_DEIE_P', value: s.AS.has_limitations_DEIE_P },
        { label: 'automatons.has_limitations_DEIE_Q', value: s.AS.has_limitations_DEIE_Q },
        { label: 'automatons.has_alpiq_stop', value: s.AS.has_alpiq_stop },
        { label: 'automatons.has_Dreal_Stop', value: s.AS.has_Dreal_Stop }
      ];
    },
    chunkArray(array, chunkSize) {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    },
    getPowerP(s) {
      let power = s.AS.raw.Puissance_P
      return power ? `${power} kWh` : "No power available";
    },

    formatTimeAndClass(time) {
      if (!time) {
        return {
          formattedTime: 'N/A',
          timeClass: ''
        };
      }
      const now = new Date();
      const eventTime = new Date(time);
      const diffMinutes = (now.getTime() - eventTime.getTime()) / (1000 * 60);
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      const formattedTime = eventTime.toLocaleDateString(undefined, options);
      let timeClass = '';
      if (diffMinutes < 30) {
        timeClass = 'time-ok';
      } else if (diffMinutes < 60) {
        timeClass = 'time-warning';
      } else {
        timeClass = 'time-late';
      }
      return {
        formattedTime,
        timeClass
      };
    },
    toggleJson(item) {
      if (this.shows[item.pk] !== undefined) {
        this.$set(this.shows, item.pk, !this.shows[item.pk]);
      }
    },
    loadPage() {
      this.$store.dispatch(`${this.moduleName}/getCurrentStatus`);
    },

    toggleTable(id_) {
      var x = document.getElementById(`raw_table-${id_}`);
      if (x.hidden) {
        x.hidden = false;
      } else {
        x.hidden = true;
      }
    },
  },

  data() {
    return {
      moduleName: "automatons",
      autocompleteSearchProducer: null,
      shows: {},
      showCouplingWarning: false,
      limit: moment().subtract(2, "hours"),
      intervalId: undefined,
    };
  },
};
</script>

<style scoped>
.raw {
  cursor: pointer;
}
.mt-2 {
  margin-top: 16px;
}
.json {
  white-space: pre;
}
.late {
  color: red;
  font-style: italic;
}
.late::before {
  content: "⚠ ";
}
thead.raw {
  cursor: pointer;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.time-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.time-ok {
  color: green;
  font-weight: bold;
}

.time-warning {
  color: orange;
  font-weight: bold;
}

.time-late {
  color: red;
  font-weight: bold;
  background-color: #ffe6e6;
  padding: 5px;
  border-radius: 4px;
}
.status-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.status-icon {
  margin-left: auto;
}
</style>
