<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col v-show="askStockPart" cols="12" sm="6" md="6">
        <v-autocomplete
          v-model="category"
          :items="$store.state.part_categories.search"
          :item-props="categoryProps"
          :label="$filters.capitalize($t('part_categories.label'))"
          v-model:search="autocompleteSearchCategory"
          :loading="$store.state.part_categories.isSearching"
          append-icon="mdi-text-box-search"
          variant="solo"
          :clearable="true"
          no-filter
          @update:modelValue="categorySelected"
          return-object
        >
          <template #no-data>
            {{
              $filters.capitalize(
                $t("search_for", { noun: $t("part_categories.label", 1) })
              )
            }}
          </template>
        </v-autocomplete>
      </v-col>

      <v-col v-show="askStockPart" cols="12" sm="6" md="6">
        <v-autocomplete
          v-model="editedItem.stock_part_id"
          :items="$store.state.stock_parts.search"
          :item-props="stockPartProps"
          item-value="pk"
          :label="$filters.capitalize($t('stock_parts.label'))"
          v-model:search="autocompleteSearchStockPart"
          :loading="$store.state.stock_parts.isSearching"
          append-icon="mdi-text-box-search"
          @update:modelValue="stockPartSelected"
          variant="solo"
          return-object
          no-filter
        >
          <template #no-data>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("stock_parts.label", 1) })
                  )
                }}
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-autocomplete
          :disabled="!askPlace"
          v-model="editedItem.place_id"
          :items="$store.state.places.search"
          :item-props='placeProps'
          :label="$filters.capitalize($t('places.label', 1))"
          v-model:search="autocompleteSearchPlace"
          :loading="$store.state.places.isSearching"
          @update:search='handlePlaceSearch'          
          append-icon="mdi-text-box-search"
          :clearable="true"
          variant="solo"
        >
          <template #no-data>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("places.label", 1) })
                  )
                }}
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-text-field
          :disabled="!serialSelection"
          v-model="editedItem.serial"
          :label="$filters.capitalize($t('units.serial', 1))"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import utils from "@/mixins/utils";
import { isNone, unProxy } from "@/functions.js";

export default {
  mixins: [filters, utils],

  props: {
    askStockPart: { type: Boolean, required: false, default: true },
    askPlace: { type: Boolean, required: false, default: true },
    partFilter: { type: String, required: false, default: "" }, // un, pi, ...
    producerId: { type: Number, required: false, default: undefined },
    stockageId: { type: Number, required: false, default: undefined },
    placeId: { type: Number, required: false, default: undefined },
    stockPartId: { type: Number, required: false, default: undefined },
    producerTypeId: { type: Number, required: false, default: undefined },
    moduleName: { type: String, required: false, default: 'parts'},
  },

  computed: {
    editedItem: {
      get() {
        return this.$store.getters.dataEdit(this.moduleName);
      },
      set(newValue) {
        this.$store.setters.dataEdit(this.moduleName, newValue);
      }
    },
    available_categories: function() {
      return this.$store.state[`part_categories`].search;
    },
    cat_name: function() {
      if (this.$store.getters.language === "en") {
        return "name_en";
      }
      return "name";
    }
  },

  created() {
    this.loadLists();
  },

  methods: {
    categoryProps(item) {
      if (typeof item !== 'object' || item === null) return;
      const language = this.$store.getters.language;
      
      const nameBasedOnLang = language === 'en' && item.name_en ? item.name_en : item.name;
      
      let producerNames = language === 'en' ? 'Generic' : 'Génerique';

      if (Array.isArray(item.producer_types) && item.producer_types.length > 0) {
        producerNames = item.producer_types.map(producer => {
          return language === 'en' && producer.name_en ? producer.name_en : producer.name;
        }).join(', '); 
      }
      const newItemTitle = producerNames ? `${nameBasedOnLang} (${producerNames})` : nameBasedOnLang;

      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
      return newItem;
    },


    stockPartProps(item) {
      if (typeof item !== 'object' || item === null) return;
      const titleParts = [];
      if (item.name) {
        titleParts.push(item.name);
      }
      if (item.name_en) {
        titleParts.push(item.name_en);
      }
      if (item.ref) {
        titleParts.push(item.ref);
      }
      const newItemTitle = titleParts.join(' -- ');
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'error_item_props' };
      return newItem;
    },

    placeProps(item) {
      if (typeof item !== 'object' || item === null) return;

      const titleParts = [];

      if (item.stockage && item.stockage.name) {
        titleParts.push(item.stockage.name);
      }
      
      if (item.cabinet) {
        titleParts.push(item.cabinet);
      }

      if (item.shelf) {
        titleParts.push(item.shelf);
      }

      const newItemTitle = titleParts.join(' - ');
      const newItem = { ...item, title: newItemTitle, selection: newItemTitle, value: item.pk || 'default_value' };

      if (this.$store.getters.language === 'en' && item.ref) {
        newItem.selection = `${item.name} – ${item.ref}`;
      }

      return newItem;
    },


    loadLists() {
      this.user_en = this.$store.getters.language === 'en'

      //I can change this bit for just this.updateCategory('')
      if (this.producerId) {
        this.$store.dispatch(
          "part_categories/getItemsFromProducer",
          this.producerId
        ).then((items)=>{
        this.$store.dispatch("part_categories/setSearch",items)
        this.updatePlace("");
        })     
      } 
      else if (this.producerTypeId) {
        //this.$store.dispatch('part_categories/searchItemsFromProducerType', {value:'', producer_types:[this.producerTypeId]})
        this.updateCategory('')
      } 
      else {
              this.$store.dispatch("part_categories/getItems", { size: 40 });
        if (
          !isNone(this.editedItem.stock_part) &&
          !isNone(this.editedItem.stock_part.category_id)
        ) {
          this.category = this.editedItem.stock_part.category_id;
        }
      }
      if (!isNone(this.placeId) && this.askPlace) {
        this.editedItem.place_id = this.placeId;
        this.$store.dispatch("places/initSearch", { item_ids: this.placeId });
      }
      if (!isNone(this.stockPartId)) {
        this.editedItem.stock_part_id = this.stockPartId;
      }
      if (!this.askStockPart) {
        // in that case, add reference button is permitted by default
        this.serialSelection = true;
      }
    },

    clearForm() {
      this.updatePlace("");
      this.updateCategory("");
      this.updateStockPart("");
      this.category = null;
      this.editedItem.place_id = undefined;
    },

    updateCategory(val) {
      var payload = { value: val, size: 50 };
      if (!isNone(this.producerId)) {
        payload.producer_types = this.$store.state.producers.search[0].producer_type_id;
        this.delayedSearch(val, "part_categories/searchItemsFromProducerType", payload);
      } else if (!isNone(this.producerTypeId)) {
        payload.producer_types = this.producerTypeId;
        this.delayedSearch(val, "part_categories/searchItemsFromProducerType", payload);
      } else {
        this.delayedSearch(val, "part_categories/searchItems", payload);
      }
    },

    updateStockPart(val) {
      var payload = { value: val, size: 50, category_id: this.category != null ? this.category.pk:null };
      if (!isNone(this.producerTypeId)) {
        payload["producer_type_id"] = this.producerTypeId;
      }
      if (!isNone(this.partFilter)) {
        payload["unity"] = self.partFilter;
      }
      this.delayedSearch(val, "stock_parts/searchItems", payload);
    },

    updatePlace(val) {
      if (this.producerId !== undefined) {
        this.$store.dispatch("places/searchItemsFromProducer", {
          producer_id: this.producerId,
          value: val
        });
      } else {
        var payload = { value: val };
        if (this.stockageId !== undefined) {
          payload["stockage_id"] = this.stockageId;
        }
        this.delayedSearch(val, "places/searchItems", payload);
      }
    },

    categorySelected(item) {
      if(!isNone(item)){
        this.category = item;
        this.updateStockPart();
      }else if(isNone(item) || item.pk != this.category.pk){
        this.editedItem.stock_part_id = null;
      }
    },

    stockPartSelected(item) {
      if (!isNone(item)) {
        this.editedItem.stock_part_id = item.pk;
        this.serialSelection = item.unity === "un";
      } else {
        this.serialSelection = false;
      }
    },
    handlePlaceSearch(val){
        if (!this.$store.getters.canSearch("places")) return;
        this.updatePlace(val);
    },
  },

  watch: {
    serialSelection(val){
      if (val) { 
        this.editedItem.quantity = undefined;
        this.editedItem.serial = ' ';
      } else {
        this.editedItem.quantity = 0;
        this.editedItem.serial = undefined;
      }
    },
    autocompleteSearchCategory(val) {
      if (!this.$store.getters.canSearch("part_categories")) return;
      if (!this.category) {
        this.updateCategory(val);
      }
    },
    autocompleteSearchStockPart(val) {
      if (!this.$store.getters.canSearch("stock_parts")) return;
      if (!this.editedItem.stock_part_id) {
        this.updateStockPart(val);
      }
    },
    category() {
      this.updateStockPart("");
    },
    stockageId() {
      this.clearForm();
    }
  },

  data() {
    return {
      user_en: true,
      autocompleteSearchCategory: null,
      autocompleteSearchPlace: null,
      autocompleteSearchStockPart: null,
      category: null,
      serialSelection: false,
    };
  }
};
</script>
