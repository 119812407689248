<template>
  <v-container pa-0 ma-0>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <DatePicker
          v-bind:label="'purchases.states.ordered'"
          v-bind:value="editedItem.ordered_at"
          @input="editedItem.ordered_at = $event"
        >
        </DatePicker>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="editedItem.comment"
          :label="$filters.capitalize($t('purchases.comment'))"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-text-field
          v-if="askInfos"
          v-model="editedItem.delay"
          single-line
          type="number"
          min="0"
          :label="$filters.capitalize($t('purchases.delay'))"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <DatePicker
          v-bind:label="'purchases.states.quoted'"
          v-bind:value="editedItem.quoted_at"
          @input="editedItem.quoted_at = $event"
        >
        </DatePicker>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <DatePicker
          v-bind:label="'purchases.states.paid'"
          v-bind:value="editedItem.paid_at"
          @input="editedItem.paid_at = $event"
        >
        </DatePicker>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <DatePicker
          v-bind:label="'purchases.states.sent'"
          v-bind:value="editedItem.sent_at"
          @input="editedItem.sent_at = $event"
        >
        </DatePicker>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <DatePicker
          v-if="askReceivedAt"
          v-bind:label="'purchases.states.received'"
          v-bind:value="editedItem.received_at"
          @input="editedItem.received_at = $event"
        >
        </DatePicker>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import DatePicker from '@/components/DatePicker';

export default {
  components: {
    DatePicker
  },

  mixins: [filters],

  props: {
    askReceivedAt: { type: Boolean, required: false, default: true },
    askInfos: { type: Boolean, required: false, default: true },
  },

  computed: {
    editedItem: function () {
      const a = this.$store.getters.dataEdit(this.moduleName);
      return a;
    },
  },

  data() {
    return {
      moduleName: "purchases",
    };
  },
};
</script>
