<template>
  <v-container>
    <div style="display: none">
      <EditDialog
        v-bind:model="chooseRange"
        v-bind:moduleName="'analytics'"
        v-bind:btnName="''"
        v-bind:formTitle="$t('analytics.period.choose_label')"
        @save="saveRange"
        @close="cleanupRangeDialog"
        ref="editDialogRange"
      >
        <template v-slot:edit_content>
          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="showRangeBegin"
                :close-on-content-click="false"
                :nudge-right="100"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ props }">                  <v-text-field
                    :value="rangeBegin"
                    :label="$filters.capitalize($t('generic.begin_date') )"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="rangeBegin"
                  @input="showRangeBegin = false"
                  :locale="$i18n.locale"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="showRangeEnd"
                :close-on-content-click="false"
                :nudge-right="100"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ props }">                  <v-text-field
                    :value="rangeEnd"
                    :label="$filters.capitalize($t('generic.end_date') )"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="rangeEnd"
                  @input="showRangeEnd = false"
                  :locale="$i18n.locale"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </EditDialog>
    </div>

    <v-toolbar text color="white" elevation="1">
      ! {{ $filters.capitalize($t("under_construction") ) }} !
      <v-spacer></v-spacer>

      <v-toolbar-title style="font-weight: bolder" class="mr-2">{{
        $filters.capitalize($t(pageTitle, 1) )
      }}</v-toolbar-title>

      <v-menu
        :return-value="angleId"
        :open-on-hover="true"
        :close-on-click="true"
        :close-on-content-click="true"
        :offset-x="false"
        :offset-y="true"
      >
        <template v-slot:activator="props">
          <v-btn color="primary" dark v-bind="props">
            {{ currentAngle.name }}
            <v-icon class="ml-2">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in angles"
            :key="item.id"
            @click="chooseAngle(item)"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      ! {{ $filters.capitalize($t("under_construction") ) }} !
    </v-toolbar>

    <v-toolbar text color="white" elevation="1">
      <v-autocomplete
        v-model="producer_id"
        :items="$store.state.producers.search"
        item-title="display_name"
        item-value="pk"
        :label="$filters.capitalize($t('producers.label', 1) )"
        v-model::search="autocompleteSearchProducer"
        :loading="$store.state.producers.isSearching"
        :filter="filterProducers"
        append-icon="mdi-text-box-search"
        :clearable="true"
        variant="solo"
        outlined
        density= 'compact'
        hide-details
        @update:modelValue="selectProducer"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{
                $filters.capitalize($t("search_for", { noun: $t("producers.label", 1) })
                  )
              }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-menu
        :return-value="periodId"
        :open-on-hover="true"
        :close-on-click="true"
        :close-on-content-click="true"
        :offset-x="false"
        :offset-y="true"
      >
        <template v-slot:activator="props">
          <v-btn color="primary" dark v-bind="props" class="mr-3">
            <v-icon class="mr-2">mdi-timelapse</v-icon>
            {{ currentPeriod.name }}
            <v-icon class="ml-2">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in periods"
            :key="item.id"
            @click="choosePeriod(item)"
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-row align="center" justify="center">
      <v-col cols="12" md="9" align="center" justify="center">
        {{ pageDescription }}.
      </v-col>

      <v-col cols="12" md="9">
        <v-data-table
          :headers="headers"
          :items="data"
          :options.sync="options"
          :footer-props="footerProps"
          :loading="$store.state.analytics.isLoading"
          :no-data-text="$filters.capitalize($t('no_data_text') )"
          :no-results-text="$filters.capitalize($t('no_results_text') )"
          item-key="id"
          elevation="2"
          fixed-header
        >
          <template v-slot:item.alarm="{ item }">
            <router-link
              :to="{
                name: 'intervention_reports',
                query: { alarm_code_id: item.alarm.pk, user_id: null },
              }"
            >
              {{ item.alarm.name }} &mdash; {{ item.alarm.producer_type.name }}
            </router-link>
          </template>

          <template v-slot:item.producer="{ item }">
            {{ item.producer.display_name }} &mdash;
            {{ item.producer.producer_type.name }}
          </template>

          <template v-slot:item.availability_avg="{ item }">
            <span v-if="item.availability_avg === null">?</span>
            <span v-else>{{
              $filters.formatFloat((item.availability_avg * 100) )
            }}</span>
          </template>

          <template v-slot:item.switches_sum="{ item }">
            <span v-if="item.switches_sum === null">?</span>
            <span v-else>{{ toFrequency(item.switches_sum) }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { capitalize, isNone } from "@/functions.js";
import filters from "@/mixins/filters";
import EditDialog from "@/components/EditDialog";

export default {
  mixins: [filters],

  components: {
    EditDialog: EditDialog,
  },

  mounted() {
    this.currentPeriod = this.periods[0];
    this.chooseAngle(this.angles[0]);
  },

  methods: {
    loadPage() {
      var payload = {};
      if (!isNone(this.producer_id)) {
        payload["producer_id"] = this.producer_id;
      }
      const period_params = {
        begin: moment(this.rangeBegin).format("D MMM"),
        end: moment(this.rangeEnd).format("D MMM"),
      };

      switch (this.currentPeriod.id) {
        case "week":
        case "weekend":
          payload["period"] = this.currentPeriod.id;
          break;
        case "choose":
          payload["start"] = this.rangeBegin;
          payload["end"] = this.rangeEnd;
          this.currentPeriod.name = capitalize(
            this.$t("analytics.period.choosed", period_params),
          );
          break;
        case "":
        case "all":
          break;
        default:
          console.error(`Unknown period ${this.currentPeriod.name}.`);
      }

      switch (this.currentAngle.id) {
        case 1:
          this.$store
            .dispatch("analytics/getByCause", payload)
            .then((items) => {
              this.headers = this.headersCause;
              this.pageDescription = capitalize(
                this.$t("analytics.description.cause"),
              );
              this.data = items;
            });
          break;
        case 2:
          this.$store
            .dispatch("analytics/getByDuration", payload)
            .then((items) => {
              this.headers = this.headersDuration;
              this.pageDescription = capitalize(
                this.$t("analytics.description.duration"),
              );
              this.data = items;
            });
          break;
        case 4:
          this.$store
            .dispatch("analytics/getByFrequency", payload)
            .then((items) => {
              this.headers = this.headersFrequency;
              this.pageDescription = capitalize(
                this.$t("analytics.description.frequency"),
              );
              this.data = items;
            });
          break;
        default:
          console.error(`Unknown angle ${this.currentAngle.name}.`);
      }
    },

    selectProducer() {
      this.loadPage();
    },
    chooseAngle(angle) {
      this.currentAngle = angle;
      this.loadPage();
    },
    choosePeriod(period) {
      this.currentPeriod = period;
      if (period.id === "choose") {
        this.chooseRange = true;
      } else {
        this.loadPage();
      }
    },

    toFrequency(value) {
      return Math.trunc(value / 2);
    },

    saveRange() {
      this.loadPage();
      this.$refs.editDialogRange.closeDialog();
    },
    cleanupRangeDialog() {
      this.chooseRange = false;
    },
  },

  watch: {
    autocompleteSearchProducer(val) {
      if (!this.$store.getters.canSearch("producers")) return;
      this.$store.dispatch("producers/searchItems", { value: val });
    },
  },

  unmounted() {
    this.power_stations = [];
  },

  data() {
    return {
      pageTitle: "analytics.label_by",
      moduleName: "analytics",
      autocompleteSearchProducer: null,
      producer_id: undefined,
      angles: [
        { id: 1, name: capitalize(this.$t("analytics.angle.cause")) },
        { id: 2, name: capitalize(this.$t("analytics.angle.duration")) },
        { id: 3, name: capitalize(this.$t("analytics.angle.prod_loss")) },
        { id: 4, name: capitalize(this.$t("analytics.angle.frequency")) },
      ],
      angleId: 1,
      currentAngle: "",
      periods: [
        { id: "all", name: capitalize(this.$t("analytics.period.all")) },
        { id: "week", name: capitalize(this.$t("analytics.period.week")) },
        {
          id: "weekend",
          name: capitalize(this.$t("analytics.period.weekend")),
        },
        //{ id: 'hour', name: capitalize(this.$t('analytics.period.hour')) },
        {
          id: "choose",
          name: capitalize(this.$t("analytics.period.choose")),
        },
      ],
      periodId: 1,
      currentPeriod: "",
      headers: [],
      headersCause: [
        { title: this.$t("analytics.object"), key: " alarm" },
        { title: this.$t("analytics.data"), key: " occurrences" },
        //{ title: this.$t('actions.label'), key: 'actions', sortable: false },
      ],
      headersDuration: [
        { title: this.$t("analytics.object"), key: " producer" },
        { title: this.$t("analytics.data"), key: " availability_avg" },
        //{ title: this.$t('actions.label'), key: 'actions', sortable: false },
      ],
      headersFrequency: [
        { title: this.$t("analytics.object"), key: " producer" },
        { title: this.$t("analytics.data"), key: " switches_sum" },
        //{ title: this.$t('actions.label'), key: 'actions', sortable: false },
      ],
      pageDescription: "",
      options: {
        itemsPerPage: -1,
      },
      footerProps: {
        "items-per-page-options": [-1],
        "items-per-page-text": this.$t("rows_per_page"),
      },
      chooseRange: false,
      showRangeBegin: false,
      showRangeEnd: false,
      rangeBegin: moment().format("yyyy-MM-DD"),
      rangeEnd: moment().format("yyyy-MM-DD"),
      data: [],
    };
  },
};
</script>
