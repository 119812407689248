<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName">
          <template v-slot:edit_content>
            <PurchaseLineForm
              :key="editedItem.pk"
              v-bind:adminView="true"
            ></PurchaseLineForm>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import PurchaseLineForm from "@/components/PurchaseLineForm";

export default {
  components: {
    Crud: Crud,
    PurchaseLineForm: PurchaseLineForm,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  data() {
    return {
      moduleName: "purchase_lines",
      headers: [
        { title: this.$t("stock_parts.label"), key: "stock_part.name" },
        { title: this.$t("generic.created_at"), key: "created_at" },
        { title: this.$t("purchase_lines.quantity"), key: "quantity" },
        { title: this.$t("purchase_lines.price"), key: "price" },
        { title: this.$t("stockages.label"), key: "stockage.name" },
        {
          title: this.$t("purchases.ordered_at"),
          key: "purchase.ordered_at",
        },
        { title: this.$t("purchases.label"), key: "purchase_id" },
        {
          title: this.$t("purchases.comment"),
          key: "purchase.comment",
          sortable: false,
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      autocompleteSearch: null,
    };
  },
};
</script>
