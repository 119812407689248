<template>
  <v-card color="white" class="pa-2 elevation-1" density="comfortable">
    <v-row justify="space-around" align="start" density="compact">
      <v-col>
        <v-autocomplete
          v-model="producerIds"
          :items="$store.state.producers.all"
          :item-props="producerProps"
          :label="$filters.capitalize($t('producers.label', 1) )"
          :loading="$store.state.producers.isSearching"
          :filter="filterProducers"
          :clearable="true"
          :multiple="true"
          variant="solo"
          density="compact"
          hide-details
          return-object
          @update:modelValue="handleChange"
          @click:clear="handleChange"
          >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{
                  $filters.capitalize(
                    $t("search_for", { noun: $t("producers.label", 1) })
                  )
                }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col>
        <VueDatePicker 
          v-model="dateRange"
          @update:model-value="handleChange"
          @cleared="handleChange"
          :teleport="true"
          range 
          :enable-time-picker="false"
        >
        </VueDatePicker>
      </v-col>

      <v-col cols="2">
        <v-select
          v-model="selectOrigin"
          :value="editedItem.origin"
          :label="$filters.capitalize($t('limitations.origin.label'))"
          :items="origin_options"
          item-title="text"
          item-value="value"
          :clearable="true"
          :multiple="true"
          @update:modelValue="handleChange"
          @click:clear="handleChange"
          variant="solo"
          density="compact"
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import utils from "@/mixins/utils";
import filters from "@/mixins/filters";
import { capitalize, isNone } from "@/functions";

export default {
  mixins: [utils, filters],
  emits: ["filterToP"],
  methods: {
    getOriginOptionByValue(value) {
      return this.origin_options.find(option => option.value === value);
    },
    producerProps(item) {
      if (typeof item !== 'object' || item === null) return;
      const newItem = { 
        ...item, 
        title: item.display_name, 
        selection: item.display_name, 
        value: item.pk || 'error_item_props' 
      };
      return newItem;
    },
    handleChange() {
      let date_start = this.dateRange?.[0] ?? null;
      let date_end = this.dateRange?.[1] ?? null;
      if (date_start != null && date_end != null) {
        date_start = new Date(date_start);
        date_end = new Date(date_end);
      }
      const idList = this.producerIds.map(item => item.pk) ?? null;
      const selectOrigin = this.selectOrigin ?? null;
      const searchParams = {
        start: date_start,
        end: date_end,
        producer_id: idList,
        origin_options: selectOrigin
      };
      this.$emit("filterToP", searchParams);
    },
    selectProdFromQuery(list = this.$store.state.producers.all) {
      const p_id = this.$route.query.producer_id;
      const proddy = list.find(opt => opt.pk == p_id);
      if (proddy) {
        this.producerIds = [proddy];
      }
    },

  },
  created() {
    this.select = this.getOriginOptionByValue(this.editedItem.origin);
    if (this.$store.state.producers.all.length <= 0) {
      this.$store.dispatch('producers/getAll').then((list) => {
        if (!isNone(this.$route.query.producer_id)) this.selectProdFromQuery(list);
      });
    } else {
      this.selectProdFromQuery();
    }
    this.handleChange(); //Load default search parameters
  },
  data() {
    return {
      moduleName: "limitations",
      search_params: {},
      producerIds: [],
      dateRange: [
          moment("2024-01-01").startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          moment().add(1, 'd').endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        ],
      selectOrigin: ["ui", "deie"],
      origin_options: [
        {
          value: "ui",
          text: this.$t("limitations.origin.ui"),
          disabled: false
        },
        {
          value: "sp",
          text: this.$t("limitations.origin.sp"),
          disabled: false
        },
        {
          value: "deie",
          text: this.$t("limitations.origin.deie"),
          disabled: true
        }
      ]
    };
  }
};
</script>
