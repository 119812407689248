<template>
  <v-container>
  <v-dialog
    v-model="imageInLarge"
    max-width="90vw"
    transition="scale-transition"
    content-class="imageInLargeDialog"
    @click:outside="imageInLarge = false"
  >
    <v-btn
      class="mx-auto"
      icon="true"
      color="secondary"
      @click="imageInLarge = false"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-img :src="imageInLargeSrc" alt="" max-height="90vh"/>
  </v-dialog>
    <v-dialog
      v-model="showAddConsumable"
      max-width="75%"
      @click:outside="showAddConsumable = false"
      @addItemEnd="cleanupDialog"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
              $filters.capitalize($t("consumables.form_title"))
            }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <ConsumableForm :askStockPart=false></ConsumableForm>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary lighten-2"
            variant="text"
            @click="showAddConsumable = false"
          >{{ $filters.capitalize($t("cancel")) }}
          </v-btn>
          <v-btn color="success" @click="saveAddConsumableItem()">{{
              $filters.capitalize($t("save"))
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAddPart"
      max-width="75%"
      @click:outside="showAddPart = false"
      @addItemEnd="cleanupDialog"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
              $filters.capitalize($t("units.form_title"))
            }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <UnitForm v-bind:askStockPart="false"></UnitForm>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary lighten-2"
            variant="text"
            @click="showAddPart = false"
          >{{ $filters.capitalize($t("cancel")) }}
          </v-btn
          >
          <v-btn color="success" @click="saveAddPartItem()">{{
              $filters.capitalize($t("save"))
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-toolbar flat color="white" elevation="1">
      <v-text-field
        v-model="searchValue"
        append-icon="mdi-folder-search"
        :label="$filters.capitalize($t('search'))"
        single-line
        hide-details
      ></v-text-field>

      <v-spacer></v-spacer>

      <v-toolbar-title style="font-weight: bolder">{{
          $filters.capitalize($t(`${moduleName}.label`, 10))
        }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <EditDialog
        v-bind:model="editDialog"
        v-bind:moduleName="moduleName"
        v-bind:btnName="btnName"
        v-bind:formTitle="formTitle"
        @add="addItemEvent"
        @save="saveItemEvent"
        @close="cleanupDialog"
        ref="editDialogRef"
      >
        <template v-slot:edit_content>
          <slot name="edit_content"></slot>
        </template>
      </EditDialog>
    </v-toolbar>
    <v-data-table-server
      :headers="headers"
      :items="module.items"
      v-model:page="page"
      :items-length="module.pagination.size || 0"
      v-model:items-per-page="itemsPerPage"
      :items-per-page-options="itemsPerPageOptions"
      :items-per-page-text="$t('rows_per_page')"
      :loading="module.loading"
      :no-data-text="$filters.capitalize($t('no_data_text'))"
      fixed-header
      @update:options="loadPage"
    >
      <template v-slot:item.kind="{ item }">
        {{ $filters.capitalize($filters.affectationKindFilter(item.kind)) }}
      </template>

      <template v-slot:item.unity="{ item }">
        {{ $filters.capitalize($filters.unityFilter(item.unity)) }}
      </template>
      <template v-slot:item.stock_part.unit="{ item }">
        {{ $filters.capitalize($filters.unityFilter(item.stock_part.unit)) }}
      </template>

      <template v-slot:item.variety="{ item }">
        {{ $filters.capitalize($filters.varietyFilter(item.variety)) }}
      </template>

      <template v-slot:item.status="{ item }">
        {{ $filters.capitalize($filters.reportStatusFilter(item.status)) }}
      </template>

      <template v-slot:item.criticality="{ item }">
        {{
          $filters.capitalize($filters.criticalityFilter(item.criticality))
        }}
      </template>

      <template v-slot:item.producer_types="{ item }">
        <ul v-if="item.producer_types !== undefined">
          <li
            v-for="producer_type in item.producer_types"
            v-bind:key="producer_type.pk"
          >
            {{ $filters.capitalize(producer_type.name) }}
          </li>
        </ul>
      </template>

      <template v-slot:item.created_by="{ item }">
        {{ $filters.capitalize(item.created_by.name) }}
      </template>
      <template v-slot:item.updated_by="{ item }">
        {{ $filters.capitalize(item.updated_by.name) }}
      </template>
      
      <template v-slot:item.users="{ item }">
        <ul v-if="item.users != undefined">
          <li v-for="user in item.users" v-bind:key="user.pk">
            {{ $filters.capitalize(user.name) }}
          </li>
        </ul>
      </template>

      <template v-slot:item.applicable_maintenances="{ item }">
        <ul v-if="item.applicable_maintenances !== undefined">
          <li
            v-for="applim in item.applicable_maintenances"
            v-bind:key="applim.pk"
          >
            {{ $filters.capitalize($filters.formatPeriod(applim)) }}
          </li>
        </ul>
      </template>

      <template v-slot:item.lines="{ item }">
        <ul v-if="item.lines !== undefined">
          <li v-for="line in item.lines" v-bind:key="line.pk">
            {{ line.quantity }} &times;
            {{ $filters.capitalize(line.stock_part.name) }}
          </li>
        </ul>
      </template>

      <template v-slot:item.suppliers="{ item }">
        <ul v-if="item.suppliers !== undefined">
          <li v-for="supplier in item.suppliers" v-bind:key="supplier.pk">
            {{ $filters.capitalize(supplier.name) }}
          </li>
        </ul>
      </template>

      <template v-slot:item.price="{ item }">
        {{ $filters.formatPrice(item.price, item.price_currency) }}
      </template>
      <template v-slot:item.resell_price="{ item }">
        {{ $filters.formatPrice(item.resell_price, item.resell_price_currency) }}
      </template>

      <template v-slot:item.report="{ item }">
        <v-tooltip location="top" :text="$filters.capitalize($t('actions.download'))">
          <template v-slot:activator="{ props }">
            <v-icon
              v-show="item.report !== null"
              v-bind="props"
              class="mr-auto"
              @click="openLink(item.report)"
            >mdi-file-outline
            </v-icon
            >
          </template>
        </v-tooltip>
      </template>

      <!-- Dates conversions -->
      <template v-slot:item.agreed_at="{ item }">
        {{ $filters.formatDate(item.agreed_at) }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ $filters.formatDate(item.created_at) }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ $filters.formatDate(item.date) }}
      </template>
      <template v-slot:item.done_at="{ item }">
        {{ $filters.formatDate(item.done_at) }}
      </template>
      <template v-slot:item.repaired_at="{ item }">
        {{ $filters.formatDate(item.repaired_at) }}
      </template>
      <template v-slot:item.received_at="{ item }">
        {{ $filters.formatDate(item.received_at) }}
      </template>
      <template v-slot:item.sent_at="{ item }">
        {{ $filters.formatDate(item.sent_at) }}
      </template>
      <template v-slot:item.fullfilled_at="{ item }">
        {{ $filters.formatDate(item.fullfilled_at) }}
      </template>
      <template v-slot:item.commissioned_at="{ item }">
        {{ $filters.formatDate(item.commissioned_at) }}
      </template>
      <template v-slot:item.ordered_at="{ item }">
        {{ $filters.formatDate(item.ordered_at) }}
      </template>
      <template v-slot:item.quoted_at="{ item }">
        {{ $filters.formatDate(item.quoted_at) }}
      </template>
      <template v-slot:item.paid_at="{ item }">
        {{ $filters.formatDate(item.paid_at) }}
      </template>

      <template v-slot:item.image="{ item }">
        <v-img
          :src="item.image"
          max-height="32px"
          max-width="32px"
          contain
          @click="zoom(item.image)"
        ></v-img>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip location="top" :text=" $filters.capitalize($t('actions.consumables')) ">
          <template v-slot:activator="{ props }">
            <v-icon
              v-show="actionAddConsumable && item.unity !== 'un'"
              small
              v-bind="props"
              class="mr-2"
              @click="addConsumableItemBtn(item)"
            >mdi-plus-thick
            </v-icon
            >
          </template>
        </v-tooltip>

        <v-tooltip location="top" :text=" $filters.capitalize($t('actions.parts'))">
          <template v-slot:activator="{ props }">
            <v-icon
              v-show="
                  actionAddPart &&
                  (item.unity === 'un' || item.unity === undefined)
                "
              small
              v-bind="props"
              class="mr-2"
              @click="addPartItemBtn(item)"
            >mdi-plus-thick
            </v-icon
            >
          </template>
        </v-tooltip>

        <v-tooltip location="top" :text="$filters.capitalize($t('actions.edit')) ">
          <template v-slot:activator="{ props }">
            <v-icon
              v-show="actionEdit"
              small
              v-bind="props"
              class="mr-2"
              @click="editItemBtn(item)"
            >mdi-pencil
            </v-icon
            >
          </template>
        </v-tooltip>

        <v-tooltip location="top" :text="$filters.capitalize($t('actions.to_trash'))">
          <template v-slot:activator="{ props }">
            <v-icon
              v-show="actionPartToTrash"
              small
              v-bind="props"
              class="mr-2"
              @click="partToTrashItemBtn(item.pk)"
            >mdi-delete-empty-outline
            </v-icon
            >
          </template>
        </v-tooltip>

        <v-tooltip location="top" :text="$filters.capitalize($t('actions.delete'))">
          <template v-slot:activator="{ props }">
            <v-icon
              v-show="actionDelete"
              small
              v-bind="props"
              @click="deleteItemBtn(item.pk)"
            >mdi-delete
            </v-icon
            >
          </template>
        </v-tooltip>
        <v-tooltip location="top" :text="$filters.capitalize($t('actions.convert_to_unit'))">
          <template v-slot:activator="{ props }">
              <v-icon
                v-if="actionConvertToUnit && item.unity!=='un'"
                small
                v-bind="props"
                @click="convertToUnitItemBtn(item)"
              >mdi-barcode
            </v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="top" :text="$filters.capitalize($t('edit'))">
          <template v-slot:activator="{ props }">
              <v-icon
                v-show="item.unity=='un'"
                small
                v-bind="props"
                @click="goToPartEditionBtn(item)"
              >mdi-eye-outline
            </v-icon>
          </template>
        </v-tooltip>
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ConsumableForm from "@/components/ConsumableForm";
import EditDialog from "@/components/EditDialog";
import UnitForm from "@/components/UnitForm";
import { isNone, alertPopup } from "@/functions";
import utils from "@/mixins/utils";

export default {
  components: {
    ConsumableForm: ConsumableForm,
    UnitForm: UnitForm,
    EditDialog: EditDialog
  },

  mixins: [utils],

  computed: mapState({
    formTitle() {
      return this.editedItem.pk === undefined
        ? this.$t("new_item")
        : this.$t("edit_item");
    },
    btnName() {
      return this.$t(`${this.moduleName}.actions.add_item`);
    }
  }),

  props: {
    moduleName: { type: String, required: true },
    headers: { type: Array, required: true },
    saveOverride: { type: Function, required: false },
    editOverride: { type: Function, required: false },
    actionAddConsumable: { type: Boolean, required: false, default: false },
    actionAddPart: { type: Boolean, required: false, default: false },
    actionEdit: { type: Boolean, required: false, default: true },
    actionPartToTrash: { type: Boolean, required: false, default: false },
    actionDelete: { type: Boolean, required: false, default: true },
    actionConvertToUnit: { type: Boolean, required: false, default: false }
  },

  methods: {
    cleanupDialog() {
      this.editDialog = false;
      // FIXME: set EditDialog.loadingAddItem = false too
      if (this.module.kindId !== undefined) {
        // quickfix for affectations module tmp var
        this.module.kindId = null;
      }
      var item = Object.assign({}, this.dataStruct);
      this.$store.dispatch(`${this.moduleName}/updateEdit`, item);
    },

    addItem(item) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`${this.moduleName}/addItem`, item)
          .then(() => {
            this.$refs.editDialogRef.closeDialog();
            this.loadPage();
            resolve();
          })
          .catch(error => {
            console.error('An error occurred while adding the item from store:', error);
            let errorMessage = 'An error occurred while adding the item.';

            // Check if the error is an array of error messages
            if (Array.isArray(error)) {
              errorMessage += '\n' + error.join('\n');
            } else if (typeof error === 'object') {
              // If the error is an object, construct the error message from its fields
              for (const field in error) {
                if (error.hasOwnProperty(field)) {
                  const fieldErrors = error[field];
                  if (Array.isArray(fieldErrors)) {
                    errorMessage += `\n${field}: ${fieldErrors.join('; ')}`;
                  } else {
                    console.error(`\n${field}: ${fieldErrors}`);
                  }
                }
              }
            } else {
              console.error(error);
            }

            this.$store.dispatch("snackbar/showError", errorMessage);
            reject(error);
          });
    });
},



    editItem(item) {
      return this.$store
        .dispatch(`${this.moduleName}/editItem`, item)
        .then(() => {
          this.$refs.editDialogRef.closeDialog();
        });
    },

    deleteItem(msgId) {
      return this.$store.dispatch(`${this.moduleName}/deleteItem`, msgId);
    },

    addItemEvent() {
      try {
        this.$emit("addItem");
        this.editDialog = true;
      } catch (error) {
        console.error("An error occurred while adding item:", error);
        this.showErrorDialog("An error occurred while adding item. Please try again later.");
      }
    },

    saveItemEvent() {
      if(this.editItem == undefined) return;
      this.$emit("saveItem");
      var item = Object.assign({}, this.editedItem);
      if (item.pk !== undefined) {
        if (this.editOverride !== undefined) {
          this.editOverride(item).then(() => {
            this.$refs.editDialogRef.closeDialog();
          });
        } else {
          this.editItem(item);
        }
      } else {
        if (this.saveOverride !== undefined) {
          this.saveOverride(item).then(() => {
            this.$refs.editDialogRef.closeDialog();
          });
        } else {
          this.addItem(item);
        }
      }
    },

    editItemBtn(item) {
      this.$store.dispatch(`${this.moduleName}/updateEdit`, item).then(() => {
        this.$emit("editItem", item);
        this.editDialog = true;
      });
    },

    partToTrashItemBtn(item_id) {
      var index = this.items.findIndex((i) => i.pk === item_id);
      alertPopup(this.$t("dialog.to_trash_confirm")).then((result) => {
        if (result && this.items.splice(index, 1)) {
          this.$store.dispatch(`affectations/affect`, {
            unit_id: item_id,
            kind: "ts",
            trash_state_id: 1,
          });
        }
      });
    },

    deleteItemBtn(item_id) {
      var index = this.items.findIndex((i) => i.pk === item_id);
      alertPopup(this.$t("dialog.delete_confirm")).then((result) => {
        if (result && this.items.splice(index, 1)) {
          this.deleteItem(item_id);
        }
      });
    },

    addConsumableItemBtn(item) {
      var stock_part_id = item.stock_part_id;
      if (stock_part_id === undefined) {
        stock_part_id = item.pk;
      }
      this.$store.dispatch(`consumables/setEditStockPart`, stock_part_id);
      this.showAddConsumable = true;
    },

    saveAddConsumableItem() {
      this.$store
        .dispatch(`consumables/addItem`, this.$store.state.consumables.edit)
        .then(() => {
          this.$store.dispatch(
            "snackbar/showSuccess",
            this.$t("consumables.added_success")
          );
        });
      this.showAddConsumable = false;
    },

    addPartItemBtn(item) {
      var stock_part_id = item.stock_part_id;
      if (stock_part_id === undefined) {
        stock_part_id = item.pk;
      }
      // The button is used on stock_part_context
      if (stock_part_id !== undefined) {
        this.$store.dispatch(`units/setEditStockPart`, stock_part_id);
      }
      this.showAddPart = true;
    },

    saveAddPartItem() {
      const edit = this.$store.state.units.edit;
      if (isNone(edit.serial)) {
        this.$store.dispatch(
          "snackbar/showWarning",
          this.$t("part_view.missing.sentence", {slice: this.$t("part_view.missing.serial")})
        );
      }else {
        this.$store.dispatch(`units/addItem`, edit).then(() => {
                this.$store.dispatch(
                  "snackbar/showSuccess",
                  this.$t("units.added_success")
                );
              });
          }
      this.showAddPart = false;
    },
    convertToUnitItemBtn(item) {
      this.$emit("convertItemToUnit", item);
    },

    loadPage(options_) {
      let options = {}
      if (options_) options=this.genPaginationPayload(options_)
      else options = {page:this.page, size:this.itemsPerPage}
      this.delayedSearch(
        this.searchValue,
        `${this.moduleName}/getItems`,options
      );
    },

    zoom(url) {
      this.imageInLarge = true;
      this.imageInLargeSrc = url;
    },

    openLink(url) {
      window.open(url, "_blank");
    },

    goToPartEditionBtn(item){
        let query = {
          stock_part_id: String(item.pk).toString(),
        };
        this.$router.push({ name: "part_edition", query: query });
    },
  },

  created() {
    this.loadPage();
    this.$store.dispatch(`${this.moduleName}/updateEdit`, this.dataStruct);
  },

  watch: {
    // options: {
    //   handler() {
    //     this.loadPage();
    //   },
    //   deep: true
    // },
    searchValue() {
      this.loadPage();
    }
  },

  data() {
    return {
      editDialog: false,
      searchValue: "",
      showAddConsumable: false,
      showAddPart: false,
      // options: {
      //   itemsPerPage: 20
      // },
      page: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 50, -1],
      // itemsPerPageText: this.$t("rows_per_page"),
      imageInLarge: false,
      imageInLargeSrc: null,
      debouncerTimer: undefined
    };
  }
};
</script>

<style scoped>
/* Vuetify ugly sticky header hack */
:v-deep(.v-data-table__wrapper) {
  height: calc(100vh - 155px) !important;
}

td .v-image {
  cursor: pointer;
}
</style>
