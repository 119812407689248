<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-card elevation="3">
          <v-card-title>
            <v-spacer></v-spacer>

            <span class="headline">{{
              $filters.capitalize($t("help.label", 1))
            }}</span>

            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <p>
              {{ $filters.capitalize($t("help.hello")) }}
              {{ $store.getters.firstName }},
            </p>
            <p>{{ $t("help.text") }}</p>
            <v-icon class="mr-3">mdi-arrow-right-bold-outline</v-icon>
            <a href="mailto:admin@innovent.fr">{{
              $filters.capitalize($t("help.contact_me"))
            }}</a>
            <v-icon class="ml-3">mdi-sticker-emoji</v-icon>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-card elevation="3">
          <v-card-title>
            <v-spacer></v-spacer>

            <span class="headline">{{
              $filters.capitalize($t("help.doc.label", 10))
            }}</span>

            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <ul>
              <li>
                <a :href="static_back('doc_part_edition.pdf')" target="_blank">
                  {{ $filters.capitalize($t("help.doc.part_edit")) }}</a
                >
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="9">
        <v-card elevation="3">
          <v-card-title>
            <v-spacer></v-spacer>

            <span class="headline">{{
              $filters.capitalize($t("help.changelog", 10))
            }}</span>

            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <ul id="example-2">
              <li
                v-for="(item, index) in changelogs"
                :key="index"
                v-bind:class="item.type"
              >
                {{ item.date }} : {{ item.message }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
const changelogs = [
  {
    date: "24/09",
    type: "feat",
    message: "Achat : possibilité d'éditer les quantités sur chaque ligne",
  },
  {
    date: "21/09",
    type: "fix",
    message:
      "Planning : les nouveaux évènements se mettent maintenant bien sur la journée complète (sans rechargement)",
  },
  {
    date: "16/09",
    type: "fix",
    message: "Filtrage général sur les utilisateurs actifs seulement",
  },
  {
    date: "14/09",
    type: "feat",
    message: "Production : affichage de la dispo temps",
  },
  {
    date: "13/09",
    type: "feat",
    message: "Achat : plus d'infos sur la visualisation",
  },
  {
    date: "13/09",
    type: "feat",
    message:
      "Télémétries : ajout des anciennes vues pour faire un monitoring global",
  },
  {
    date: "10/09",
    type: "fix",
    message: "Corrections sur l'import/export avec Google sheet",
  },
  {
    date: "01/09",
    type: "fix",
    message: "Corrections sur l'import/export avec Google sheet",
  },
  {
    date: "16/08",
    type: "feat",
    message: "Ajout d'une nouvelle devise : KMF",
  },
  {
    date: "02/08",
    type: "fix",
    message: "Corrections sur l'import/export avec Google sheet",
  },
  {
    date: "02/08",
    type: "fix",
    message:
      "Astreintes : corrections sur les rapports mensuels envoyés par mail",
  },
  {
    date: "29/07",
    type: "fix",
    message: "Pièces : réparation du filtre par catégorie",
  },
  {
    date: "27/07",
    type: "feat",
    message:
      "Éolien : affichage du taux de disponibilité sur le vue « Production », ainsi que du cut-in sur la vue « Télémétrie »",
  },
  {
    date: "26/07",
    type: "feat",
    message: "Achat : recalcul journalier des besoins d'achat",
  },
  {
    date: "22/07",
    type: "fix",
    message: "L'accès aux pages de détail des maintenances préventives",
  },
  {
    date: "21/07",
    type: "fix",
    message:
      "API : nettoyage automatique des connexions à l'ancienne base de données",
  },
  {
    date: "19/07",
    type: "feat",
    message: "Todo : ajout d'une action pour clôturer rapidement une todo",
  },
  {
    date: "16/07",
    type: "feat",
    message:
      "Rapports : on peut maintenant associer plusieurs personne à un rapport",
  },
  {
    date: "05/07",
    type: "feat",
    message:
      "Analyser des données : ajout de la visualisation par durée et fréquence",
  },
  {
    date: "30/06",
    type: "fix",
    message: "Plusieurs correctifs sur l'outil de planning",
  },
  {
    date: "22/06",
    type: "fix",
    message: "Plusieurs correctifs sur l'outil de planning",
  },
  {
    date: "21/06",
    type: "feat",
    message: "Calcul journalier du taux de disponibilité",
  },
  {
    date: "15/06",
    type: "feat",
    message: "Analyser des données : ajout d'un filtrage par origine de panne",
  },
  {
    date: "12/06",
    type: "feat",
    message: "Nouvelle vue pour analyser les données d'exploitation",
  },
  {
    date: "10/06",
    type: "feat",
    message:
      "On peut dorénavent sélectionner un producteur sur un évènement du calendrier (profil éolien seulement)",
  },
  {
    date: "10/06",
    type: "fix",
    message:
      "Plusieurs corrections sur le calendrier et la gestion des astreintes",
  },
  {
    date: "03/06",
    type: "feat",
    message: "Ajout de la valeur du stock à l'exportation",
  },
  {
    date: "02/06",
    type: "feat",
    message:
      "Pendant un rapport, on peut dorénavant saisir une alarme ; et l'on peut aussi rechercher par alarmes dans la liste des rapports",
  },
  {
    date: "31/05",
    type: "feat",
    message: "Exportation/importation sur/depuis un Google sheet",
  },
  {
    date: "28/05",
    type: "fix",
    message:
      "Correction du défillement des pages de résultats sur plusieurs vues",
  },
  {
    date: "27/05",
    type: "fix",
    message: "Retrait du type PDL de la liste des stockages",
  },
  {
    date: "26/05",
    type: "feat",
    message:
      "Automatisation de l'envoie du résumé mensuel des astreintes par mail",
  },
  {
    date: "20/05",
    type: "feat",
    message: "Première version de la gestion d'astreinte dans le planning",
  },
  {
    date: "17/05",
    type: "feat",
    message:
      "Ajout d'une visualisation de production spécifique pour la Namibie",
  },
  {
    date: "14/05",
    type: "feat",
    message:
      "Amélioration de la version anglaise : traduction et tri des catégories, locale persistente...",
  },
  { date: "12/05", type: "fix", message: "Semaine de bugfix :)" },
  {
    date: "07/05",
    type: "feat",
    message: "Possibilité de paramétrage des maintenances préventives",
  },
  {
    date: "06/05",
    type: "feat",
    message: "Groupement des besoins d'achat par fournisseurs",
  },
  {
    date: "05/05",
    type: "feat",
    message: "Nouvelle vue pour le suivi des maintenances préventives",
  },
  {
    date: "04/05",
    type: "fix",
    message: "Plusieurs corrections sur la vue des Todo",
  },
  {
    date: "30/04",
    type: "feat",
    message: "Export du planning en xslx (excel)",
  },
  {
    date: "23/04",
    type: "feat",
    message: "Une pièce peut maintenant avoir plusieurs fournisseurs",
  },
  { date: "22/04", type: "feat", message: "Ajout d'une devise aux prix" },
  {
    date: "21/04",
    type: "feat",
    message:
      "Les fournisseurs peuvent maintenant avoir trois numéros de téléphone",
  },
  {
    date: "21/04",
    type: "feat",
    message: "Filtrage des achats par stock",
  },
  { date: "16/04", type: "feat", message: "Nouvelle vue de production" },
  {
    date: "15/04",
    type: "feat",
    message: "Possibilité de faire des rapports sur les points de livraison",
  },
  {
    date: "15/04",
    type: "feat",
    message: "Nouvelle vue de télémétrie générique",
  },
  {
    date: "13/04",
    type: "feat",
    message: "Possibilité de supprimer des références de la liste des pièces",
  },
  {
    date: "09/04",
    type: "feat",
    message: "Exportation du planning en csv",
  },
  {
    date: "02/04",
    type: "feat",
    message: "Nouvelle vue : cette page d'aide",
  },
  {
    date: "01/04",
    type: "feat",
    message: "Ajout des tâches associés à un rapport ou un producteur",
  },
  {
    date: "30/03",
    type: "fix",
    message: "Correction des évènements dupliqués dans la partie planning",
  },
  {
    date: "26/03",
    type: "fix",
    message: "Correction de l'envoie d'image à la création d'une pièce",
  },
  {
    date: "24/03",
    type: "feat",
    message: "Possibilité de changer plusieurs pièces de catégorie en une fois",
  },
  {
    date: "24/03",
    type: "feat",
    message: "Page avec les tâches en cours (autre que les réparations)",
  },
  {
    date: "22/03",
    type: "fix",
    message:
      "Force la reconnexion si besoin (plus besoin de se déconnecter manuellement)",
  },
  { date: "17/03", type: "feat", message: "Nouvelle page utilisateur" },
  {
    date: "04/03",
    type: "feat",
    message: "Filtrage des rapports d'intervention",
  },
  {
    date: "01/03",
    type: "feat",
    message: "Écran de télémétrie pour Oualidia",
  },
  {
    date: "23/02",
    type: "feat",
    message: "Ajout du superviseur sur les rapports d'intervention",
  },
  {
    date: "15/02",
    type: "feat",
    message: "Nouvelle page serveant au suivi des achats",
  },
  {
    date: "02/02",
    type: "feat",
    message: "Nouvelle page avec les tâches de réparation en cours",
  },
  {
    date: "19/01",
    type: "feat",
    message: "Ajout du suivi des batteries",
  },
  {
    date: "18/01",
    type: "feat",
    message: "Nouvelle page l'ajout manuel de pièce au stock",
  },
];
const static_back = (file) => {
  return `${process.env.VUE_APP_API_URL}/static/${file}`;
};
</script>

<style scoped>
li.feat {
  color: black;
}
li.fix {
  color: green;
}
</style>
