import { LocaleManager } from "@bryntum/scheduler";

const customFrLocale = {
  extends: "En",

  desc: "French",
  locale: {
    localeName: "Fr",
    localeDesc: "French",

    // fix 4.2.x
    Object: { newEvent: " " },
    EventCopyPaste: {
      copyEvent: "Copier la tâche",
      cutEvent: "Couper la tâche",
      pasteEvent: "Coller la tâche",
    },

    BooleanCombo: {
      Yes: "Oui",
      No: "Non",
    },

    Combo: { noResults: "Pas de résultats" },

    EventEdit: {
      Name: "Nom",
      Resource: "Ressource",
      Start: "Début",
      End: "Fin",
      Save: "Sauvegarder",
      Delete: "Supprimer",
      Cancel: "Annuler",
      "Edit event": "Modifier la tâche",
    },

    SchedulerBase: {
      "Add event": "Ajouter une tâche",
      "Delete event": "Supprimer la tâche",
      "Unassign event": "Désassigner la tâche",
    },

    // Button : {
    //     'Add column'    : 'Ajouter une colonne',
    //     'Display hints' : 'Montrer les astuces',
    //     'Remove column' : 'Retirer une colonne',
    //     Apply           : 'Appliquer'
    // },

    // Column : {
    //     Company : 'Company',
    //     Name    : 'Name'
    // },

    // Checkbox : {
    //     'Auto apply'  : 'Application auto',
    //     Automatically : 'Automatiquement'
    // },

    // CodeEditor : {
    //     'Code editor'   : 'Editeur de code',
    //     'Download code' : 'Télécharger le code'
    // },

    // Tooltip : {
    //     'Click to show info and switch theme or locale' : 'Click to show info and switch theme or locale',
    //     'Click to show the built in code editor'        : 'Click to show the built in code editor',
    //     CheckAutoHints                                  : 'Check to automatically display hints when loading the example',
    //     Fullscreen                                      : 'Fullscreen'
    // }

    DateHelper: {
      locale: "fr",
      shortWeek: "S",
      shortQuarter: "T",
      week: "Semaine",
      weekStartDay: 1,
      unitNames: [
        { single: "Milliseconde", plural: "Millisecondes", abbrev: "ms" },
        { single: "Seconde", plural: "Secondes", abbrev: "s" },
        { single: "Minute", plural: "Minutes", abbrev: "min" },
        { single: "Heure", plural: "Heures", abbrev: "h" },
        { single: "Jour", plural: "Jours", abbrev: "j" },
        { single: "Semaine", plural: "Semaines", abbrev: "S" },
        { single: "Mois", plural: "Mois", abbrev: "m" },
        { single: "Trimestre", plural: "Trimestres", abbrev: "T" },
        { single: "Année", plural: "Années", abbrev: "a" },
      ],
      unitAbbreviations: [
        ["ms"],
        ["s", "sec"],
        ["m", "min"],
        ["h"],
        ["j"],
        ["s"],
        ["m", "mois"],
        ["T", "tri"],
        ["a"],
      ],
      parsers: {
        L: "DD/MM/YYYY",
        LT: "HH:mm",
      },
      ordinalSuffix: function (number) {
        return number;
      },
    },

    DateField: { invalidDate: "Date invalide" },
    NumberFormat: {
      locale: "fr-FR",
      currency: "EURO",
    },
    DurationField: { invalidUnit: "Unité invalide" },
    TimeField: { invalidTime: "Heure invalide" },
    List: { loading: "Chargement..." },

    ColumnPicker: {
      column: "Colonne",
      columnsMenu: "Colonnes",
      hideColumn: "Cacher la colonne",
      hideColumnShort: "Cacher",
    },
    Filter: {
      applyFilter: "Appliquer le filtre",
      filter: "Filtre",
      editFilter: "Modifier le filtre",
      on: "Sur",
      before: "Avant",
      after: "Après",
      equals: "Égale",
      lessThan: "Moins que",
      moreThan: "Plus que",
      removeFilter: "Supprimer le filtre",
    },
    Search: { searchForValue: "Rechercher une valeur" },
    Sort: {
      sort: "Trier",
      sortAscending: "Tri ascendant",
      sortDescending: "Tri descendant",
      multiSort: "Tri mutliple",
      removeSorter: "Supprimer le tri",
      addSortAscending: "Ajouter un tri ascendant",
      addSortDescending: "Ajouter un tri descendant",
      toggleSortAscending: "Changer pour ascendant",
      toggleSortDescending: "Changer pour descendant",
      sortAscendingShort: "Ascendant",
      sortDescendingShort: "Descendant",
      removeSorterShort: "Supprimer",
      addSortAscendingShort: "+ Ascendant",
      addSortDescendingShort: "+ Descendant",
    },
    GridBase: {
      loadFailedMessage: "Le chargement des données a échoué !",
      syncFailedMessage: "La synchronisation de données a échoué!",
      unspecifiedFailure: "Erreur non spécifiée",
      networkFailure: "Erreur réseau",
      parseFailure: "Échec de l'analyse de la réponse du serveur",
      loadMask: "Chargement ...",
      syncMask: "Sauvegarde des modifications, veuillez patienter ...",
      noRows: "Aucune tâche à afficher",
      moveColumnLeft: "Déplacer dans la partie gauche",
      moveColumnRight: "Déplacer dans la partie droite",
      removeRow: "Supprimer l'élément",
      removeRows: "Supprimer les éléments",
      moveColumnTo: function (e) {
        return "Déplacer dans la colonne ".concat(e);
      },
    },
    CellMenu: {
      removeRow: "Supprimer l'élément",
      removeRows: "Supprimer les éléments",
    },
    EventDrag: {
      eventOverlapsExisting: "La tâche chevauche une autre tâche existant",
      noDropOutsideTimeline:
        "Un tâche ne peut être lachée en dehors de la frise chronologique",
    },
    EventFilter: {
      filterEvents: "Filtre les tâches",
      byName: "Par nom",
    },
    HeaderContextMenu: {
      pickZoomLevel: "Zoom",
      activeDateRange: "Période de temps",
      startText: "Date de début",
      endText: "Date de fin",
      todayText: "Aujourd'hui",
    },
    TimeAxisHeaderMenu: {
      pickZoomLevel: "Zoom",
      activeDateRange: "Période de temps",
      startText: "Date de début",
      endText: "Date de fin",
      todayText: "Aujourd'hui",
    },
    TimeRanges: { showCurrentTimeLine: "Montrer la chronologie actuelle" },
  },
};

LocaleManager.registerLocale("Fr", customFrLocale);
LocaleManager.applyLocale("Fr");
