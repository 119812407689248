<template v-slot:edit_content>
    <v-row>
        <v-col cols="12" sm="6" md="6">
            <v-autocomplete
                v-model="editedItem.producer"
                :items="$store.state.producers.all"
                :item-value="'pk'"
                :item-title="'display_name'"
                :label="$filters.capitalize($t('producers.label', 1))"
                :loading="$store.state.producers.isSearching"
                :clearable="true"
                hide-details
                return-object
            >
                <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title>
                    {{
                        $filters.capitalize(
                        $t("search_for", { noun: $t("producers.label", 1) })
                        )
                    }}  
                    </v-list-item-title>
                </v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="editedItem.year"
                :label="$filters.capitalize($t('year'))"
                type="number"
                min="2000"
                max="2100"
                placeholder="YYYY"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="editedItem.month"
                :label="$filters.capitalize($t('month'))"
                type="number"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="editedItem.ratio"
                :label="'Ratio'"
                type="number"
            ></v-text-field>
        </v-col>
        
        <v-col cols="12" sm="12" md="12">
        <v-textarea
            v-model="editedItem.description"
            :label="$filters.capitalize($t('description'))"
            rows="3"
        ></v-textarea>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
        moduleName: "performance_ratio_solar_panel",
        selectedProducer: null,
        description: "",
        };
    },
    props: {
    report_value: { type: [String, null], required: false, default: null },

    },
    computed: {
        editedItem: {
        get() {
            console.log('GET', this.$store.getters.dataEdit(this.moduleName));
            return this.$store.getters.dataEdit(this.moduleName);
        },
        set(newValue) {
            console.log('set');
            this.$store.commit('updateEditedItem', { moduleName: this.moduleName, newValue });
        }
        },
    },
    watch: {
        report_value(val) {
            console.log(val);
        },
        editedItem: {
            handler(val) {
                if (val) {
                this.selectedProducer = val.producer || null;
                this.description = val.description || '';
                }
            },
        immediate: true,
        },
    },
    methods: {
        save() {
        const payload = {
            producer: this.selectedProducer,
            description: this.description,
        };
        this.$store.dispatch(`${this.moduleName}/saveItem`, payload)
            .then(() => {
            this.$store.dispatch('snackbar/showSuccess', 'Saved successfully');
            this.$emit('save');
            })
            .catch(error => {
            this.$store.dispatch('snackbar/showError', 'Error saving item');
            console.error('Save error:', error);
            });
        },
        resetForm() {
        this.selectedProducer = null;
        this.description = '';
        },
    },
    created() {
        this.resetForm();
    },
};
</script>