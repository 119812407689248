<template>
  <v-container>
    <v-table fixed-header class="my-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{$filters.capitalize( $t("production.name") ) }}</th>
            <th class="text-left">{{$filters.capitalize( $t("production.links") ) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="prod in wind_productions" :key="prod.pk">
            <td>
                {{$filters.capitalize( prod.display_name ) }}
            </td>
            <td>
                <v-btn
                  @click="get_link(prod.display_name)"
                  color="primary"
                  class="mr-2"
                >
                  {{$filters.capitalize( $t("production.links") ) }}
                </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </v-container>
</template>

<script>
import moment from "moment";
import { sumValues } from "@/functions.js";
import { isNone } from "@/functions.js";
import utils from "@/mixins/utils";
import i18n from "@/i18n";

export default {
  mixins: [utils],
  methods: {
    updateData() {
      this.$store
        .dispatch(`telemetries/windCurrent_producerItems`, {
          ids: [
            1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
            38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
            55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
            72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 88, 89, 90, 91, 92,
            93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 113, 114,
            115, 116, 117, 126, 127, 128, 131, 139, 140, 141, 191, 204, 130, 109,
            106, 135
          ],
        })
        .then((items) => {
          this.wind_productions = items;
        });
    },

    get_link(prod_name) {
      console.log(prod_name);
      this.allinks = {
        "audrieu2": "https://drive.google.com/open?id=11c3sv23GKWMr0TwjFDMMHAfn17eOicbN&usp=drive_copy",
        "audrieu3": "https://drive.google.com/open?id=1OYR8uPDFMoYP4xZSmjouVPYPxz4ULK--&usp=drive_copy",
        "bignan1": "https://drive.google.com/open?id=1HC6qOhXxYN6xeludLw6EssSOwgT9WmmJ&usp=drive_copy",
        "bignan2": "https://drive.google.com/open?id=1_JxrzSPVz5Of7LGJHIkI5jXijQh9ZdpZ&usp=drive_copy",
        "boisbergues1": "https://drive.google.com/open?id=1ZxC-P9ooP8KOQXBWL8YpcgDYwJ7IyHtJ&usp=drive_copy",
        "boisbergues2": "https://drive.google.com/open?id=1qMqKvqA0kW8hRlIs_lT8HnyKYx56r9Ym&usp=drive_copy",
        "boutavent1": "https://drive.google.com/open?id=1b81gdrnaWbYbqpjtRogMbZnM-6bKKRSv&usp=drive_copy",
        "buire 01": "https://drive.google.com/open?id=1pRfOiyuygi1zwDmcJM5LqkHt43ErtDju&usp=drive_copy",
        "buire 02": "https://drive.google.com/open?id=1LmOt-KIp-7EKPhLRvUVAPJgMy-LYd-hU&usp=drive_copy",
        "buire 03": "https://drive.google.com/open?id=1VrU3_loMPdg0dRZnj15EBmtMgSyw2mwG&usp=drive_copy",
        "buire 04": "https://drive.google.com/open?id=1lqVLMVTx8VqarzZoenq0aU-zb26wbWBT&usp=drive_copy",
        "buire 05": "https://drive.google.com/open?id=1XpNbsJNVOu9p2hbglKiAZSq4i22_SuRc&usp=drive_copy",
        "buire 06": "https://drive.google.com/open?id=1ptCn_XdkxrkWVSLA0vDfkLLQ6IY2dXe9&usp=drive_copy",
        "buire 07": "https://drive.google.com/open?id=1ncGqR00BhOdVLX7UYvkfc9uZbXz-rmDo&usp=drive_copy",
        "buire 08": "https://drive.google.com/open?id=1utzr4F36bVmjbKv6P4JFdZpZH3G7X-wV&usp=drive_copy",
        "buire 09": "https://drive.google.com/open?id=12K2uxjU7-Uw589tUPQa29GOWBcg2-k22&usp=drive_copy",
        "buire 10": "https://drive.google.com/open?id=1x0E3J6HuGKHa5_enevUDYl8ljcHmb_5M&usp=drive_copy",
        "buire 11": "https://drive.google.com/open?id=1DGyXD50j27HrJtBAyOnO11emJ-SH2oQG&usp=drive_copy",
        "croixrault e40": "https://drive.google.com/open?id=1vkf8gdvphgyvKq2EQ6tD3cdOh_YTPXeI&usp=drive_copy",
        "croixrault e41": "https://drive.google.com/open?id=1grp6HHrGXBRF93Laga5irVmE9eX3wOUJ&usp=drive_copy",
        "croixrault e43": "https://drive.google.com/open?id=1L_1k87MB6GLZUjoGjR24RO3mVFl-vZ0V&usp=drive_copy",
        "croixrault e44": "https://drive.google.com/open?id=179zTd60dJUfeHqkHguPWuJRea-9BCHbt&usp=drive_copy",
        "eplessier e1": "https://drive.google.com/open?id=1_-JW7plOVScsxA0B5oCqd-b7Q2C8pAK2&usp=drive_copy",
        "eplessier e2": "https://drive.google.com/open?id=1ZhdplGPKppMkdAjQAN7NRW6dq2f6yOCZ&usp=drive_copy",
        "eplessier t1": "https://drive.google.com/open?id=1qXaoA3yiE1WIlv26o8YMnsdoyT2XE0p5&usp=drive_copy",
        "eplessier t2": "https://drive.google.com/open?id=131Gm-wU-6FKUpGA5rQWVGRIv_FWugFdG&usp=drive_copy",
        "eplessier t3": "https://drive.google.com/open?id=1T6pZIpn9TRBeiI2ciMenW1WkF5ITcvMd&usp=drive_copy",
        "eplessier t4": "https://drive.google.com/open?id=1JaHC0IE8NMuQQ6F4kOzkWKU4VqgDuCn5&usp=drive_copy",
        "eplessier t5": "https://drive.google.com/open?id=1ow7oq6fqPYy40wwybgvxZYH0h2LKR6Qh&usp=drive_copy",
        "eplessier t6": "https://drive.google.com/open?id=1YRuW6vtw81F6WZnD_3S3Y-Bh9V402ww0&usp=drive_copy",
        "eplessier t7": "https://drive.google.com/open?id=14BrA36B-aLGSUOufZHFmBP6Evk7GmRFZ&usp=drive_copy",
        "eplessier t8": "https://drive.google.com/open?id=1ctwu4PK82LliJofCKlz9xGI11HK1PXWc&usp=drive_copy",
        "eplessier t9": "https://drive.google.com/open?id=1PPSWYwVv-NtvqDuRwF2BVInCNBmPQJIf&usp=drive_copy",
        "essey les ponts 1 (sj - acier)": "https://drive.google.com/open?id=1ecpi8Xeg-SYfXq6OByFyEWDXGu3Y3eTP&usp=drive_copy",
        "essey les ponts 2 (sj - acier)": "https://drive.google.com/open?id=1pOa724RC-NcfGh9Eyzj-WF8zgEi7VwPX&usp=drive_copy",
        "essey les ponts 3 (sa - bois)": "https://drive.google.com/open?id=1A3xlHSXEv1DJXNTINeSQEGtZMKj6Vb5f&usp=drive_copy",
        "essey les ponts 4 (sj - acier)": "https://drive.google.com/open?id=1bO2QpWRCMLdBKjZfaQDa9YMz0z8Gz0Qc&usp=drive_copy",
        "essey les ponts 5 (sa - bois)": "https://drive.google.com/open?id=1za3Z7BNDoMn45GJ6yA1-00O3qEY-_zg2&usp=drive_copy",
        "essey les ponts 6 (sa - bois)": "https://drive.google.com/open?id=1coCUhla0isK76A07_D-2TVFb1gPx0klq&usp=drive_copy",
        "essey les ponts 7 (sa - bois)": "https://drive.google.com/open?id=1T30am1_DKVCGR5cEvKjppwKyDlLmhB6K&usp=drive_copy",
        "fiefs 1": "https://drive.google.com/open?id=1_97CUTLo6Of0GekBMONc4MaaZ5iXAURt&usp=drive_copy",
        "fiefs 2": "https://drive.google.com/open?id=1fV9eOnwUxwfnUwJg1rC-_xjad2jsWpbx&usp=drive_copy",
        "fiefs 3": "https://drive.google.com/open?id=1odAAneoCplBN57-l4E1DCfFWU2MBJ7zI&usp=drive_copy",
        "fiefs 4": "https://drive.google.com/open?id=1niuA9Ag-ziDdeEMp4Tnwnog8fzJ0QI8g&usp=drive_copy",
        "fiefs 5": "https://drive.google.com/open?id=1FEPziGHbdJvpEzgOkGW7onGu2x3f3Lwk&usp=drive_copy",
        "fiefs 6": "https://drive.google.com/open?id=1fH-A9X-m8xCi5qpV3jdnGZrFPwTZVFEM&usp=drive_copy",
        "fortel1": "https://drive.google.com/open?id=1KnDMU6ykFJQh9BjH1AYPYxx09hVdSTyC&usp=drive_copy",
        "fortel2": "https://drive.google.com/open?id=1lzZ7stVnj3MdURgkCYyeIY3Hux8AmQQP&usp=drive_copy",
        "fortel3": "https://drive.google.com/open?id=1LWyJfsbXLk0-b2iHzqBPzdOhEcbakOMk&usp=drive_copy",
        "frE9nouville4": "https://drive.google.com/open?id=184v-dEN7aMXnsjKIA0_8NhuGb399QLwf&usp=drive_copy",
        "frE9nouville5": "https://drive.google.com/open?id=1g1mtCCzYfsq9qfu7gWBENzXMkiioMGOO&usp=drive_copy",
        "frE9nouville6": "https://drive.google.com/open?id=1q6T7USjPF9QLffGM17IMvzGWZM5uFGie&usp=drive_copy",
        "gapree1": "https://drive.google.com/open?id=1FiufbTKdViqyQ6WsN1UQmB5b4mfMjgwi&usp=drive_copy",
        "gapree2": "https://drive.google.com/open?id=1QVuyohrAF4HlOmbimByo_zRaAq-5sr7l&usp=drive_copy",
        "grand fougeray1": "https://drive.google.com/open?id=12fDwmKSIzipu8bSqe7NN0rA8-2IutEZv&usp=drive_copy",
        "grand fougeray2": "https://drive.google.com/open?id=1CT2qhhY7GQ9xtwzGzcBVp9To795SGxEB&usp=drive_copy",
        "guehenno1": "https://drive.google.com/open?id=1N7s1MCNZP1inbI1sVOJNayMSn00CbowN&usp=drive_copy",
        "guehenno2": "https://drive.google.com/open?id=1lBw8nrRtXWJretOKVXOFNyDZolRX8FGK&usp=drive_copy",
        "guehenno3": "https://drive.google.com/open?id=1x3ayonSQwK3JNdrRdSzT_f5dWVMnY3ip&usp=drive_copy",
        "hescamps1": "https://drive.google.com/open?id=1R-_KyGUma3CZjL9JmFHdnZsmlMYONrdt&usp=drive_copy",
        "hescamps2": "https://drive.google.com/open?id=1ahWukaRJf66-K48lj-vQEMacj-nYwiyw&usp=drive_copy",
        "hescamps3": "https://drive.google.com/open?id=1EDyLegREMJxn_HDXSJU2BCa2y2lWfuPF&usp=drive_copy",
        "hescamps4": "https://drive.google.com/open?id=1-ObP18YudJPuusC609kq3aQAPCxaTFrJ&usp=drive_copy",
        "hescamps5": "https://drive.google.com/open?id=1HXYH1UHbjUscPZ1-gv6F8gR7dStcnX-U&usp=drive_copy",
        "kalix1": "https://drive.google.com/open?id=1YHCU4Zpq45je7Q73C7T_mVseb16nXg8r&usp=drive_copy",
        "kalix2": "https://drive.google.com/open?id=1DUWUVdjagi381xot_dV_iL5-li0qeXjb&usp=drive_copy",
        "kalix3": "https://drive.google.com/open?id=14Z4eINDKqmD-_QiHEh_pT9pnjJD8i4pv&usp=drive_copy",
        "kalix4": "https://drive.google.com/open?id=1Sigrcc93HeeCRaqAmhxuICvKmAK9KV8Z&usp=drive_copy",
        "kalix5": "https://drive.google.com/open?id=1xw2v6sjCzOVBtjG19DnxlyYviuMeoLks&usp=drive_copy",
        "lamballe 1": "https://drive.google.com/open?id=1p8qK2zmoUotQT6ER3ZJRZP4EqvDDON&usp=drive_copy",
        "les diagots1": "https://drive.google.com/open?id=1lUOy5WaSedbPbeM02Ap4rtMz1z5phJ9M&usp=drive_copy",
        "ombepo1 t1": "https://drive.google.com/open?id=1XsChRMAir6FauP1L_qy21WidgM7nNRwz&usp=drive_copy",
        "ombepo1 t2": "https://drive.google.com/open?id=1E1ADo0AUlU1dSmRqHIVZ5poNtUjOTjqo&usp=drive_copy",
        "ombepo1 t3": "https://drive.google.com/open?id=1r0jK0vjQoLoXtM91gqov3AhvtMtyQnV-&usp=drive_copy",
        "oualidia11": "https://drive.google.com/open?id=15Xma8v0Gl6n3RTxuZoxYUg0otndUviIM&usp=drive_copy",
        "oualidia12": "https://drive.google.com/open?id=1skAk0ptgeeHL57OW4CfrTJ-_nL33aFEq&usp=drive_copy",
        "oualidia13": "https://drive.google.com/open?id=1huAMpuoSQoth0E8xSImUBkKSHSImWxI4&usp=drive_copy",
        "oualidia14": "https://drive.google.com/open?id=1ixLJuMGk0B-VywGMrghcRicZlF4UhgHe&usp=drive_copy",
        "oualidia15": "https://drive.google.com/open?id=1HyI7vAXoGQefQ4SdsnyTvCX8vlY2vKcA&usp=drive_copy",
        "oualidia16": "https://drive.google.com/open?id=11xO4J4PPrMFvP2QPyh2n_pr6lw-yGgZO&usp=drive_copy",
        "oualidia21": "https://drive.google.com/open?id=1eQ6JyEtnYzB1hgmAvYgeK9mFuGX6hfbx&usp=drive_copy",
        "oualidia22": "https://drive.google.com/open?id=1I4BBUo_F4Fer31csTKYhETOq4OnVo2FK&usp=drive_copy",
        "oualidia23": "https://drive.google.com/open?id=1jE3CCUfUofh6axcNZIdHCRELuQI7PYV9&usp=drive_copy",
        "oualidia24": "https://drive.google.com/open?id=10wBrS3NFenMKKHU0GuEBzIk3nqSUxMIw&usp=drive_copy",
        "oualidia25": "https://drive.google.com/open?id=1n4P8G32JXiOjrAn_XsgzxwNPYVyr8Hcx&usp=drive_copy",
        "oualidia26": "https://drive.google.com/open?id=1Ujh3bqejqmBtkPWZT2zs3x4WmREmf71d&usp=drive_copy",
        "pilote1": "https://drive.google.com/open?id=1J1-9-lOKYXXwDS4_Ezi0kYP5HfGm96Ul&usp=drive_copy",
        "plechatel1": "https://drive.google.com/open?id=1sBv0eFiSj5vaJUpX3ztCCxBzXahqO8Wp&usp=drive_copy",
        "plechatel2": "https://drive.google.com/open?id=1jko9WXPDsf8_4mtaREwfamN1GZGX3dJV&usp=drive_copy",
        "plechatel3": "https://drive.google.com/open?id=1z2MeyfvKHzWGlczM1POgbayA8_GgL4-N&usp=drive_copy",
        "plechatel4": "https://drive.google.com/open?id=12EtktUNOA_hj0gXGbXdvEd5kLv1c2pQO&usp=drive_copy",
        "roye 1": "https://drive.google.com/open?id=1ePJuOksY42GUjLZmtqkRAOYpgsxj4SXt&usp=drive_copy",
        "roye 3": "https://drive.google.com/open?id=1oN0LVvEOsO68xMy3Eo11em7C08ERzSyh&usp=drive_copy",
        "roye 4": "https://drive.google.com/open?id=1M3AfCWq-tofT50ZC00sjW7WJ2OYxKDIE&usp=drive_copy",
        "roye 5": "https://drive.google.com/open?id=1jJwrcx2j9rGhsGQn4LNGE56UNcBR5lJQ&usp=drive_copy",
        "roye argentan 12": "https://drive.google.com/open?id=1DU4isNsrzk5gpjGvaANjvG_CenS3yJ9U&usp=drive_copy",
        "roye argentan 13": "https://drive.google.com/open?id=1hRy-fWissw1sLqIBK3K2V9gAigwtzu2T&usp=drive_copy",
        "roye argentan 14": "https://drive.google.com/open?id=1uV9DxxuhfXMC32KLF43q8gNc3vNiaJtA&usp=drive_copy",
        "roye argentan 15": "https://drive.google.com/open?id=1V5UvBkcov-dJtOHcHtKgRsI7W2nttNzt&usp=drive_copy",
        "saint Hilaire1": "https://drive.google.com/open?id=1Usxh3LonRxJEPJJF5XLCpgjxvbL2o2Ly&usp=drive_copy",
        "valhuon1": "https://drive.google.com/open?id=1um0nH7vT2uhDvdAMXOsHg8fPxh-q84-A&usp=drive_copy",
        "vanern01": "https://drive.google.com/open?id=1WKw-syrOjSaAnxsKEAgu-_BpLluV3AdX&usp=drive_copy",
        "vanern02": "https://drive.google.com/open?id=1cShlG74QvrSTqp6AF0NkPWLmNRuBZnEX&usp=drive_copy",
        "vanern03": "https://drive.google.com/open?id=14dMkVb8QZvqk94Ow7mPFxdoU210EYwbs&usp=drive_copy",
        "vanern04": "https://drive.google.com/open?id=1p7w7pH3e7glQsYJCmlyCAB23vFxriqch&usp=drive_copy",
        "vanern05": "https://drive.google.com/open?id=1pKUizPe7Q0XJaTdWNC-CMKyugxcFtf5k&usp=drive_copy",
        "vanern06": "https://drive.google.com/open?id=1BrDLqFIe4Rpp75H-JpP7k89N_Ct_nLgq&usp=drive_copy",
        "vanern09": "https://drive.google.com/open?id=1b6OTqEejG_sf3Llne5fJhsVBLZHU2CnK&usp=drive_copy",
        "vanern10": "https://drive.google.com/open?id=1HDQODVjVG0RiTLQGL1R7oWARHtOPvo6u&usp=drive_copy",
        "vensys le portel": "https://drive.google.com/open?id=1Qhlf-i2feo3ctQd2GQOiGHnTh3WEuHjq&usp=drive_copy",
        "vensys1": "https://drive.google.com/open?id=1x-blq08E98hpX62iukFG2oaukGWN4JKJ&usp=drive_copy",
        "vensys2": "https://drive.google.com/open?id=1iaOOaxZbo404iGMCBCyEQt0-a9SjyWGk&usp=drive_copy",
        "vensys6": "https://drive.google.com/open?id=16AqFep78OFfk_UGrZ5QzXrpOi-v8ij_c&usp=drive_copy",
        "vron e70": "https://drive.google.com/open?id=1xBnBiNt7ocECkblCKf6tmGNRk8Sv20xQ&usp=drive_copy",
        "vron e73": "https://drive.google.com/open?id=1CU4uwG-pYhaD4N9kfo-43URwwlah_BHI&usp=drive_copy",
      }
      window.open(this.allinks[prod_name], "_blank");
    },

    openRangeSelection(prod_id) {
      this.producer_id = prod_id;
      this.showRangeSelection = true;
    },

    closeRangeSelection() {
      this.showRangeSelection = false;
      this.producer_id = undefined;
    },
  },

  created() {
    this.updateData();
  },

  mounted() {

    this.updateData();
      this.$nextTick(function () {
      this.intervalId = window.setInterval(
        () => {
          this.updateData();
        },
        10 * 60 * 1000,
      );
    });
  },
  unmounted() {
    window.clearInterval(this.intervalId);
  },

  data() {
    return {
      wind_productions: [],
      allinks: {},
    };
  },
  };
</script>

