<template>
  <v-container pa-0 ma-0>
    <v-select
      :items="$store.state.units.edit.serials"
      :label="$filters.capitalize($t('units.label', 1))"
      variant="solo"
      @update:modelValue="updateUnits"
    >
    </v-select>
  </v-container>
</template>

<script>
import filters from "@/mixins/filters";
import { isNone } from "@/functions";

export default {
  mixins: [filters],

  props: {
    stock_part_id: { type: Number, required: false, default: undefined },
  },

  computed: {
    editedItem: {
      get: function () {
        return this.$store.getters.dataEdit("units");
      },
      set: function (item) {
        if (isNone(item)) {
          this.$store.dispatch("units/cleanupEdit");
        } else {
          this.$store.dispatch("units/updateEdit", {
            pk: item.pk,
            serial: item.serial,
          });
        }
      },
    },
  },

  methods: {
    updateUnits(val) {
      this.$store.dispatch("units/searchFromStockPart", {
        value: val,
        item_id: this.stock_part_id,
      });
    },
  },
};
</script>
