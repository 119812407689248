<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName">
          <template v-slot:edit_content>
            <InternalRepairForm></InternalRepairForm>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import InternalRepairForm from "@/components/InternalRepairForm";

export default {
  components: {
    Crud: Crud,
    InternalRepairForm: InternalRepairForm,
  },

  data() {
    return {
      moduleName: "internal_repairs",
      headers: [
        {
          title: this.$t("internal_repairs.created_at"),
          key: "created_at",
        },
        {
          title: this.$t("internal_repairs.description"),
          key: "description",
        },
        {
          title: this.$t("internal_repairs.assigned_to"),
          key: "assigned_to.first_name",
        }, // separated into first and last name to enable sorting in back
        {
          title: this.$t("internal_repairs.assigned_to"),
          key: "assigned_to.last_name",
        },
        {
          title: this.$t("internal_repairs.repaired_at"),
          key: "repaired_at",
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
