<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud
          v-bind:headers="headers"
          v-bind:moduleName="moduleName"
          @addItem="addItemEvent"
          @editItem="editItemEvent"
        >
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedItem.description"
                  :label="$filters.capitalize($t('producers.description') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  outlined
                  v-model="editedItem.display_name"
                  :label="$filters.capitalize($t('producers.display_name') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="editedItem.producer_type_id"
                  :items="$store.state.producer_types.items"
                  item-title="name"
                  item-value="pk"
                  :label="$filters.capitalize($t('producer_types.label', 1) )"
                  variant="solo"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-autocomplete
                  v-model="editedItem.power_station_id"
                  :items="$store.state.power_stations.search"
                  item-title="name"
                  item-value="pk"
                  :label="$filters.capitalize($t('power_stations.label', 1) )"
                  v-model:search="autocompleteSearch"
                  :loading="$store.state.power_stations.isSearching"
                  append-icon="mdi-text-box-search"
                  :clearable="true"
                  variant="solo"
                  cache-items
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{$filters.capitalize(
                          $t("search_for", {
                            noun: $t("power_stations.label", 1),
                          }) )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <DatePicker
                  v-bind:label="'producers.commissioned_at'"
                  v-bind:value="editedItem.commissioned_at"
                  @dateSelected="editedItem.commissioned_at = $event"
                >
                </DatePicker>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  outlined
                  v-model="editedItem.ordinal"
                  :label="$filters.capitalize($t('producers.ordinal') )"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="editedItem.power_curve_min_id"
                  :items="$store.state.power_curves.search"
                  item-title="description"
                  item-value="pk"
                  :label="$filters.capitalize($t('power_curves.min.label', 1) )"
                  v-model:search="autocompletePCMinSearch"
                  :loading="$store.state.power_curves.isSearching"
                  append-icon="mdi-text-box-search"
                  :clearable="true"
                  variant="solo"
                  cache-items
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{$filters.capitalize(
                          $t("search_for", {
                            noun: $t("power_curves.min.label", 1),
                          }) )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="editedItem.power_curve_moy_id"
                  :items="$store.state.power_curves.moySearch"
                  item-title="description"
                  item-value="pk"
                  :label="$filters.capitalize($t('power_curves.moy.label', 1) )"
                  v-model:search="autocompletePCMoySearch"
                  :loading="$store.state.power_curves.isSearching"
                  append-icon="mdi-text-box-search"
                  :clearable="true"
                  variant="solo"
                  cache-items
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{$filters.capitalize(
                          $t("search_for", {
                            noun: $t("power_curves.moy.label", 1),
                          }) )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import DatePicker from "@/components/DatePicker";
import { isNone } from "@/functions.js";

export default {
  components: {
    Crud: Crud,
    DatePicker: DatePicker,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },

  created() {
    this.$store.dispatch("producer_types/getItems");
  },

  methods: {
    addItemEvent() {
      this.$store.dispatch("power_stations/searchItems", { value: "" });
      this.$store.dispatch("power_curves/searchItems", { value: "" });
      this.$store.dispatch("power_curves/searchMoyItems", { value: "" });
    },
    editItemEvent() {
      this.$store.dispatch("power_stations/initSearch", {
        item_ids: this.editedItem.power_station_id,
      });
      if (!isNone(this.editedItem.power_curve_min_id)) {
        this.$store.dispatch("power_curves/initSearch", {
          item_ids: this.editedItem.power_curve_min_id,
        });
      }
      if (!isNone(this.editedItem.power_curve_moy_id)) {
        this.$store.dispatch("power_curves/initMoySearch", {
          item_ids: this.editedItem.power_curve_moy_id,
        });
      }
    },
  },

  watch: {
    autocompleteSearch(val) {
      if (!this.$store.getters.canSearch("power_stations")) return;
      this.$store.dispatch("power_stations/searchItems", { value: val });
    },
    autocompletePCMinSearch(val) {
      if (!this.$store.getters.canSearch("power_curves")) return;
      this.$store.dispatch("power_curves/searchItems", {
        curve_type: "min",
        value: val,
      });
    },
    autocompletePCMoySearch(val) {
      if (!this.$store.getters.canSearch("power_curves")) return;
      this.$store.dispatch("power_curves/searchMoyItems", { value: val });
    },
  },

  data() {
    return {
      moduleName: "producers",
      headers: [
        { title: this.$t("generic.id"), key: "pk" },
        { title: this.$t("producers.description"), key: "description" },
        { title: this.$t("producers.display_name"), key: "display_name" },
        {
          title: this.$t("producer_types.label"),
          key: "producer_type.name",
        },
        {
          title: this.$t("producers.commissioned_at"),
          key: "commissioned_at",
        },
        { title: this.$t("producers.ordinal"), key: "ordinal" },
        {
          title: this.$t("power_stations.label"),
          key: "power_station.name",
        },
        {
          title: this.$t("power_curves.min.label"),
          key: "power_curve_min.description",
          width: "17%",
          sortable: false,
        },
        {
          title: this.$t("power_curves.moy.label"),
          key: "power_curve_moy.description",
          width: "17%",
          sortable: false,
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
      autocompleteSearch: null,
      autocompletePCMinSearch: null,
      autocompletePCMoySearch: null,
    };
  },
};
</script>
