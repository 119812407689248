<template>
  <v-container>
    <v-toolbar text color="white" elevation="1" class="mt-3">
      <v-tooltip location="top" :text="$filters.capitalize($t(`${moduleName}.actions.back_view`))">
        <template v-slot:activator="{ on }">
          <v-icon v-bind="on" class="mx-2" @click="backBtn()"
          >mdi-arrow-left-drop-circle-outline
          </v-icon
          >
        </template>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-toolbar-title style="font-weight: bolder">{{
          $filters.capitalize($t(pageTitle, 1))
        }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <StockPartList
      v-show="showStockPartSelection"
      v-bind:edition="edition"
      @chooseProdType="chooseProdTypeEvent"
      @choosePartCategory="choosePartCategoryEvent"
      @chooseStockPart="chooseStockPartEvent"
      @newStockPart="newStockPartEvent"
    ></StockPartList>
    <StockPartForm
      v-show="showStockPartEdition"
      v-bind:edition="edition"
      v-bind:isItNewPart="isItNewPart"
      @chooseProdType="chooseProdTypeEvent"
      @choosePartCategory="choosePartCategoryEvent"
      @chooseStockPart="chooseStockPartEvent"
      @clearStockPart="clearStockPartEvent"
    ></StockPartForm>
    <EditPartForm
      v-show="showPartEdition"
      v-bind:edition="edition"
      v-bind:showSPS="showStockPartSelection"
      @chooseStockage="chooseStockageEvent"
    ></EditPartForm>
    <AffectedUnitsList
      :v-show="showPartEdition"
      v-bind:showSPS="showStockPartSelection"
      v-bind:stock_part_id="edition.stock_part_id"
    >
    </AffectedUnitsList>
  </v-container>
</template>

<script>
import StockPartList from "@/components/part/StockPartList";
import StockPartForm from "@/components/part/StockPartForm";
import EditPartForm from "@/components/part/EditPartForm";
import AffectedUnitsList from "@/components/part/AffectedUnitsList.vue";
import filters from "@/mixins/filters";
import { isNone } from "@/functions.js";

export default {
  mixins: [filters],

  components: {
    StockPartList,
    StockPartForm,
    EditPartForm,
    AffectedUnitsList
  },

  props: {
    part: { type: String, required: false }
  },

  computed: {
    editMode: function() {
      return !isNone(this.part);
    }
  },

  methods: {
    backBtn() {
      if (
        !isNone(this.edition.stockage_id) &&
        !isNone(this.edition.producer_type_id)
      ) {
        let query = {};
        query["stockage_id"] = this.edition.stockage_id;
        query["producer_type_id"] = this.edition.producer_type_id;
        this.$router.push({ name: "part_view", query: query });
      } else {
        this.clearEdition();
        this.$router.push({ name: "part_view" }).catch(() => {
        });
      }
      this.showPartEdition = false;
      this.showStockPartEdition = false;
      this.showStockPartSelection = true;
    },
    chooseProdTypeEvent(value) {
      if (
        !isNone(this.part_category_id) &&
        this.edition.producer_type_id != value.pk
      ) {
        this.edition.part_category_id = undefined;
      }
      if (!isNone(value)) {
        this.edition.producer_type_id = value.pk;
        this.$store.dispatch("stockages/getByProducerTypes", {
          producer_types: [this.edition.producer_type_id]
        });
      }
      this.showStockPartEdition = false;
      this.showStockPartSelection = true;
      this.showPartEdition = false;
    },
    choosePartCategoryEvent(value) {
      this.edition.part_category_id = value;
    },
    chooseStockPartEvent(value) {
      this.getRelevantStockages(value);
      this.updateEdition(value);
      this.showPartEdition = true;
      this.showStockPartEdition = true;
      this.showStockPartSelection = false;
      this.isItNewPart = false;
    },
    newStockPartEvent() {
      this.clearEdition();
      this.showPartEdition = false;
      this.showStockPartEdition = true;
      this.showStockPartSelection = false;
      this.isItNewPart = true;
    },
    clearStockPartEvent() {
      this.clearEdition();
      this.showPartEdition = false;
      this.showStockPartEdition = false;
      this.showStockPartSelection = true;
      this.isItNewPart = false;
    },
    chooseStockageEvent(value) {
      this.edition.stockage_id = value;
    },
    clearEdition() {
      this.edition.stock_part_id = undefined;
      this.edition.part_category_id = undefined;
      this.edition.stockage_id = undefined;
    },
    updateEdition(item) {
      this.edition.stock_part_id = item.pk;
      this.edition.part_category_id = item.category_id;
    },
    getRelevantStockages(value) {
      let p_ = value.category.producer_type_ids;
      if (p_.length == 0) {
        this.$store.dispatch("stockages/getItems");
      } else {
        this.$store.dispatch("stockages/getByProducerTypes", {
          producer_types: p_
        });
      }
    }
  },
  mounted() {
    this.edition.stockage_id = parseInt(this.$route.query.stockage_id) || undefined;
    this.edition.stock_part_id = parseInt(this.$route.query.stock_part_id) || undefined;
    this.edition.producer_type_id = parseInt(
      this.$route.query.producer_type_id
    ) || undefined;
    this.$store.dispatch("producer_types/getItems");

    if (
      !isNone(this.edition.stock_part_id)
    ) {
      this.isItNewPart = false;
      this.$store.dispatch("stockages/getItems", {
        ids: this.edition.stockage_id
      });
      this.$store
        .dispatch("stock_parts/getItem", this.edition.stock_part_id)
        .then((item) => {
          this.$store.dispatch("stock_parts/updateEdit", item);
          this.edition.part_category_id = item.category_id;
        });

      this.choosePartCategoryEvent(this.edition.part_category_id);
      this.showStockPartSelection = false;
      this.showStockPartEdition = true;
      this.showPartEdition = true;
    } else if (this.editMode) {
      this.isItNewPart = false;
      this.$store
        .dispatch(`${this.moduleName}/getItem`, this.part)
        .then((item) => {
          if (!this.$store.state.part_categories.isLoading) {
            this.$store.dispatch("part_categories/getItems", {
              ids: [item.stock_part.category_id]
            });
          }
          this.$store.dispatch("stock_parts/initSearch", {
            item_ids: item.stock_part_id
          });
          if (!isNone(item.stock_part.supplier_ids)) {
            this.$store.dispatch("suppliers/initSearch", {
              item_ids: item.stock_part.supplier_ids
            });
          }
          this.$store.dispatch("stockages/getByProducerTypes", {
            producer_types: item.stock_part.category.producer_type_ids
          });
          this.edition.stockage_id = item.place.stockage_id;
          this.edition.stock_part_id = item.stock_part.pk;
          this.$store
            .dispatch("stock_parts/getItem", this.edition.stock_part_id)
            .then((item) => {
              this.$store.dispatch("stock_parts/updateEdit", item);
            });

          this.edition.part_category_id = item.stock_part.category_id;
          this.choosePartCategoryEvent(this.edition.part_category_id);
          this.showStockPartSelection = false;
          this.showStockPartEdition = true;
          this.showPartEdition = true;
        });
    } else {
      this.$store.dispatch("stockages/getItems");
      this.showStockPartSelection = true;
      this.showStockPartEdition = false;
      this.showPartEdition = false;
    }
  },

  unmounted() {
    this.$store.dispatch("parts/cleanupItems");
    this.$store.dispatch("real_parts/cleanupItems");
    this.$store.dispatch("stock_parts/cleanupItems");
    this.$store.dispatch("stock_parts/cleanupEdit");
  },

  data() {
    return {
      pageTitle: "part_edition.label",
      moduleName: "parts",
      edition: {},
      showPartEdition: false,
      showStockPartEdition: false,
      showStockPartSelection: false,
      isItNewPart: true
    };
  }
};
</script>

<style scoped>
.service_message {
  font-style: italic;
}
</style>
