<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <v-switch
            v-model="lastOnly"
            :label="$filters.capitalize($t('affectations.actions.latest') )"
            ></v-switch>
        <Crud
            v-bind:headers="headers"
            v-bind:moduleName="moduleName"
            v-bind:editOverride="editOverride"
            @addItem="addItemEvent"
            @editItem="editItemEvent"
            >
            <template v-slot:edit_content>
              <AffectationForm></AffectationForm>
            </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Crud from "@/components/Crud";
  import AffectationForm from "@/components/AffectationForm";
  import utils from "@/mixins/utils";

  export default {
    mixins: [utils],

    components: {
      Crud: Crud,
      AffectationForm: AffectationForm,
    },

    methods: {
      editOverride(item) {
        switch (item.kind) {
          case "er":
            this.$store.dispatch(
              "external_repairs/editItem",
              this.$store.state.external_repairs.edit,
            );
            break;
          case "ir":
            this.$store.dispatch(
              "internal_repairs/editItem",
              this.$store.state.internal_repairs.edit,
            );
            break;
        }
        // cleaning object, #notProud
        if (item.stock_state_id &&
          typeof(item.stock_state_id)=='object') {
          item.stock_state_id = item.stock_state_id.pk;
        }
        item.unit_id = item.unit.pk
        delete item.unit

        return new Promise((resolve) => {
          this.$store
            .dispatch(
              "affectations/editItem",
              item,
            )
            .then(() => {
              resolve();
            });
        });
      },
      addItemEvent() {
        this.$store.dispatch("units/searchItems", { value: "" });
      },
      editItemEvent() {
        switch(this.editedItem.kind){
          case 'er':
            this.$store.dispatch(
              "external_repairs/updateEdit",
              this.editedItem.external_repair,
            );
            break
          case 'ir':
            this.$store.dispatch(
              "internal_repairs/updateEdit",
              this.editedItem.internal_repair,
            );
            break
          case 'p':
            this.$store.dispatch("producers/updateEdit", this.editedItem.producer);
            break
          case 'ss':
            this.$store.dispatch("places/updateEdit", this.editedItem.stock_state);
            this.$store.dispatch("stockages/updateEdit", this.editedItem.stock_state.stockage);
            break
        }
        this.$store.dispatch("units/updateEdit", this.editedItem.unit);
      },
    },

    watch: {
      lastOnly(value) {
        if (value) {
          this.$store.dispatch("affectations/getLatest");
        } else {
          this.$store.dispatch("affectations/getItems");
        }
      },
    },

  data() {
    return {
      moduleName: "affectations",
      lastOnly: false,
      headers: [
        { title: this.$t("generic.date"), key: "date" },
        {
          title: this.$t("stock_parts.name"),
          key: "unit.stock_part.name",
        },
        {
          title: this.$t("stock_parts.name_en"),
          key: "unit.stock_part.name_en",
        },
        {
          title: this.$t("stock_parts.ref"),
          key: "unit.stock_part.ref",
        },
        { title: this.$t("units.serial", 1), key: "unit.serial" },
        {
          title: this.$t("affectations.status.label"),
          key: "kind",
          sortable: false,
        },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
