<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <Crud v-bind:headers="headers" v-bind:moduleName="moduleName">
          <template v-slot:edit_content>
            <v-row>
              <v-col cols="12" sm="8" md="6">
                <v-autocomplete
                  v-model="editedItem.stockage_id"
                  :items="$store.state.stockages.all"
                  item-title="name"
                  item-value="pk"
                  :label="$filters.capitalize($t('stockages.label'))"
                  :loading="$store.state.stockages.isSearching"
                  :filter="filterStockages"
                  prepend-icon="mdi-truck-delivery-outline"
                  append-icon="mdi-text-box-search"
                  :clearable="true"
                  variant="solo"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{
                          $filters.capitalize(
                            $t("search_for", { noun: $t("stockages.label", 1) })
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  v-model="editedItem.cabinet"
                  :label="$filters.capitalize($t('places.cabinet') )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="6">
                <v-text-field
                  v-model="editedItem.shelf"
                  :label="$filters.capitalize($t('places.shelf') )"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Crud from "@/components/Crud";
import filters from "@/mixins/filters";

export default {
  mixins: [filters],

  components: {
    Crud: Crud,
  },

  computed: {
    editedItem: function () {
      return this.$store.getters.dataEdit(this.moduleName);
    },
  },
  methods: {
    getIfEmpty(){
        if (this.$store.state.stockages.all.length > 0 || this.$store.state.stockages.isLoading) return
        this.$store.dispatch('stockages/getAll')
    }
  },
  watch: {
    editedItem(val){
        this.getIfEmpty();
    },
  },

  created() {
    this.getIfEmpty();
  },

  data() {
    return {
      moduleName: "places",
      headers: [
        { title: this.$t("stockages.label"), key: "stockage.name" },
        { title: this.$t("places.cabinet"), key: "cabinet" },
        { title: this.$t("places.shelf"), key: "shelf" },
        { title: this.$t("places.label"), key: "name" },
        {
          title: this.$t("actions.label"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>
